import {Notifier} from './notifier';
import {Beacon} from './beacon';

import * as pb from './protocol';

export async function play({ui, scenario, serialPort}) {
    const playlist = scenario.playlist;
    const notifier = new Notifier(ui.notifications);
    
    const beacon = new Beacon({
        locationID: scenario.location.id,
        streamID: scenario.location.id,
        privateKey: scenario.location.privateKey,
        serialPort
    });
    
    let firstCatch = true;

    beacon.on('catch', () => {
        let html;
        if (firstCatch) {
            html = scenario.events.onFirstCatch;
            firstCatch = false;
        }
        if (!html) {
            html = scenario.events.onCatch;
        }
        notifier.notify({html: html, className: 'is-catch'});
    });

    await beacon.start();

    function _play(entryIndex, targetPlayer, otherPlayer) {
        const curr = playlist[entryIndex % playlist.length];
        const next = playlist[(entryIndex + 1) % playlist.length];

        setTimeout(() => {
            _play(entryIndex+1, otherPlayer, targetPlayer);
        }, curr.duration * 1000);

        otherPlayer.classList.remove('is-active');
        otherPlayer.src = next.mediaURL;
        
        targetPlayer.classList.add('is-active');

        const ps = _playlist(curr, next);
        console.log("Setting playlist", ps);
        beacon.setPlaylist(ps);
        
        targetPlayer.play();
    }

    ui.videos[0].oncanplaythrough = () => {
        ui.videos[0].oncanplaythrough = null;
        _play(0, ui.videos[0], ui.videos[1]);
    };

    ui.videos[0].src = playlist[0].mediaURL;

    (scenario.notifications || []).forEach(({time, text, html}) => {
        setTimeout(() => {
            notifier.notify({text, html});
        }, time);
    });
}

function _playlist(curr, next) {
    const t1 = Date.now();
    const t2 = t1 + Math.floor(curr.duration * 1000);
    const t3 = t2 + Math.floor(next.duration * 1000);

    return [
        pb.Playstate.create({
            catchableId: curr.catchableID,
            campaignType: _getCampaignType(curr),
            startTimeMs: t1,
            endTimeMs: t2,
            catchesRequired: _getCatchesRequired(curr)
        }),
        pb.Playstate.create({
            catchableId: next.catchableID,
            campaignType: _getCampaignType(next),
            startTimeMs: t2,
            endTimeMs: t3,
            catchesRequired: _getCatchesRequired(next)
        })
    ];
}

function _getCampaignType(ent) {
    switch (ent.campaignType) {
        case 'chance'   : return pb.CampaignType.CAMPAIGN_TYPE_CHANCE;
        case 'collect'  : return pb.CampaignType.CAMPAIGN_TYPE_COLLECT;
        case 'info'     : return pb.CampaignType.CAMPAIGN_TYPE_INFO;
        case 'instant'  : return pb.CampaignType.CAMPAIGN_TYPE_INSTANT;
        default         : throw new Error("unkown campaign type: " + ent.campaignType);
    }
}

function _getCatchesRequired(ent) {
    if (ent.campaignType === 'collect') {
        return ent.catchesRequired;
    } else if (ent.campaignType === 'info') {
        return 0;
    }
    return 1;
}
