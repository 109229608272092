const VendorIDSiLabs           = 0x10c4;
const ProductIDCP2104          = 0xea60;
const ProductIDCP2105          = 0xea70;

const VendorIDSegger           = 0x1366;

const VendorIDMCS              = 0x16D0;
const ProductIDBloodaxeGen2    = 0x11BE;
const ProductIDBloodaxeGen2ESP = 0x11BF;

const BaudRate          = 115200;

function product(vid, pid) {
    return {
        usbVendorId: vid,
        usbProductId: pid
    };
}

const Filter = [
    product(VendorIDSiLabs, ProductIDCP2104),   // Silicon Labs CP2104 Single-UART USB Bridge
    product(VendorIDSiLabs, ProductIDCP2105),   // Silicon Labs CP2105 Dual-UART USB Bridge
    product(VendorIDSegger, 0x1015),            // PCA10040 J-Link USB Debug UART
    product(VendorIDSegger, 0x0105),            // PCA10040 J-Link USB Debug UART (V7.58d)
    product(VendorIDMCS, ProductIDBloodaxeGen2),
    product(VendorIDMCS, ProductIDBloodaxeGen2ESP)
];

export async function openSerialPort() {
    const port = await navigator.serial.requestPort({filters: Filter});
    await port.open({baudRate: BaudRate});
    return port;
}
