const ItemSpacing = 20;
const MakeSpaceTime = 500;

function measure(el) {
    el.classList.add('is-measuring');
    document.body.appendChild(el);
    const height = el.offsetHeight;
    document.body.removeChild(el);
    el.classList.remove('is-measuring');
    return height;
}

export class Notifier {
    constructor(root) {
        this._root = root;
        this._target = this._root.querySelector('.notifications--inner');
        this._clear = this._root.querySelector('button');

        this._clear.onclick = () => {
            this._clear.classList.add('is-out');
            this._target.innerHTML = '';
        }
    }

    notify({html, text = '', className = ''}) {
        this._clear.classList.remove('is-out');

        const el = document.createElement('div');
        el.className = `notification ${className}`;
        if (typeof html === 'string') {
            el.innerHTML = html;
        } else {
            el.textContent = text;
        }

        const height = measure(el);
        const spacer = document.createElement('div');
        spacer.className = 'notifications--spacer';
        this._target.appendChild(spacer);
        spacer.offsetWidth;
        spacer.style.height = `${height + ItemSpacing}px`;
        setTimeout(() => { spacer.replaceWith(el); }, MakeSpaceTime);
    }
}
