import {EventEmitter} from 'events';
import {BeaconComms} from './beacon-comms';
import * as pb from './protocol';

export class Beacon extends EventEmitter {
    constructor({serialPort, locationID, streamID, privateKey}) {
        super();

        this.locationID = locationID;
        this.streamID = streamID;

        this._privateKey = privateKey;

        this._comms = new BeaconComms(serialPort);
        this._comms.on('oob', (msg) => {
            if (msg.catchEvent) {
                const catchableID = msg.catchEvent.catchableId;
                console.log("Catch received for catchable ID %d", catchableID);
                this.emit('catch', {
                    catchableID: catchableID
                });
            }
        });
    }

    async start() {
        this._comms.start();
        await this._comms.sendRequest(this._makeSetModeRequest(pb.Mode.MODE_IDLE));
        await this._comms.sendRequest(this._makeSetConfigRequest());
        await this._comms.sendRequest(this._makeSetModeRequest(pb.Mode.MODE_ACTIVE_SIGNING));

        const _sync = async () => {
            console.log("Syncing time");
            await this._comms.sendRequest(this._makeSetTimeRequest());
            // TODO: feed watchdog
            setTimeout(_sync, 15000);
        }

        _sync();
    }

    setPlaylist(p) {
        const req = this._makeSetPlaylistRequest(p);
        return this._comms.sendRequest(req);
    }

    _makeSetModeRequest(mode) {
        return pb.Request.create({
            setMode: pb.Request.SetMode.create({
                mode: mode
            })
        });
    }

    _makeSetConfigRequest() {
        return pb.Request.create({
            setConfig: pb.Request.SetConfig.create({
                deviceId: this.locationID,
                streamId: this.streamID,
                locationId: this.locationID,
                privateSigningKey: this._privateKey,
                ibeaconEnable: true
            })
        });
    }

    _makeSetTimeRequest() {
        return pb.Request.create({
            setTime: pb.Request.SetTime.create({
                systimeMs: Date.now()
            })
        });
    }

    _makeSetPlaylistRequest(playlist) {
        return pb.Request.create({
            setPlaylist: pb.Request.SetPlaylist.create({
                playstates: playlist
            })
        });
    }
}
