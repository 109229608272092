/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

/**
 * Status codes included in Response and ProtocolError messages.
 * @exports StatusCode
 * @enum {number}
 * @property {number} STATUS_SUCCESS=0 STATUS_SUCCESS value
 * @property {number} STATUS_ERROR_NO_MEM=1 STATUS_ERROR_NO_MEM value
 * @property {number} STATUS_ERROR_NOT_FOUND=2 STATUS_ERROR_NOT_FOUND value
 * @property {number} STATUS_ERROR_NOT_SUPPORTED=3 STATUS_ERROR_NOT_SUPPORTED value
 * @property {number} STATUS_ERROR_NOT_AUTHORIZED=4 STATUS_ERROR_NOT_AUTHORIZED value
 * @property {number} STATUS_ERROR_INVALID_PARAM=5 STATUS_ERROR_INVALID_PARAM value
 * @property {number} STATUS_ERROR_INVALID_STATE=6 STATUS_ERROR_INVALID_STATE value
 * @property {number} STATUS_ERROR_INVALID_LENGTH=7 STATUS_ERROR_INVALID_LENGTH value
 * @property {number} STATUS_ERROR_INVALID_DATA=8 STATUS_ERROR_INVALID_DATA value
 * @property {number} STATUS_ERROR_TIMEOUT=9 STATUS_ERROR_TIMEOUT value
 * @property {number} STATUS_ERROR_BUSY=10 STATUS_ERROR_BUSY value
 * @property {number} STATUS_ERROR_IO=11 STATUS_ERROR_IO value
 * @property {number} STATUS_ERROR_INTERNAL=12 STATUS_ERROR_INTERNAL value
 * @property {number} STATUS_ERROR_LINK=13 STATUS_ERROR_LINK value
 * @property {number} STATUS_ERROR_FRAMING=14 STATUS_ERROR_FRAMING value
 * @property {number} STATUS_ERROR_DECODING=15 STATUS_ERROR_DECODING value
 */
export const StatusCode = $root.StatusCode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "STATUS_SUCCESS"] = 0;
    values[valuesById[1] = "STATUS_ERROR_NO_MEM"] = 1;
    values[valuesById[2] = "STATUS_ERROR_NOT_FOUND"] = 2;
    values[valuesById[3] = "STATUS_ERROR_NOT_SUPPORTED"] = 3;
    values[valuesById[4] = "STATUS_ERROR_NOT_AUTHORIZED"] = 4;
    values[valuesById[5] = "STATUS_ERROR_INVALID_PARAM"] = 5;
    values[valuesById[6] = "STATUS_ERROR_INVALID_STATE"] = 6;
    values[valuesById[7] = "STATUS_ERROR_INVALID_LENGTH"] = 7;
    values[valuesById[8] = "STATUS_ERROR_INVALID_DATA"] = 8;
    values[valuesById[9] = "STATUS_ERROR_TIMEOUT"] = 9;
    values[valuesById[10] = "STATUS_ERROR_BUSY"] = 10;
    values[valuesById[11] = "STATUS_ERROR_IO"] = 11;
    values[valuesById[12] = "STATUS_ERROR_INTERNAL"] = 12;
    values[valuesById[13] = "STATUS_ERROR_LINK"] = 13;
    values[valuesById[14] = "STATUS_ERROR_FRAMING"] = 14;
    values[valuesById[15] = "STATUS_ERROR_DECODING"] = 15;
    return values;
})();

/**
 * Application run modes.
 * The device supports multiple different application modes, each with different BLE behaviour.
 * @exports Mode
 * @enum {number}
 * @property {number} MODE_OFF=0 MODE_OFF value
 * @property {number} MODE_IDLE=1 MODE_IDLE value
 * @property {number} MODE_ACTIVE_SIGNING=2 MODE_ACTIVE_SIGNING value
 * @property {number} MODE_ACTIVE_NO_SIGNING=3 MODE_ACTIVE_NO_SIGNING value
 * @property {number} MODE_PASSIVE_SIGNING=4 MODE_PASSIVE_SIGNING value
 * @property {number} MODE_PASSIVE_NO_SIGNING=5 MODE_PASSIVE_NO_SIGNING value
 * @property {number} MODE_FACTORY_TEST=6 MODE_FACTORY_TEST value
 * @property {number} MODE_DEMO=7 MODE_DEMO value
 */
export const Mode = $root.Mode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "MODE_OFF"] = 0;
    values[valuesById[1] = "MODE_IDLE"] = 1;
    values[valuesById[2] = "MODE_ACTIVE_SIGNING"] = 2;
    values[valuesById[3] = "MODE_ACTIVE_NO_SIGNING"] = 3;
    values[valuesById[4] = "MODE_PASSIVE_SIGNING"] = 4;
    values[valuesById[5] = "MODE_PASSIVE_NO_SIGNING"] = 5;
    values[valuesById[6] = "MODE_FACTORY_TEST"] = 6;
    values[valuesById[7] = "MODE_DEMO"] = 7;
    return values;
})();

/**
 * Catch campaign types.
 * @exports CampaignType
 * @enum {number}
 * @property {number} CAMPAIGN_TYPE_CHANCE=0 CAMPAIGN_TYPE_CHANCE value
 * @property {number} CAMPAIGN_TYPE_INSTANT=1 CAMPAIGN_TYPE_INSTANT value
 * @property {number} CAMPAIGN_TYPE_COLLECT=2 CAMPAIGN_TYPE_COLLECT value
 * @property {number} CAMPAIGN_TYPE_INFO=3 CAMPAIGN_TYPE_INFO value
 * @property {number} CAMPAIGN_TYPE_UNKNOWN=4 CAMPAIGN_TYPE_UNKNOWN value
 */
export const CampaignType = $root.CampaignType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CAMPAIGN_TYPE_CHANCE"] = 0;
    values[valuesById[1] = "CAMPAIGN_TYPE_INSTANT"] = 1;
    values[valuesById[2] = "CAMPAIGN_TYPE_COLLECT"] = 2;
    values[valuesById[3] = "CAMPAIGN_TYPE_INFO"] = 3;
    values[valuesById[4] = "CAMPAIGN_TYPE_UNKNOWN"] = 4;
    return values;
})();

/**
 * FaultType enum.
 * @exports FaultType
 * @enum {number}
 * @property {number} FAULT_TYPE_ASSERT=0 FAULT_TYPE_ASSERT value
 * @property {number} FAULT_TYPE_WATCHDOG_TIMEOUT=1 FAULT_TYPE_WATCHDOG_TIMEOUT value
 * @property {number} FAULT_TYPE_STACK_OVERFLOW=2 FAULT_TYPE_STACK_OVERFLOW value
 * @property {number} FAULT_TYPE_BUFFER_OVERFLOW=3 FAULT_TYPE_BUFFER_OVERFLOW value
 * @property {number} FAULT_TYPE_NULL_POINTER=4 FAULT_TYPE_NULL_POINTER value
 * @property {number} FAULT_TYPE_HF_INVALID_FP=5 FAULT_TYPE_HF_INVALID_FP value
 * @property {number} FAULT_TYPE_HF_UNDEF_INSTR=6 FAULT_TYPE_HF_UNDEF_INSTR value
 * @property {number} FAULT_TYPE_HF_LDM_ALIGN=7 FAULT_TYPE_HF_LDM_ALIGN value
 */
export const FaultType = $root.FaultType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "FAULT_TYPE_ASSERT"] = 0;
    values[valuesById[1] = "FAULT_TYPE_WATCHDOG_TIMEOUT"] = 1;
    values[valuesById[2] = "FAULT_TYPE_STACK_OVERFLOW"] = 2;
    values[valuesById[3] = "FAULT_TYPE_BUFFER_OVERFLOW"] = 3;
    values[valuesById[4] = "FAULT_TYPE_NULL_POINTER"] = 4;
    values[valuesById[5] = "FAULT_TYPE_HF_INVALID_FP"] = 5;
    values[valuesById[6] = "FAULT_TYPE_HF_UNDEF_INSTR"] = 6;
    values[valuesById[7] = "FAULT_TYPE_HF_LDM_ALIGN"] = 7;
    return values;
})();

export const Protocol = $root.Protocol = (() => {

    /**
     * Properties of a Protocol.
     * @exports IProtocol
     * @interface IProtocol
     * @property {IRequest|null} [request] Protocol request
     * @property {IResponse|null} [response] Protocol response
     * @property {IOOB|null} [oob] Protocol oob
     * @property {IAuthenticatedRequest|null} [authenticatedRequest] Protocol authenticatedRequest
     */

    /**
     * Constructs a new Protocol.
     * @exports Protocol
     * @classdesc Top-level container for all Catch control protocol messages.
     * @implements IProtocol
     * @constructor
     * @param {IProtocol=} [properties] Properties to set
     */
    function Protocol(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Protocol request.
     * @member {IRequest|null|undefined} request
     * @memberof Protocol
     * @instance
     */
    Protocol.prototype.request = null;

    /**
     * Protocol response.
     * @member {IResponse|null|undefined} response
     * @memberof Protocol
     * @instance
     */
    Protocol.prototype.response = null;

    /**
     * Protocol oob.
     * @member {IOOB|null|undefined} oob
     * @memberof Protocol
     * @instance
     */
    Protocol.prototype.oob = null;

    /**
     * Protocol authenticatedRequest.
     * @member {IAuthenticatedRequest|null|undefined} authenticatedRequest
     * @memberof Protocol
     * @instance
     */
    Protocol.prototype.authenticatedRequest = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * Catch control protocol message
     * @member {"request"|"response"|"oob"|"authenticatedRequest"|undefined} msg
     * @memberof Protocol
     * @instance
     */
    Object.defineProperty(Protocol.prototype, "msg", {
        get: $util.oneOfGetter($oneOfFields = ["request", "response", "oob", "authenticatedRequest"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Protocol instance using the specified properties.
     * @function create
     * @memberof Protocol
     * @static
     * @param {IProtocol=} [properties] Properties to set
     * @returns {Protocol} Protocol instance
     */
    Protocol.create = function create(properties) {
        return new Protocol(properties);
    };

    /**
     * Encodes the specified Protocol message. Does not implicitly {@link Protocol.verify|verify} messages.
     * @function encode
     * @memberof Protocol
     * @static
     * @param {IProtocol} message Protocol message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Protocol.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.request != null && Object.hasOwnProperty.call(message, "request"))
            $root.Request.encode(message.request, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.response != null && Object.hasOwnProperty.call(message, "response"))
            $root.Response.encode(message.response, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.oob != null && Object.hasOwnProperty.call(message, "oob"))
            $root.OOB.encode(message.oob, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.authenticatedRequest != null && Object.hasOwnProperty.call(message, "authenticatedRequest"))
            $root.AuthenticatedRequest.encode(message.authenticatedRequest, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Protocol message, length delimited. Does not implicitly {@link Protocol.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Protocol
     * @static
     * @param {IProtocol} message Protocol message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Protocol.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Protocol message from the specified reader or buffer.
     * @function decode
     * @memberof Protocol
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Protocol} Protocol
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Protocol.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Protocol();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.request = $root.Request.decode(reader, reader.uint32());
                break;
            case 2:
                message.response = $root.Response.decode(reader, reader.uint32());
                break;
            case 3:
                message.oob = $root.OOB.decode(reader, reader.uint32());
                break;
            case 4:
                message.authenticatedRequest = $root.AuthenticatedRequest.decode(reader, reader.uint32());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Protocol message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Protocol
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Protocol} Protocol
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Protocol.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Protocol message.
     * @function verify
     * @memberof Protocol
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Protocol.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.request != null && message.hasOwnProperty("request")) {
            properties.msg = 1;
            {
                let error = $root.Request.verify(message.request);
                if (error)
                    return "request." + error;
            }
        }
        if (message.response != null && message.hasOwnProperty("response")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.verify(message.response);
                if (error)
                    return "response." + error;
            }
        }
        if (message.oob != null && message.hasOwnProperty("oob")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.OOB.verify(message.oob);
                if (error)
                    return "oob." + error;
            }
        }
        if (message.authenticatedRequest != null && message.hasOwnProperty("authenticatedRequest")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.AuthenticatedRequest.verify(message.authenticatedRequest);
                if (error)
                    return "authenticatedRequest." + error;
            }
        }
        return null;
    };

    /**
     * Creates a Protocol message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Protocol
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Protocol} Protocol
     */
    Protocol.fromObject = function fromObject(object) {
        if (object instanceof $root.Protocol)
            return object;
        let message = new $root.Protocol();
        if (object.request != null) {
            if (typeof object.request !== "object")
                throw TypeError(".Protocol.request: object expected");
            message.request = $root.Request.fromObject(object.request);
        }
        if (object.response != null) {
            if (typeof object.response !== "object")
                throw TypeError(".Protocol.response: object expected");
            message.response = $root.Response.fromObject(object.response);
        }
        if (object.oob != null) {
            if (typeof object.oob !== "object")
                throw TypeError(".Protocol.oob: object expected");
            message.oob = $root.OOB.fromObject(object.oob);
        }
        if (object.authenticatedRequest != null) {
            if (typeof object.authenticatedRequest !== "object")
                throw TypeError(".Protocol.authenticatedRequest: object expected");
            message.authenticatedRequest = $root.AuthenticatedRequest.fromObject(object.authenticatedRequest);
        }
        return message;
    };

    /**
     * Creates a plain object from a Protocol message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Protocol
     * @static
     * @param {Protocol} message Protocol
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Protocol.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.request != null && message.hasOwnProperty("request")) {
            object.request = $root.Request.toObject(message.request, options);
            if (options.oneofs)
                object.msg = "request";
        }
        if (message.response != null && message.hasOwnProperty("response")) {
            object.response = $root.Response.toObject(message.response, options);
            if (options.oneofs)
                object.msg = "response";
        }
        if (message.oob != null && message.hasOwnProperty("oob")) {
            object.oob = $root.OOB.toObject(message.oob, options);
            if (options.oneofs)
                object.msg = "oob";
        }
        if (message.authenticatedRequest != null && message.hasOwnProperty("authenticatedRequest")) {
            object.authenticatedRequest = $root.AuthenticatedRequest.toObject(message.authenticatedRequest, options);
            if (options.oneofs)
                object.msg = "authenticatedRequest";
        }
        return object;
    };

    /**
     * Converts this Protocol to JSON.
     * @function toJSON
     * @memberof Protocol
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Protocol.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Protocol;
})();

export const AuthenticatedRequest = $root.AuthenticatedRequest = (() => {

    /**
     * Properties of an AuthenticatedRequest.
     * @exports IAuthenticatedRequest
     * @interface IAuthenticatedRequest
     * @property {Uint8Array|null} [serialisedRequest] AuthenticatedRequest serialisedRequest
     * @property {Uint8Array|null} [signature] AuthenticatedRequest signature
     */

    /**
     * Constructs a new AuthenticatedRequest.
     * @exports AuthenticatedRequest
     * @classdesc This message is used in unauthenticated connections where the request must be
     * accompanied by a digital signature to prove its authenticity.
     * 1. The original request message is serialised and included in the serialised_request field.
     * NOTE: The nonce field of the request MUST be set to the nonce previously sent
     * by the the device in response to the get_nonce command.
     * 2. The signature field then includes a digital signature of this serialised request.
     * @implements IAuthenticatedRequest
     * @constructor
     * @param {IAuthenticatedRequest=} [properties] Properties to set
     */
    function AuthenticatedRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AuthenticatedRequest serialisedRequest.
     * @member {Uint8Array} serialisedRequest
     * @memberof AuthenticatedRequest
     * @instance
     */
    AuthenticatedRequest.prototype.serialisedRequest = $util.newBuffer([]);

    /**
     * AuthenticatedRequest signature.
     * @member {Uint8Array} signature
     * @memberof AuthenticatedRequest
     * @instance
     */
    AuthenticatedRequest.prototype.signature = $util.newBuffer([]);

    /**
     * Creates a new AuthenticatedRequest instance using the specified properties.
     * @function create
     * @memberof AuthenticatedRequest
     * @static
     * @param {IAuthenticatedRequest=} [properties] Properties to set
     * @returns {AuthenticatedRequest} AuthenticatedRequest instance
     */
    AuthenticatedRequest.create = function create(properties) {
        return new AuthenticatedRequest(properties);
    };

    /**
     * Encodes the specified AuthenticatedRequest message. Does not implicitly {@link AuthenticatedRequest.verify|verify} messages.
     * @function encode
     * @memberof AuthenticatedRequest
     * @static
     * @param {IAuthenticatedRequest} message AuthenticatedRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AuthenticatedRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.serialisedRequest != null && Object.hasOwnProperty.call(message, "serialisedRequest"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.serialisedRequest);
        if (message.signature != null && Object.hasOwnProperty.call(message, "signature"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.signature);
        return writer;
    };

    /**
     * Encodes the specified AuthenticatedRequest message, length delimited. Does not implicitly {@link AuthenticatedRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AuthenticatedRequest
     * @static
     * @param {IAuthenticatedRequest} message AuthenticatedRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AuthenticatedRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AuthenticatedRequest message from the specified reader or buffer.
     * @function decode
     * @memberof AuthenticatedRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AuthenticatedRequest} AuthenticatedRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AuthenticatedRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AuthenticatedRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.serialisedRequest = reader.bytes();
                break;
            case 2:
                message.signature = reader.bytes();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AuthenticatedRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AuthenticatedRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AuthenticatedRequest} AuthenticatedRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AuthenticatedRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AuthenticatedRequest message.
     * @function verify
     * @memberof AuthenticatedRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AuthenticatedRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.serialisedRequest != null && message.hasOwnProperty("serialisedRequest"))
            if (!(message.serialisedRequest && typeof message.serialisedRequest.length === "number" || $util.isString(message.serialisedRequest)))
                return "serialisedRequest: buffer expected";
        if (message.signature != null && message.hasOwnProperty("signature"))
            if (!(message.signature && typeof message.signature.length === "number" || $util.isString(message.signature)))
                return "signature: buffer expected";
        return null;
    };

    /**
     * Creates an AuthenticatedRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AuthenticatedRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AuthenticatedRequest} AuthenticatedRequest
     */
    AuthenticatedRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AuthenticatedRequest)
            return object;
        let message = new $root.AuthenticatedRequest();
        if (object.serialisedRequest != null)
            if (typeof object.serialisedRequest === "string")
                $util.base64.decode(object.serialisedRequest, message.serialisedRequest = $util.newBuffer($util.base64.length(object.serialisedRequest)), 0);
            else if (object.serialisedRequest.length)
                message.serialisedRequest = object.serialisedRequest;
        if (object.signature != null)
            if (typeof object.signature === "string")
                $util.base64.decode(object.signature, message.signature = $util.newBuffer($util.base64.length(object.signature)), 0);
            else if (object.signature.length)
                message.signature = object.signature;
        return message;
    };

    /**
     * Creates a plain object from an AuthenticatedRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AuthenticatedRequest
     * @static
     * @param {AuthenticatedRequest} message AuthenticatedRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AuthenticatedRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.serialisedRequest = "";
            else {
                object.serialisedRequest = [];
                if (options.bytes !== Array)
                    object.serialisedRequest = $util.newBuffer(object.serialisedRequest);
            }
            if (options.bytes === String)
                object.signature = "";
            else {
                object.signature = [];
                if (options.bytes !== Array)
                    object.signature = $util.newBuffer(object.signature);
            }
        }
        if (message.serialisedRequest != null && message.hasOwnProperty("serialisedRequest"))
            object.serialisedRequest = options.bytes === String ? $util.base64.encode(message.serialisedRequest, 0, message.serialisedRequest.length) : options.bytes === Array ? Array.prototype.slice.call(message.serialisedRequest) : message.serialisedRequest;
        if (message.signature != null && message.hasOwnProperty("signature"))
            object.signature = options.bytes === String ? $util.base64.encode(message.signature, 0, message.signature.length) : options.bytes === Array ? Array.prototype.slice.call(message.signature) : message.signature;
        return object;
    };

    /**
     * Converts this AuthenticatedRequest to JSON.
     * @function toJSON
     * @memberof AuthenticatedRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AuthenticatedRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return AuthenticatedRequest;
})();

export const Request = $root.Request = (() => {

    /**
     * Properties of a Request.
     * @exports IRequest
     * @interface IRequest
     * @property {number|null} [correlationId] Unique correlation ID that can be set by the client to allow them to track and process
     * messages asynchronously and out of order.
     * @property {Uint8Array|null} [nonce] Nonce that is set when using AuthenticatedRequest messages. This field is set to the
     * nonce value received prior using the get_nonce command.
     * @property {Request.IPing|null} [ping] Request ping
     * @property {Request.IGetVersion|null} [getVersion] Request getVersion
     * @property {Request.IGetDeviceInfo|null} [getDeviceInfo] Request getDeviceInfo
     * @property {Request.IGetTemperature|null} [getTemperature] Request getTemperature
     * @property {Request.IGetNonce|null} [getNonce] Request getNonce
     * @property {Request.IGetTime|null} [getTime] Request getTime
     * @property {Request.ISetTime|null} [setTime] Request setTime
     * @property {Request.IGetConfig|null} [getConfig] Request getConfig
     * @property {Request.ISetConfig|null} [setConfig] Request setConfig
     * @property {Request.IGetMode|null} [getMode] Request getMode
     * @property {Request.ISetMode|null} [setMode] Request setMode
     * @property {Request.IGetPlaylist|null} [getPlaylist] Request getPlaylist
     * @property {Request.ISetPlaylist|null} [setPlaylist] Request setPlaylist
     * @property {Request.IGetPlaystate|null} [getPlaystate] Request getPlaystate
     * @property {Request.IAuthenticate|null} [authenticate] Request authenticate
     * @property {Request.IEnterBootloader|null} [enterBootloader] Request enterBootloader
     * @property {Request.IReboot|null} [reboot] Request reboot
     * @property {Request.IFactoryReset|null} [factoryReset] Request factoryReset
     * @property {Request.IEnableWatchdog|null} [enableWatchdog] Request enableWatchdog
     * @property {Request.IFeedWatchdog|null} [feedWatchdog] Request feedWatchdog
     * @property {Request.ITriggerFault|null} [triggerFault] Request triggerFault
     */

    /**
     * Constructs a new Request.
     * @exports Request
     * @classdesc Request messages sent by the client.
     * The device will respond with a Response message for every request it receives.
     * @implements IRequest
     * @constructor
     * @param {IRequest=} [properties] Properties to set
     */
    function Request(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Unique correlation ID that can be set by the client to allow them to track and process
     * messages asynchronously and out of order.
     * @member {number} correlationId
     * @memberof Request
     * @instance
     */
    Request.prototype.correlationId = 0;

    /**
     * Nonce that is set when using AuthenticatedRequest messages. This field is set to the
     * nonce value received prior using the get_nonce command.
     * @member {Uint8Array|null|undefined} nonce
     * @memberof Request
     * @instance
     */
    Request.prototype.nonce = null;

    /**
     * Request ping.
     * @member {Request.IPing|null|undefined} ping
     * @memberof Request
     * @instance
     */
    Request.prototype.ping = null;

    /**
     * Request getVersion.
     * @member {Request.IGetVersion|null|undefined} getVersion
     * @memberof Request
     * @instance
     */
    Request.prototype.getVersion = null;

    /**
     * Request getDeviceInfo.
     * @member {Request.IGetDeviceInfo|null|undefined} getDeviceInfo
     * @memberof Request
     * @instance
     */
    Request.prototype.getDeviceInfo = null;

    /**
     * Request getTemperature.
     * @member {Request.IGetTemperature|null|undefined} getTemperature
     * @memberof Request
     * @instance
     */
    Request.prototype.getTemperature = null;

    /**
     * Request getNonce.
     * @member {Request.IGetNonce|null|undefined} getNonce
     * @memberof Request
     * @instance
     */
    Request.prototype.getNonce = null;

    /**
     * Request getTime.
     * @member {Request.IGetTime|null|undefined} getTime
     * @memberof Request
     * @instance
     */
    Request.prototype.getTime = null;

    /**
     * Request setTime.
     * @member {Request.ISetTime|null|undefined} setTime
     * @memberof Request
     * @instance
     */
    Request.prototype.setTime = null;

    /**
     * Request getConfig.
     * @member {Request.IGetConfig|null|undefined} getConfig
     * @memberof Request
     * @instance
     */
    Request.prototype.getConfig = null;

    /**
     * Request setConfig.
     * @member {Request.ISetConfig|null|undefined} setConfig
     * @memberof Request
     * @instance
     */
    Request.prototype.setConfig = null;

    /**
     * Request getMode.
     * @member {Request.IGetMode|null|undefined} getMode
     * @memberof Request
     * @instance
     */
    Request.prototype.getMode = null;

    /**
     * Request setMode.
     * @member {Request.ISetMode|null|undefined} setMode
     * @memberof Request
     * @instance
     */
    Request.prototype.setMode = null;

    /**
     * Request getPlaylist.
     * @member {Request.IGetPlaylist|null|undefined} getPlaylist
     * @memberof Request
     * @instance
     */
    Request.prototype.getPlaylist = null;

    /**
     * Request setPlaylist.
     * @member {Request.ISetPlaylist|null|undefined} setPlaylist
     * @memberof Request
     * @instance
     */
    Request.prototype.setPlaylist = null;

    /**
     * Request getPlaystate.
     * @member {Request.IGetPlaystate|null|undefined} getPlaystate
     * @memberof Request
     * @instance
     */
    Request.prototype.getPlaystate = null;

    /**
     * Request authenticate.
     * @member {Request.IAuthenticate|null|undefined} authenticate
     * @memberof Request
     * @instance
     */
    Request.prototype.authenticate = null;

    /**
     * Request enterBootloader.
     * @member {Request.IEnterBootloader|null|undefined} enterBootloader
     * @memberof Request
     * @instance
     */
    Request.prototype.enterBootloader = null;

    /**
     * Request reboot.
     * @member {Request.IReboot|null|undefined} reboot
     * @memberof Request
     * @instance
     */
    Request.prototype.reboot = null;

    /**
     * Request factoryReset.
     * @member {Request.IFactoryReset|null|undefined} factoryReset
     * @memberof Request
     * @instance
     */
    Request.prototype.factoryReset = null;

    /**
     * Request enableWatchdog.
     * @member {Request.IEnableWatchdog|null|undefined} enableWatchdog
     * @memberof Request
     * @instance
     */
    Request.prototype.enableWatchdog = null;

    /**
     * Request feedWatchdog.
     * @member {Request.IFeedWatchdog|null|undefined} feedWatchdog
     * @memberof Request
     * @instance
     */
    Request.prototype.feedWatchdog = null;

    /**
     * Request triggerFault.
     * @member {Request.ITriggerFault|null|undefined} triggerFault
     * @memberof Request
     * @instance
     */
    Request.prototype.triggerFault = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * Request _nonce.
     * @member {"nonce"|undefined} _nonce
     * @memberof Request
     * @instance
     */
    Object.defineProperty(Request.prototype, "_nonce", {
        get: $util.oneOfGetter($oneOfFields = ["nonce"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Request message.
     * @member {"ping"|"getVersion"|"getDeviceInfo"|"getTemperature"|"getNonce"|"getTime"|"setTime"|"getConfig"|"setConfig"|"getMode"|"setMode"|"getPlaylist"|"setPlaylist"|"getPlaystate"|"authenticate"|"enterBootloader"|"reboot"|"factoryReset"|"enableWatchdog"|"feedWatchdog"|"triggerFault"|undefined} msg
     * @memberof Request
     * @instance
     */
    Object.defineProperty(Request.prototype, "msg", {
        get: $util.oneOfGetter($oneOfFields = ["ping", "getVersion", "getDeviceInfo", "getTemperature", "getNonce", "getTime", "setTime", "getConfig", "setConfig", "getMode", "setMode", "getPlaylist", "setPlaylist", "getPlaystate", "authenticate", "enterBootloader", "reboot", "factoryReset", "enableWatchdog", "feedWatchdog", "triggerFault"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Request instance using the specified properties.
     * @function create
     * @memberof Request
     * @static
     * @param {IRequest=} [properties] Properties to set
     * @returns {Request} Request instance
     */
    Request.create = function create(properties) {
        return new Request(properties);
    };

    /**
     * Encodes the specified Request message. Does not implicitly {@link Request.verify|verify} messages.
     * @function encode
     * @memberof Request
     * @static
     * @param {IRequest} message Request message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Request.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.correlationId != null && Object.hasOwnProperty.call(message, "correlationId"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.correlationId);
        if (message.nonce != null && Object.hasOwnProperty.call(message, "nonce"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.nonce);
        if (message.ping != null && Object.hasOwnProperty.call(message, "ping"))
            $root.Request.Ping.encode(message.ping, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.getVersion != null && Object.hasOwnProperty.call(message, "getVersion"))
            $root.Request.GetVersion.encode(message.getVersion, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.getDeviceInfo != null && Object.hasOwnProperty.call(message, "getDeviceInfo"))
            $root.Request.GetDeviceInfo.encode(message.getDeviceInfo, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.getTemperature != null && Object.hasOwnProperty.call(message, "getTemperature"))
            $root.Request.GetTemperature.encode(message.getTemperature, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.getNonce != null && Object.hasOwnProperty.call(message, "getNonce"))
            $root.Request.GetNonce.encode(message.getNonce, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.getTime != null && Object.hasOwnProperty.call(message, "getTime"))
            $root.Request.GetTime.encode(message.getTime, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.setTime != null && Object.hasOwnProperty.call(message, "setTime"))
            $root.Request.SetTime.encode(message.setTime, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.getConfig != null && Object.hasOwnProperty.call(message, "getConfig"))
            $root.Request.GetConfig.encode(message.getConfig, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.setConfig != null && Object.hasOwnProperty.call(message, "setConfig"))
            $root.Request.SetConfig.encode(message.setConfig, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.getMode != null && Object.hasOwnProperty.call(message, "getMode"))
            $root.Request.GetMode.encode(message.getMode, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.setMode != null && Object.hasOwnProperty.call(message, "setMode"))
            $root.Request.SetMode.encode(message.setMode, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.getPlaylist != null && Object.hasOwnProperty.call(message, "getPlaylist"))
            $root.Request.GetPlaylist.encode(message.getPlaylist, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.setPlaylist != null && Object.hasOwnProperty.call(message, "setPlaylist"))
            $root.Request.SetPlaylist.encode(message.setPlaylist, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
        if (message.getPlaystate != null && Object.hasOwnProperty.call(message, "getPlaystate"))
            $root.Request.GetPlaystate.encode(message.getPlaystate, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
        if (message.authenticate != null && Object.hasOwnProperty.call(message, "authenticate"))
            $root.Request.Authenticate.encode(message.authenticate, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
        if (message.enterBootloader != null && Object.hasOwnProperty.call(message, "enterBootloader"))
            $root.Request.EnterBootloader.encode(message.enterBootloader, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        if (message.reboot != null && Object.hasOwnProperty.call(message, "reboot"))
            $root.Request.Reboot.encode(message.reboot, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        if (message.factoryReset != null && Object.hasOwnProperty.call(message, "factoryReset"))
            $root.Request.FactoryReset.encode(message.factoryReset, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
        if (message.enableWatchdog != null && Object.hasOwnProperty.call(message, "enableWatchdog"))
            $root.Request.EnableWatchdog.encode(message.enableWatchdog, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
        if (message.feedWatchdog != null && Object.hasOwnProperty.call(message, "feedWatchdog"))
            $root.Request.FeedWatchdog.encode(message.feedWatchdog, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
        if (message.triggerFault != null && Object.hasOwnProperty.call(message, "triggerFault"))
            $root.Request.TriggerFault.encode(message.triggerFault, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Request message, length delimited. Does not implicitly {@link Request.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Request
     * @static
     * @param {IRequest} message Request message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Request.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Request message from the specified reader or buffer.
     * @function decode
     * @memberof Request
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Request} Request
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Request.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.correlationId = reader.uint32();
                break;
            case 2:
                message.nonce = reader.bytes();
                break;
            case 5:
                message.ping = $root.Request.Ping.decode(reader, reader.uint32());
                break;
            case 6:
                message.getVersion = $root.Request.GetVersion.decode(reader, reader.uint32());
                break;
            case 7:
                message.getDeviceInfo = $root.Request.GetDeviceInfo.decode(reader, reader.uint32());
                break;
            case 8:
                message.getTemperature = $root.Request.GetTemperature.decode(reader, reader.uint32());
                break;
            case 9:
                message.getNonce = $root.Request.GetNonce.decode(reader, reader.uint32());
                break;
            case 10:
                message.getTime = $root.Request.GetTime.decode(reader, reader.uint32());
                break;
            case 11:
                message.setTime = $root.Request.SetTime.decode(reader, reader.uint32());
                break;
            case 12:
                message.getConfig = $root.Request.GetConfig.decode(reader, reader.uint32());
                break;
            case 13:
                message.setConfig = $root.Request.SetConfig.decode(reader, reader.uint32());
                break;
            case 14:
                message.getMode = $root.Request.GetMode.decode(reader, reader.uint32());
                break;
            case 15:
                message.setMode = $root.Request.SetMode.decode(reader, reader.uint32());
                break;
            case 16:
                message.getPlaylist = $root.Request.GetPlaylist.decode(reader, reader.uint32());
                break;
            case 17:
                message.setPlaylist = $root.Request.SetPlaylist.decode(reader, reader.uint32());
                break;
            case 18:
                message.getPlaystate = $root.Request.GetPlaystate.decode(reader, reader.uint32());
                break;
            case 19:
                message.authenticate = $root.Request.Authenticate.decode(reader, reader.uint32());
                break;
            case 20:
                message.enterBootloader = $root.Request.EnterBootloader.decode(reader, reader.uint32());
                break;
            case 21:
                message.reboot = $root.Request.Reboot.decode(reader, reader.uint32());
                break;
            case 22:
                message.factoryReset = $root.Request.FactoryReset.decode(reader, reader.uint32());
                break;
            case 23:
                message.enableWatchdog = $root.Request.EnableWatchdog.decode(reader, reader.uint32());
                break;
            case 24:
                message.feedWatchdog = $root.Request.FeedWatchdog.decode(reader, reader.uint32());
                break;
            case 25:
                message.triggerFault = $root.Request.TriggerFault.decode(reader, reader.uint32());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Request message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Request
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Request} Request
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Request.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Request message.
     * @function verify
     * @memberof Request
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Request.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.correlationId != null && message.hasOwnProperty("correlationId"))
            if (!$util.isInteger(message.correlationId))
                return "correlationId: integer expected";
        if (message.nonce != null && message.hasOwnProperty("nonce")) {
            properties._nonce = 1;
            if (!(message.nonce && typeof message.nonce.length === "number" || $util.isString(message.nonce)))
                return "nonce: buffer expected";
        }
        if (message.ping != null && message.hasOwnProperty("ping")) {
            properties.msg = 1;
            {
                let error = $root.Request.Ping.verify(message.ping);
                if (error)
                    return "ping." + error;
            }
        }
        if (message.getVersion != null && message.hasOwnProperty("getVersion")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.GetVersion.verify(message.getVersion);
                if (error)
                    return "getVersion." + error;
            }
        }
        if (message.getDeviceInfo != null && message.hasOwnProperty("getDeviceInfo")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.GetDeviceInfo.verify(message.getDeviceInfo);
                if (error)
                    return "getDeviceInfo." + error;
            }
        }
        if (message.getTemperature != null && message.hasOwnProperty("getTemperature")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.GetTemperature.verify(message.getTemperature);
                if (error)
                    return "getTemperature." + error;
            }
        }
        if (message.getNonce != null && message.hasOwnProperty("getNonce")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.GetNonce.verify(message.getNonce);
                if (error)
                    return "getNonce." + error;
            }
        }
        if (message.getTime != null && message.hasOwnProperty("getTime")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.GetTime.verify(message.getTime);
                if (error)
                    return "getTime." + error;
            }
        }
        if (message.setTime != null && message.hasOwnProperty("setTime")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.SetTime.verify(message.setTime);
                if (error)
                    return "setTime." + error;
            }
        }
        if (message.getConfig != null && message.hasOwnProperty("getConfig")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.GetConfig.verify(message.getConfig);
                if (error)
                    return "getConfig." + error;
            }
        }
        if (message.setConfig != null && message.hasOwnProperty("setConfig")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.SetConfig.verify(message.setConfig);
                if (error)
                    return "setConfig." + error;
            }
        }
        if (message.getMode != null && message.hasOwnProperty("getMode")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.GetMode.verify(message.getMode);
                if (error)
                    return "getMode." + error;
            }
        }
        if (message.setMode != null && message.hasOwnProperty("setMode")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.SetMode.verify(message.setMode);
                if (error)
                    return "setMode." + error;
            }
        }
        if (message.getPlaylist != null && message.hasOwnProperty("getPlaylist")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.GetPlaylist.verify(message.getPlaylist);
                if (error)
                    return "getPlaylist." + error;
            }
        }
        if (message.setPlaylist != null && message.hasOwnProperty("setPlaylist")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.SetPlaylist.verify(message.setPlaylist);
                if (error)
                    return "setPlaylist." + error;
            }
        }
        if (message.getPlaystate != null && message.hasOwnProperty("getPlaystate")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.GetPlaystate.verify(message.getPlaystate);
                if (error)
                    return "getPlaystate." + error;
            }
        }
        if (message.authenticate != null && message.hasOwnProperty("authenticate")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.Authenticate.verify(message.authenticate);
                if (error)
                    return "authenticate." + error;
            }
        }
        if (message.enterBootloader != null && message.hasOwnProperty("enterBootloader")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.EnterBootloader.verify(message.enterBootloader);
                if (error)
                    return "enterBootloader." + error;
            }
        }
        if (message.reboot != null && message.hasOwnProperty("reboot")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.Reboot.verify(message.reboot);
                if (error)
                    return "reboot." + error;
            }
        }
        if (message.factoryReset != null && message.hasOwnProperty("factoryReset")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.FactoryReset.verify(message.factoryReset);
                if (error)
                    return "factoryReset." + error;
            }
        }
        if (message.enableWatchdog != null && message.hasOwnProperty("enableWatchdog")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.EnableWatchdog.verify(message.enableWatchdog);
                if (error)
                    return "enableWatchdog." + error;
            }
        }
        if (message.feedWatchdog != null && message.hasOwnProperty("feedWatchdog")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.FeedWatchdog.verify(message.feedWatchdog);
                if (error)
                    return "feedWatchdog." + error;
            }
        }
        if (message.triggerFault != null && message.hasOwnProperty("triggerFault")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Request.TriggerFault.verify(message.triggerFault);
                if (error)
                    return "triggerFault." + error;
            }
        }
        return null;
    };

    /**
     * Creates a Request message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Request
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Request} Request
     */
    Request.fromObject = function fromObject(object) {
        if (object instanceof $root.Request)
            return object;
        let message = new $root.Request();
        if (object.correlationId != null)
            message.correlationId = object.correlationId >>> 0;
        if (object.nonce != null)
            if (typeof object.nonce === "string")
                $util.base64.decode(object.nonce, message.nonce = $util.newBuffer($util.base64.length(object.nonce)), 0);
            else if (object.nonce.length)
                message.nonce = object.nonce;
        if (object.ping != null) {
            if (typeof object.ping !== "object")
                throw TypeError(".Request.ping: object expected");
            message.ping = $root.Request.Ping.fromObject(object.ping);
        }
        if (object.getVersion != null) {
            if (typeof object.getVersion !== "object")
                throw TypeError(".Request.getVersion: object expected");
            message.getVersion = $root.Request.GetVersion.fromObject(object.getVersion);
        }
        if (object.getDeviceInfo != null) {
            if (typeof object.getDeviceInfo !== "object")
                throw TypeError(".Request.getDeviceInfo: object expected");
            message.getDeviceInfo = $root.Request.GetDeviceInfo.fromObject(object.getDeviceInfo);
        }
        if (object.getTemperature != null) {
            if (typeof object.getTemperature !== "object")
                throw TypeError(".Request.getTemperature: object expected");
            message.getTemperature = $root.Request.GetTemperature.fromObject(object.getTemperature);
        }
        if (object.getNonce != null) {
            if (typeof object.getNonce !== "object")
                throw TypeError(".Request.getNonce: object expected");
            message.getNonce = $root.Request.GetNonce.fromObject(object.getNonce);
        }
        if (object.getTime != null) {
            if (typeof object.getTime !== "object")
                throw TypeError(".Request.getTime: object expected");
            message.getTime = $root.Request.GetTime.fromObject(object.getTime);
        }
        if (object.setTime != null) {
            if (typeof object.setTime !== "object")
                throw TypeError(".Request.setTime: object expected");
            message.setTime = $root.Request.SetTime.fromObject(object.setTime);
        }
        if (object.getConfig != null) {
            if (typeof object.getConfig !== "object")
                throw TypeError(".Request.getConfig: object expected");
            message.getConfig = $root.Request.GetConfig.fromObject(object.getConfig);
        }
        if (object.setConfig != null) {
            if (typeof object.setConfig !== "object")
                throw TypeError(".Request.setConfig: object expected");
            message.setConfig = $root.Request.SetConfig.fromObject(object.setConfig);
        }
        if (object.getMode != null) {
            if (typeof object.getMode !== "object")
                throw TypeError(".Request.getMode: object expected");
            message.getMode = $root.Request.GetMode.fromObject(object.getMode);
        }
        if (object.setMode != null) {
            if (typeof object.setMode !== "object")
                throw TypeError(".Request.setMode: object expected");
            message.setMode = $root.Request.SetMode.fromObject(object.setMode);
        }
        if (object.getPlaylist != null) {
            if (typeof object.getPlaylist !== "object")
                throw TypeError(".Request.getPlaylist: object expected");
            message.getPlaylist = $root.Request.GetPlaylist.fromObject(object.getPlaylist);
        }
        if (object.setPlaylist != null) {
            if (typeof object.setPlaylist !== "object")
                throw TypeError(".Request.setPlaylist: object expected");
            message.setPlaylist = $root.Request.SetPlaylist.fromObject(object.setPlaylist);
        }
        if (object.getPlaystate != null) {
            if (typeof object.getPlaystate !== "object")
                throw TypeError(".Request.getPlaystate: object expected");
            message.getPlaystate = $root.Request.GetPlaystate.fromObject(object.getPlaystate);
        }
        if (object.authenticate != null) {
            if (typeof object.authenticate !== "object")
                throw TypeError(".Request.authenticate: object expected");
            message.authenticate = $root.Request.Authenticate.fromObject(object.authenticate);
        }
        if (object.enterBootloader != null) {
            if (typeof object.enterBootloader !== "object")
                throw TypeError(".Request.enterBootloader: object expected");
            message.enterBootloader = $root.Request.EnterBootloader.fromObject(object.enterBootloader);
        }
        if (object.reboot != null) {
            if (typeof object.reboot !== "object")
                throw TypeError(".Request.reboot: object expected");
            message.reboot = $root.Request.Reboot.fromObject(object.reboot);
        }
        if (object.factoryReset != null) {
            if (typeof object.factoryReset !== "object")
                throw TypeError(".Request.factoryReset: object expected");
            message.factoryReset = $root.Request.FactoryReset.fromObject(object.factoryReset);
        }
        if (object.enableWatchdog != null) {
            if (typeof object.enableWatchdog !== "object")
                throw TypeError(".Request.enableWatchdog: object expected");
            message.enableWatchdog = $root.Request.EnableWatchdog.fromObject(object.enableWatchdog);
        }
        if (object.feedWatchdog != null) {
            if (typeof object.feedWatchdog !== "object")
                throw TypeError(".Request.feedWatchdog: object expected");
            message.feedWatchdog = $root.Request.FeedWatchdog.fromObject(object.feedWatchdog);
        }
        if (object.triggerFault != null) {
            if (typeof object.triggerFault !== "object")
                throw TypeError(".Request.triggerFault: object expected");
            message.triggerFault = $root.Request.TriggerFault.fromObject(object.triggerFault);
        }
        return message;
    };

    /**
     * Creates a plain object from a Request message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Request
     * @static
     * @param {Request} message Request
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Request.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.correlationId = 0;
        if (message.correlationId != null && message.hasOwnProperty("correlationId"))
            object.correlationId = message.correlationId;
        if (message.nonce != null && message.hasOwnProperty("nonce")) {
            object.nonce = options.bytes === String ? $util.base64.encode(message.nonce, 0, message.nonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.nonce) : message.nonce;
            if (options.oneofs)
                object._nonce = "nonce";
        }
        if (message.ping != null && message.hasOwnProperty("ping")) {
            object.ping = $root.Request.Ping.toObject(message.ping, options);
            if (options.oneofs)
                object.msg = "ping";
        }
        if (message.getVersion != null && message.hasOwnProperty("getVersion")) {
            object.getVersion = $root.Request.GetVersion.toObject(message.getVersion, options);
            if (options.oneofs)
                object.msg = "getVersion";
        }
        if (message.getDeviceInfo != null && message.hasOwnProperty("getDeviceInfo")) {
            object.getDeviceInfo = $root.Request.GetDeviceInfo.toObject(message.getDeviceInfo, options);
            if (options.oneofs)
                object.msg = "getDeviceInfo";
        }
        if (message.getTemperature != null && message.hasOwnProperty("getTemperature")) {
            object.getTemperature = $root.Request.GetTemperature.toObject(message.getTemperature, options);
            if (options.oneofs)
                object.msg = "getTemperature";
        }
        if (message.getNonce != null && message.hasOwnProperty("getNonce")) {
            object.getNonce = $root.Request.GetNonce.toObject(message.getNonce, options);
            if (options.oneofs)
                object.msg = "getNonce";
        }
        if (message.getTime != null && message.hasOwnProperty("getTime")) {
            object.getTime = $root.Request.GetTime.toObject(message.getTime, options);
            if (options.oneofs)
                object.msg = "getTime";
        }
        if (message.setTime != null && message.hasOwnProperty("setTime")) {
            object.setTime = $root.Request.SetTime.toObject(message.setTime, options);
            if (options.oneofs)
                object.msg = "setTime";
        }
        if (message.getConfig != null && message.hasOwnProperty("getConfig")) {
            object.getConfig = $root.Request.GetConfig.toObject(message.getConfig, options);
            if (options.oneofs)
                object.msg = "getConfig";
        }
        if (message.setConfig != null && message.hasOwnProperty("setConfig")) {
            object.setConfig = $root.Request.SetConfig.toObject(message.setConfig, options);
            if (options.oneofs)
                object.msg = "setConfig";
        }
        if (message.getMode != null && message.hasOwnProperty("getMode")) {
            object.getMode = $root.Request.GetMode.toObject(message.getMode, options);
            if (options.oneofs)
                object.msg = "getMode";
        }
        if (message.setMode != null && message.hasOwnProperty("setMode")) {
            object.setMode = $root.Request.SetMode.toObject(message.setMode, options);
            if (options.oneofs)
                object.msg = "setMode";
        }
        if (message.getPlaylist != null && message.hasOwnProperty("getPlaylist")) {
            object.getPlaylist = $root.Request.GetPlaylist.toObject(message.getPlaylist, options);
            if (options.oneofs)
                object.msg = "getPlaylist";
        }
        if (message.setPlaylist != null && message.hasOwnProperty("setPlaylist")) {
            object.setPlaylist = $root.Request.SetPlaylist.toObject(message.setPlaylist, options);
            if (options.oneofs)
                object.msg = "setPlaylist";
        }
        if (message.getPlaystate != null && message.hasOwnProperty("getPlaystate")) {
            object.getPlaystate = $root.Request.GetPlaystate.toObject(message.getPlaystate, options);
            if (options.oneofs)
                object.msg = "getPlaystate";
        }
        if (message.authenticate != null && message.hasOwnProperty("authenticate")) {
            object.authenticate = $root.Request.Authenticate.toObject(message.authenticate, options);
            if (options.oneofs)
                object.msg = "authenticate";
        }
        if (message.enterBootloader != null && message.hasOwnProperty("enterBootloader")) {
            object.enterBootloader = $root.Request.EnterBootloader.toObject(message.enterBootloader, options);
            if (options.oneofs)
                object.msg = "enterBootloader";
        }
        if (message.reboot != null && message.hasOwnProperty("reboot")) {
            object.reboot = $root.Request.Reboot.toObject(message.reboot, options);
            if (options.oneofs)
                object.msg = "reboot";
        }
        if (message.factoryReset != null && message.hasOwnProperty("factoryReset")) {
            object.factoryReset = $root.Request.FactoryReset.toObject(message.factoryReset, options);
            if (options.oneofs)
                object.msg = "factoryReset";
        }
        if (message.enableWatchdog != null && message.hasOwnProperty("enableWatchdog")) {
            object.enableWatchdog = $root.Request.EnableWatchdog.toObject(message.enableWatchdog, options);
            if (options.oneofs)
                object.msg = "enableWatchdog";
        }
        if (message.feedWatchdog != null && message.hasOwnProperty("feedWatchdog")) {
            object.feedWatchdog = $root.Request.FeedWatchdog.toObject(message.feedWatchdog, options);
            if (options.oneofs)
                object.msg = "feedWatchdog";
        }
        if (message.triggerFault != null && message.hasOwnProperty("triggerFault")) {
            object.triggerFault = $root.Request.TriggerFault.toObject(message.triggerFault, options);
            if (options.oneofs)
                object.msg = "triggerFault";
        }
        return object;
    };

    /**
     * Converts this Request to JSON.
     * @function toJSON
     * @memberof Request
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Request.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Request.GetVersion = (function() {

        /**
         * Properties of a GetVersion.
         * @memberof Request
         * @interface IGetVersion
         */

        /**
         * Constructs a new GetVersion.
         * @memberof Request
         * @classdesc Get the various version fields (firmware, signing, board, etc).
         * @implements IGetVersion
         * @constructor
         * @param {Request.IGetVersion=} [properties] Properties to set
         */
        function GetVersion(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetVersion instance using the specified properties.
         * @function create
         * @memberof Request.GetVersion
         * @static
         * @param {Request.IGetVersion=} [properties] Properties to set
         * @returns {Request.GetVersion} GetVersion instance
         */
        GetVersion.create = function create(properties) {
            return new GetVersion(properties);
        };

        /**
         * Encodes the specified GetVersion message. Does not implicitly {@link Request.GetVersion.verify|verify} messages.
         * @function encode
         * @memberof Request.GetVersion
         * @static
         * @param {Request.IGetVersion} message GetVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetVersion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetVersion message, length delimited. Does not implicitly {@link Request.GetVersion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.GetVersion
         * @static
         * @param {Request.IGetVersion} message GetVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetVersion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetVersion message from the specified reader or buffer.
         * @function decode
         * @memberof Request.GetVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.GetVersion} GetVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetVersion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.GetVersion();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetVersion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.GetVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.GetVersion} GetVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetVersion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetVersion message.
         * @function verify
         * @memberof Request.GetVersion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetVersion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetVersion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.GetVersion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.GetVersion} GetVersion
         */
        GetVersion.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.GetVersion)
                return object;
            return new $root.Request.GetVersion();
        };

        /**
         * Creates a plain object from a GetVersion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.GetVersion
         * @static
         * @param {Request.GetVersion} message GetVersion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetVersion.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetVersion to JSON.
         * @function toJSON
         * @memberof Request.GetVersion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetVersion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetVersion;
    })();

    Request.GetDeviceInfo = (function() {

        /**
         * Properties of a GetDeviceInfo.
         * @memberof Request
         * @interface IGetDeviceInfo
         */

        /**
         * Constructs a new GetDeviceInfo.
         * @memberof Request
         * @classdesc Get device-specific information such as MAC address and HW UUID.
         * @implements IGetDeviceInfo
         * @constructor
         * @param {Request.IGetDeviceInfo=} [properties] Properties to set
         */
        function GetDeviceInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetDeviceInfo instance using the specified properties.
         * @function create
         * @memberof Request.GetDeviceInfo
         * @static
         * @param {Request.IGetDeviceInfo=} [properties] Properties to set
         * @returns {Request.GetDeviceInfo} GetDeviceInfo instance
         */
        GetDeviceInfo.create = function create(properties) {
            return new GetDeviceInfo(properties);
        };

        /**
         * Encodes the specified GetDeviceInfo message. Does not implicitly {@link Request.GetDeviceInfo.verify|verify} messages.
         * @function encode
         * @memberof Request.GetDeviceInfo
         * @static
         * @param {Request.IGetDeviceInfo} message GetDeviceInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDeviceInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetDeviceInfo message, length delimited. Does not implicitly {@link Request.GetDeviceInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.GetDeviceInfo
         * @static
         * @param {Request.IGetDeviceInfo} message GetDeviceInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDeviceInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetDeviceInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Request.GetDeviceInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.GetDeviceInfo} GetDeviceInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDeviceInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.GetDeviceInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetDeviceInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.GetDeviceInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.GetDeviceInfo} GetDeviceInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDeviceInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetDeviceInfo message.
         * @function verify
         * @memberof Request.GetDeviceInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetDeviceInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetDeviceInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.GetDeviceInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.GetDeviceInfo} GetDeviceInfo
         */
        GetDeviceInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.GetDeviceInfo)
                return object;
            return new $root.Request.GetDeviceInfo();
        };

        /**
         * Creates a plain object from a GetDeviceInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.GetDeviceInfo
         * @static
         * @param {Request.GetDeviceInfo} message GetDeviceInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetDeviceInfo.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetDeviceInfo to JSON.
         * @function toJSON
         * @memberof Request.GetDeviceInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetDeviceInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetDeviceInfo;
    })();

    Request.GetTemperature = (function() {

        /**
         * Properties of a GetTemperature.
         * @memberof Request
         * @interface IGetTemperature
         */

        /**
         * Constructs a new GetTemperature.
         * @memberof Request
         * @classdesc Get device temperature.
         * @implements IGetTemperature
         * @constructor
         * @param {Request.IGetTemperature=} [properties] Properties to set
         */
        function GetTemperature(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetTemperature instance using the specified properties.
         * @function create
         * @memberof Request.GetTemperature
         * @static
         * @param {Request.IGetTemperature=} [properties] Properties to set
         * @returns {Request.GetTemperature} GetTemperature instance
         */
        GetTemperature.create = function create(properties) {
            return new GetTemperature(properties);
        };

        /**
         * Encodes the specified GetTemperature message. Does not implicitly {@link Request.GetTemperature.verify|verify} messages.
         * @function encode
         * @memberof Request.GetTemperature
         * @static
         * @param {Request.IGetTemperature} message GetTemperature message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTemperature.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetTemperature message, length delimited. Does not implicitly {@link Request.GetTemperature.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.GetTemperature
         * @static
         * @param {Request.IGetTemperature} message GetTemperature message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTemperature.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTemperature message from the specified reader or buffer.
         * @function decode
         * @memberof Request.GetTemperature
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.GetTemperature} GetTemperature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTemperature.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.GetTemperature();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTemperature message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.GetTemperature
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.GetTemperature} GetTemperature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTemperature.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTemperature message.
         * @function verify
         * @memberof Request.GetTemperature
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTemperature.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetTemperature message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.GetTemperature
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.GetTemperature} GetTemperature
         */
        GetTemperature.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.GetTemperature)
                return object;
            return new $root.Request.GetTemperature();
        };

        /**
         * Creates a plain object from a GetTemperature message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.GetTemperature
         * @static
         * @param {Request.GetTemperature} message GetTemperature
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTemperature.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetTemperature to JSON.
         * @function toJSON
         * @memberof Request.GetTemperature
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTemperature.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetTemperature;
    })();

    Request.GetNonce = (function() {

        /**
         * Properties of a GetNonce.
         * @memberof Request
         * @interface IGetNonce
         */

        /**
         * Constructs a new GetNonce.
         * @memberof Request
         * @classdesc Get nonce value. This is used when sending AuthenticatedRequest messages.
         * @implements IGetNonce
         * @constructor
         * @param {Request.IGetNonce=} [properties] Properties to set
         */
        function GetNonce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetNonce instance using the specified properties.
         * @function create
         * @memberof Request.GetNonce
         * @static
         * @param {Request.IGetNonce=} [properties] Properties to set
         * @returns {Request.GetNonce} GetNonce instance
         */
        GetNonce.create = function create(properties) {
            return new GetNonce(properties);
        };

        /**
         * Encodes the specified GetNonce message. Does not implicitly {@link Request.GetNonce.verify|verify} messages.
         * @function encode
         * @memberof Request.GetNonce
         * @static
         * @param {Request.IGetNonce} message GetNonce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetNonce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetNonce message, length delimited. Does not implicitly {@link Request.GetNonce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.GetNonce
         * @static
         * @param {Request.IGetNonce} message GetNonce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetNonce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetNonce message from the specified reader or buffer.
         * @function decode
         * @memberof Request.GetNonce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.GetNonce} GetNonce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetNonce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.GetNonce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetNonce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.GetNonce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.GetNonce} GetNonce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetNonce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetNonce message.
         * @function verify
         * @memberof Request.GetNonce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetNonce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetNonce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.GetNonce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.GetNonce} GetNonce
         */
        GetNonce.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.GetNonce)
                return object;
            return new $root.Request.GetNonce();
        };

        /**
         * Creates a plain object from a GetNonce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.GetNonce
         * @static
         * @param {Request.GetNonce} message GetNonce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetNonce.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetNonce to JSON.
         * @function toJSON
         * @memberof Request.GetNonce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetNonce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetNonce;
    })();

    Request.GetTime = (function() {

        /**
         * Properties of a GetTime.
         * @memberof Request
         * @interface IGetTime
         */

        /**
         * Constructs a new GetTime.
         * @memberof Request
         * @classdesc Get the current time of various clocks on the device (system time, uptime, etc).
         * @implements IGetTime
         * @constructor
         * @param {Request.IGetTime=} [properties] Properties to set
         */
        function GetTime(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetTime instance using the specified properties.
         * @function create
         * @memberof Request.GetTime
         * @static
         * @param {Request.IGetTime=} [properties] Properties to set
         * @returns {Request.GetTime} GetTime instance
         */
        GetTime.create = function create(properties) {
            return new GetTime(properties);
        };

        /**
         * Encodes the specified GetTime message. Does not implicitly {@link Request.GetTime.verify|verify} messages.
         * @function encode
         * @memberof Request.GetTime
         * @static
         * @param {Request.IGetTime} message GetTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTime.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetTime message, length delimited. Does not implicitly {@link Request.GetTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.GetTime
         * @static
         * @param {Request.IGetTime} message GetTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTime message from the specified reader or buffer.
         * @function decode
         * @memberof Request.GetTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.GetTime} GetTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTime.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.GetTime();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.GetTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.GetTime} GetTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTime message.
         * @function verify
         * @memberof Request.GetTime
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTime.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetTime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.GetTime
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.GetTime} GetTime
         */
        GetTime.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.GetTime)
                return object;
            return new $root.Request.GetTime();
        };

        /**
         * Creates a plain object from a GetTime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.GetTime
         * @static
         * @param {Request.GetTime} message GetTime
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTime.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetTime to JSON.
         * @function toJSON
         * @memberof Request.GetTime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetTime;
    })();

    Request.GetConfig = (function() {

        /**
         * Properties of a GetConfig.
         * @memberof Request
         * @interface IGetConfig
         */

        /**
         * Constructs a new GetConfig.
         * @memberof Request
         * @classdesc Get the current configuration.
         * @implements IGetConfig
         * @constructor
         * @param {Request.IGetConfig=} [properties] Properties to set
         */
        function GetConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetConfig instance using the specified properties.
         * @function create
         * @memberof Request.GetConfig
         * @static
         * @param {Request.IGetConfig=} [properties] Properties to set
         * @returns {Request.GetConfig} GetConfig instance
         */
        GetConfig.create = function create(properties) {
            return new GetConfig(properties);
        };

        /**
         * Encodes the specified GetConfig message. Does not implicitly {@link Request.GetConfig.verify|verify} messages.
         * @function encode
         * @memberof Request.GetConfig
         * @static
         * @param {Request.IGetConfig} message GetConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetConfig message, length delimited. Does not implicitly {@link Request.GetConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.GetConfig
         * @static
         * @param {Request.IGetConfig} message GetConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetConfig message from the specified reader or buffer.
         * @function decode
         * @memberof Request.GetConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.GetConfig} GetConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.GetConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.GetConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.GetConfig} GetConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetConfig message.
         * @function verify
         * @memberof Request.GetConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.GetConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.GetConfig} GetConfig
         */
        GetConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.GetConfig)
                return object;
            return new $root.Request.GetConfig();
        };

        /**
         * Creates a plain object from a GetConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.GetConfig
         * @static
         * @param {Request.GetConfig} message GetConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetConfig.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetConfig to JSON.
         * @function toJSON
         * @memberof Request.GetConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetConfig;
    })();

    Request.GetMode = (function() {

        /**
         * Properties of a GetMode.
         * @memberof Request
         * @interface IGetMode
         */

        /**
         * Constructs a new GetMode.
         * @memberof Request
         * @classdesc Get the current active application run mode.
         * @implements IGetMode
         * @constructor
         * @param {Request.IGetMode=} [properties] Properties to set
         */
        function GetMode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetMode instance using the specified properties.
         * @function create
         * @memberof Request.GetMode
         * @static
         * @param {Request.IGetMode=} [properties] Properties to set
         * @returns {Request.GetMode} GetMode instance
         */
        GetMode.create = function create(properties) {
            return new GetMode(properties);
        };

        /**
         * Encodes the specified GetMode message. Does not implicitly {@link Request.GetMode.verify|verify} messages.
         * @function encode
         * @memberof Request.GetMode
         * @static
         * @param {Request.IGetMode} message GetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetMode message, length delimited. Does not implicitly {@link Request.GetMode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.GetMode
         * @static
         * @param {Request.IGetMode} message GetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMode message from the specified reader or buffer.
         * @function decode
         * @memberof Request.GetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.GetMode} GetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.GetMode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.GetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.GetMode} GetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMode message.
         * @function verify
         * @memberof Request.GetMode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetMode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.GetMode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.GetMode} GetMode
         */
        GetMode.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.GetMode)
                return object;
            return new $root.Request.GetMode();
        };

        /**
         * Creates a plain object from a GetMode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.GetMode
         * @static
         * @param {Request.GetMode} message GetMode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMode.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetMode to JSON.
         * @function toJSON
         * @memberof Request.GetMode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetMode;
    })();

    Request.GetPlaylist = (function() {

        /**
         * Properties of a GetPlaylist.
         * @memberof Request
         * @interface IGetPlaylist
         */

        /**
         * Constructs a new GetPlaylist.
         * @memberof Request
         * @classdesc Get the current Catch playlist of playstates.
         * @implements IGetPlaylist
         * @constructor
         * @param {Request.IGetPlaylist=} [properties] Properties to set
         */
        function GetPlaylist(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetPlaylist instance using the specified properties.
         * @function create
         * @memberof Request.GetPlaylist
         * @static
         * @param {Request.IGetPlaylist=} [properties] Properties to set
         * @returns {Request.GetPlaylist} GetPlaylist instance
         */
        GetPlaylist.create = function create(properties) {
            return new GetPlaylist(properties);
        };

        /**
         * Encodes the specified GetPlaylist message. Does not implicitly {@link Request.GetPlaylist.verify|verify} messages.
         * @function encode
         * @memberof Request.GetPlaylist
         * @static
         * @param {Request.IGetPlaylist} message GetPlaylist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPlaylist.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetPlaylist message, length delimited. Does not implicitly {@link Request.GetPlaylist.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.GetPlaylist
         * @static
         * @param {Request.IGetPlaylist} message GetPlaylist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPlaylist.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetPlaylist message from the specified reader or buffer.
         * @function decode
         * @memberof Request.GetPlaylist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.GetPlaylist} GetPlaylist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPlaylist.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.GetPlaylist();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetPlaylist message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.GetPlaylist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.GetPlaylist} GetPlaylist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPlaylist.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetPlaylist message.
         * @function verify
         * @memberof Request.GetPlaylist
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetPlaylist.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetPlaylist message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.GetPlaylist
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.GetPlaylist} GetPlaylist
         */
        GetPlaylist.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.GetPlaylist)
                return object;
            return new $root.Request.GetPlaylist();
        };

        /**
         * Creates a plain object from a GetPlaylist message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.GetPlaylist
         * @static
         * @param {Request.GetPlaylist} message GetPlaylist
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetPlaylist.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetPlaylist to JSON.
         * @function toJSON
         * @memberof Request.GetPlaylist
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetPlaylist.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetPlaylist;
    })();

    Request.GetPlaystate = (function() {

        /**
         * Properties of a GetPlaystate.
         * @memberof Request
         * @interface IGetPlaystate
         */

        /**
         * Constructs a new GetPlaystate.
         * @memberof Request
         * @classdesc Get the currently active Catch playstate.
         * @implements IGetPlaystate
         * @constructor
         * @param {Request.IGetPlaystate=} [properties] Properties to set
         */
        function GetPlaystate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetPlaystate instance using the specified properties.
         * @function create
         * @memberof Request.GetPlaystate
         * @static
         * @param {Request.IGetPlaystate=} [properties] Properties to set
         * @returns {Request.GetPlaystate} GetPlaystate instance
         */
        GetPlaystate.create = function create(properties) {
            return new GetPlaystate(properties);
        };

        /**
         * Encodes the specified GetPlaystate message. Does not implicitly {@link Request.GetPlaystate.verify|verify} messages.
         * @function encode
         * @memberof Request.GetPlaystate
         * @static
         * @param {Request.IGetPlaystate} message GetPlaystate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPlaystate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetPlaystate message, length delimited. Does not implicitly {@link Request.GetPlaystate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.GetPlaystate
         * @static
         * @param {Request.IGetPlaystate} message GetPlaystate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPlaystate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetPlaystate message from the specified reader or buffer.
         * @function decode
         * @memberof Request.GetPlaystate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.GetPlaystate} GetPlaystate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPlaystate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.GetPlaystate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetPlaystate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.GetPlaystate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.GetPlaystate} GetPlaystate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPlaystate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetPlaystate message.
         * @function verify
         * @memberof Request.GetPlaystate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetPlaystate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetPlaystate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.GetPlaystate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.GetPlaystate} GetPlaystate
         */
        GetPlaystate.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.GetPlaystate)
                return object;
            return new $root.Request.GetPlaystate();
        };

        /**
         * Creates a plain object from a GetPlaystate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.GetPlaystate
         * @static
         * @param {Request.GetPlaystate} message GetPlaystate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetPlaystate.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetPlaystate to JSON.
         * @function toJSON
         * @memberof Request.GetPlaystate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetPlaystate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetPlaystate;
    })();

    Request.FactoryReset = (function() {

        /**
         * Properties of a FactoryReset.
         * @memberof Request
         * @interface IFactoryReset
         */

        /**
         * Constructs a new FactoryReset.
         * @memberof Request
         * @classdesc Reset device to factory default state (factory configuration values).
         * @implements IFactoryReset
         * @constructor
         * @param {Request.IFactoryReset=} [properties] Properties to set
         */
        function FactoryReset(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new FactoryReset instance using the specified properties.
         * @function create
         * @memberof Request.FactoryReset
         * @static
         * @param {Request.IFactoryReset=} [properties] Properties to set
         * @returns {Request.FactoryReset} FactoryReset instance
         */
        FactoryReset.create = function create(properties) {
            return new FactoryReset(properties);
        };

        /**
         * Encodes the specified FactoryReset message. Does not implicitly {@link Request.FactoryReset.verify|verify} messages.
         * @function encode
         * @memberof Request.FactoryReset
         * @static
         * @param {Request.IFactoryReset} message FactoryReset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FactoryReset.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified FactoryReset message, length delimited. Does not implicitly {@link Request.FactoryReset.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.FactoryReset
         * @static
         * @param {Request.IFactoryReset} message FactoryReset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FactoryReset.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FactoryReset message from the specified reader or buffer.
         * @function decode
         * @memberof Request.FactoryReset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.FactoryReset} FactoryReset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FactoryReset.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.FactoryReset();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FactoryReset message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.FactoryReset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.FactoryReset} FactoryReset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FactoryReset.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FactoryReset message.
         * @function verify
         * @memberof Request.FactoryReset
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FactoryReset.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a FactoryReset message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.FactoryReset
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.FactoryReset} FactoryReset
         */
        FactoryReset.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.FactoryReset)
                return object;
            return new $root.Request.FactoryReset();
        };

        /**
         * Creates a plain object from a FactoryReset message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.FactoryReset
         * @static
         * @param {Request.FactoryReset} message FactoryReset
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FactoryReset.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this FactoryReset to JSON.
         * @function toJSON
         * @memberof Request.FactoryReset
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FactoryReset.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FactoryReset;
    })();

    Request.FeedWatchdog = (function() {

        /**
         * Properties of a FeedWatchdog.
         * @memberof Request
         * @interface IFeedWatchdog
         */

        /**
         * Constructs a new FeedWatchdog.
         * @memberof Request
         * @classdesc Feed the control protocol watchdog
         * @implements IFeedWatchdog
         * @constructor
         * @param {Request.IFeedWatchdog=} [properties] Properties to set
         */
        function FeedWatchdog(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new FeedWatchdog instance using the specified properties.
         * @function create
         * @memberof Request.FeedWatchdog
         * @static
         * @param {Request.IFeedWatchdog=} [properties] Properties to set
         * @returns {Request.FeedWatchdog} FeedWatchdog instance
         */
        FeedWatchdog.create = function create(properties) {
            return new FeedWatchdog(properties);
        };

        /**
         * Encodes the specified FeedWatchdog message. Does not implicitly {@link Request.FeedWatchdog.verify|verify} messages.
         * @function encode
         * @memberof Request.FeedWatchdog
         * @static
         * @param {Request.IFeedWatchdog} message FeedWatchdog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeedWatchdog.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified FeedWatchdog message, length delimited. Does not implicitly {@link Request.FeedWatchdog.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.FeedWatchdog
         * @static
         * @param {Request.IFeedWatchdog} message FeedWatchdog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeedWatchdog.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FeedWatchdog message from the specified reader or buffer.
         * @function decode
         * @memberof Request.FeedWatchdog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.FeedWatchdog} FeedWatchdog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeedWatchdog.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.FeedWatchdog();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FeedWatchdog message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.FeedWatchdog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.FeedWatchdog} FeedWatchdog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeedWatchdog.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FeedWatchdog message.
         * @function verify
         * @memberof Request.FeedWatchdog
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FeedWatchdog.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a FeedWatchdog message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.FeedWatchdog
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.FeedWatchdog} FeedWatchdog
         */
        FeedWatchdog.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.FeedWatchdog)
                return object;
            return new $root.Request.FeedWatchdog();
        };

        /**
         * Creates a plain object from a FeedWatchdog message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.FeedWatchdog
         * @static
         * @param {Request.FeedWatchdog} message FeedWatchdog
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FeedWatchdog.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this FeedWatchdog to JSON.
         * @function toJSON
         * @memberof Request.FeedWatchdog
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FeedWatchdog.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FeedWatchdog;
    })();

    Request.Ping = (function() {

        /**
         * Properties of a Ping.
         * @memberof Request
         * @interface IPing
         * @property {number|null} [delayMs] Ping delayMs
         */

        /**
         * Constructs a new Ping.
         * @memberof Request
         * @classdesc Ping message used to test the serial connection and device's ability to process
         * and respond to control messages.
         * @implements IPing
         * @constructor
         * @param {Request.IPing=} [properties] Properties to set
         */
        function Ping(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Ping delayMs.
         * @member {number|null|undefined} delayMs
         * @memberof Request.Ping
         * @instance
         */
        Ping.prototype.delayMs = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Ping _delayMs.
         * @member {"delayMs"|undefined} _delayMs
         * @memberof Request.Ping
         * @instance
         */
        Object.defineProperty(Ping.prototype, "_delayMs", {
            get: $util.oneOfGetter($oneOfFields = ["delayMs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Ping instance using the specified properties.
         * @function create
         * @memberof Request.Ping
         * @static
         * @param {Request.IPing=} [properties] Properties to set
         * @returns {Request.Ping} Ping instance
         */
        Ping.create = function create(properties) {
            return new Ping(properties);
        };

        /**
         * Encodes the specified Ping message. Does not implicitly {@link Request.Ping.verify|verify} messages.
         * @function encode
         * @memberof Request.Ping
         * @static
         * @param {Request.IPing} message Ping message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ping.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.delayMs != null && Object.hasOwnProperty.call(message, "delayMs"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.delayMs);
            return writer;
        };

        /**
         * Encodes the specified Ping message, length delimited. Does not implicitly {@link Request.Ping.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.Ping
         * @static
         * @param {Request.IPing} message Ping message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ping.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Ping message from the specified reader or buffer.
         * @function decode
         * @memberof Request.Ping
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.Ping} Ping
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ping.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.Ping();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.delayMs = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Ping message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.Ping
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.Ping} Ping
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ping.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Ping message.
         * @function verify
         * @memberof Request.Ping
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Ping.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.delayMs != null && message.hasOwnProperty("delayMs")) {
                properties._delayMs = 1;
                if (!$util.isInteger(message.delayMs))
                    return "delayMs: integer expected";
            }
            return null;
        };

        /**
         * Creates a Ping message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.Ping
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.Ping} Ping
         */
        Ping.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.Ping)
                return object;
            let message = new $root.Request.Ping();
            if (object.delayMs != null)
                message.delayMs = object.delayMs >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a Ping message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.Ping
         * @static
         * @param {Request.Ping} message Ping
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ping.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.delayMs != null && message.hasOwnProperty("delayMs")) {
                object.delayMs = message.delayMs;
                if (options.oneofs)
                    object._delayMs = "delayMs";
            }
            return object;
        };

        /**
         * Converts this Ping to JSON.
         * @function toJSON
         * @memberof Request.Ping
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ping.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Ping;
    })();

    Request.SetTime = (function() {

        /**
         * Properties of a SetTime.
         * @memberof Request
         * @interface ISetTime
         * @property {number|Long|null} [systimeMs] SetTime systimeMs
         */

        /**
         * Constructs a new SetTime.
         * @memberof Request
         * @classdesc Set the device's system time clock.
         * @implements ISetTime
         * @constructor
         * @param {Request.ISetTime=} [properties] Properties to set
         */
        function SetTime(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetTime systimeMs.
         * @member {number|Long} systimeMs
         * @memberof Request.SetTime
         * @instance
         */
        SetTime.prototype.systimeMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new SetTime instance using the specified properties.
         * @function create
         * @memberof Request.SetTime
         * @static
         * @param {Request.ISetTime=} [properties] Properties to set
         * @returns {Request.SetTime} SetTime instance
         */
        SetTime.create = function create(properties) {
            return new SetTime(properties);
        };

        /**
         * Encodes the specified SetTime message. Does not implicitly {@link Request.SetTime.verify|verify} messages.
         * @function encode
         * @memberof Request.SetTime
         * @static
         * @param {Request.ISetTime} message SetTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetTime.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.systimeMs != null && Object.hasOwnProperty.call(message, "systimeMs"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.systimeMs);
            return writer;
        };

        /**
         * Encodes the specified SetTime message, length delimited. Does not implicitly {@link Request.SetTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.SetTime
         * @static
         * @param {Request.ISetTime} message SetTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetTime message from the specified reader or buffer.
         * @function decode
         * @memberof Request.SetTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.SetTime} SetTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetTime.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.SetTime();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.systimeMs = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.SetTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.SetTime} SetTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetTime message.
         * @function verify
         * @memberof Request.SetTime
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetTime.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.systimeMs != null && message.hasOwnProperty("systimeMs"))
                if (!$util.isInteger(message.systimeMs) && !(message.systimeMs && $util.isInteger(message.systimeMs.low) && $util.isInteger(message.systimeMs.high)))
                    return "systimeMs: integer|Long expected";
            return null;
        };

        /**
         * Creates a SetTime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.SetTime
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.SetTime} SetTime
         */
        SetTime.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.SetTime)
                return object;
            let message = new $root.Request.SetTime();
            if (object.systimeMs != null)
                if ($util.Long)
                    (message.systimeMs = $util.Long.fromValue(object.systimeMs)).unsigned = true;
                else if (typeof object.systimeMs === "string")
                    message.systimeMs = parseInt(object.systimeMs, 10);
                else if (typeof object.systimeMs === "number")
                    message.systimeMs = object.systimeMs;
                else if (typeof object.systimeMs === "object")
                    message.systimeMs = new $util.LongBits(object.systimeMs.low >>> 0, object.systimeMs.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a SetTime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.SetTime
         * @static
         * @param {Request.SetTime} message SetTime
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetTime.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.systimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.systimeMs = options.longs === String ? "0" : 0;
            if (message.systimeMs != null && message.hasOwnProperty("systimeMs"))
                if (typeof message.systimeMs === "number")
                    object.systimeMs = options.longs === String ? String(message.systimeMs) : message.systimeMs;
                else
                    object.systimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.systimeMs) : options.longs === Number ? new $util.LongBits(message.systimeMs.low >>> 0, message.systimeMs.high >>> 0).toNumber(true) : message.systimeMs;
            return object;
        };

        /**
         * Converts this SetTime to JSON.
         * @function toJSON
         * @memberof Request.SetTime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetTime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetTime;
    })();

    Request.SetConfig = (function() {

        /**
         * Properties of a SetConfig.
         * @memberof Request
         * @interface ISetConfig
         * @property {number|null} [deviceId] SetConfig deviceId
         * @property {number|null} [streamId] SetConfig streamId
         * @property {number|null} [locationId] SetConfig locationId
         * @property {Uint8Array|null} [privateSigningKey] SetConfig privateSigningKey
         * @property {Uint8Array|null} [publicControlKey] SetConfig publicControlKey
         * @property {number|null} [screenHeadingDeg] SetConfig screenHeadingDeg
         * @property {number|null} [bleTxPowerDbm] SetConfig bleTxPowerDbm
         * @property {number|null} [bleAdvIntervalCatchMs] SetConfig bleAdvIntervalCatchMs
         * @property {number|null} [bleAdvIntervalIbeaconMs] SetConfig bleAdvIntervalIbeaconMs
         * @property {boolean|null} [ibeaconEnable] SetConfig ibeaconEnable
         * @property {Uint8Array|null} [ibeaconUuid] SetConfig ibeaconUuid
         * @property {boolean|null} [extAntenna] SetConfig extAntenna
         */

        /**
         * Constructs a new SetConfig.
         * @memberof Request
         * @classdesc Set the application and device configuration.
         * @implements ISetConfig
         * @constructor
         * @param {Request.ISetConfig=} [properties] Properties to set
         */
        function SetConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetConfig deviceId.
         * @member {number|null|undefined} deviceId
         * @memberof Request.SetConfig
         * @instance
         */
        SetConfig.prototype.deviceId = null;

        /**
         * SetConfig streamId.
         * @member {number|null|undefined} streamId
         * @memberof Request.SetConfig
         * @instance
         */
        SetConfig.prototype.streamId = null;

        /**
         * SetConfig locationId.
         * @member {number|null|undefined} locationId
         * @memberof Request.SetConfig
         * @instance
         */
        SetConfig.prototype.locationId = null;

        /**
         * SetConfig privateSigningKey.
         * @member {Uint8Array|null|undefined} privateSigningKey
         * @memberof Request.SetConfig
         * @instance
         */
        SetConfig.prototype.privateSigningKey = null;

        /**
         * SetConfig publicControlKey.
         * @member {Uint8Array|null|undefined} publicControlKey
         * @memberof Request.SetConfig
         * @instance
         */
        SetConfig.prototype.publicControlKey = null;

        /**
         * SetConfig screenHeadingDeg.
         * @member {number|null|undefined} screenHeadingDeg
         * @memberof Request.SetConfig
         * @instance
         */
        SetConfig.prototype.screenHeadingDeg = null;

        /**
         * SetConfig bleTxPowerDbm.
         * @member {number|null|undefined} bleTxPowerDbm
         * @memberof Request.SetConfig
         * @instance
         */
        SetConfig.prototype.bleTxPowerDbm = null;

        /**
         * SetConfig bleAdvIntervalCatchMs.
         * @member {number|null|undefined} bleAdvIntervalCatchMs
         * @memberof Request.SetConfig
         * @instance
         */
        SetConfig.prototype.bleAdvIntervalCatchMs = null;

        /**
         * SetConfig bleAdvIntervalIbeaconMs.
         * @member {number|null|undefined} bleAdvIntervalIbeaconMs
         * @memberof Request.SetConfig
         * @instance
         */
        SetConfig.prototype.bleAdvIntervalIbeaconMs = null;

        /**
         * SetConfig ibeaconEnable.
         * @member {boolean|null|undefined} ibeaconEnable
         * @memberof Request.SetConfig
         * @instance
         */
        SetConfig.prototype.ibeaconEnable = null;

        /**
         * SetConfig ibeaconUuid.
         * @member {Uint8Array|null|undefined} ibeaconUuid
         * @memberof Request.SetConfig
         * @instance
         */
        SetConfig.prototype.ibeaconUuid = null;

        /**
         * SetConfig extAntenna.
         * @member {boolean|null|undefined} extAntenna
         * @memberof Request.SetConfig
         * @instance
         */
        SetConfig.prototype.extAntenna = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SetConfig _deviceId.
         * @member {"deviceId"|undefined} _deviceId
         * @memberof Request.SetConfig
         * @instance
         */
        Object.defineProperty(SetConfig.prototype, "_deviceId", {
            get: $util.oneOfGetter($oneOfFields = ["deviceId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SetConfig _streamId.
         * @member {"streamId"|undefined} _streamId
         * @memberof Request.SetConfig
         * @instance
         */
        Object.defineProperty(SetConfig.prototype, "_streamId", {
            get: $util.oneOfGetter($oneOfFields = ["streamId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SetConfig _locationId.
         * @member {"locationId"|undefined} _locationId
         * @memberof Request.SetConfig
         * @instance
         */
        Object.defineProperty(SetConfig.prototype, "_locationId", {
            get: $util.oneOfGetter($oneOfFields = ["locationId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SetConfig _privateSigningKey.
         * @member {"privateSigningKey"|undefined} _privateSigningKey
         * @memberof Request.SetConfig
         * @instance
         */
        Object.defineProperty(SetConfig.prototype, "_privateSigningKey", {
            get: $util.oneOfGetter($oneOfFields = ["privateSigningKey"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SetConfig _publicControlKey.
         * @member {"publicControlKey"|undefined} _publicControlKey
         * @memberof Request.SetConfig
         * @instance
         */
        Object.defineProperty(SetConfig.prototype, "_publicControlKey", {
            get: $util.oneOfGetter($oneOfFields = ["publicControlKey"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SetConfig _screenHeadingDeg.
         * @member {"screenHeadingDeg"|undefined} _screenHeadingDeg
         * @memberof Request.SetConfig
         * @instance
         */
        Object.defineProperty(SetConfig.prototype, "_screenHeadingDeg", {
            get: $util.oneOfGetter($oneOfFields = ["screenHeadingDeg"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SetConfig _bleTxPowerDbm.
         * @member {"bleTxPowerDbm"|undefined} _bleTxPowerDbm
         * @memberof Request.SetConfig
         * @instance
         */
        Object.defineProperty(SetConfig.prototype, "_bleTxPowerDbm", {
            get: $util.oneOfGetter($oneOfFields = ["bleTxPowerDbm"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SetConfig _bleAdvIntervalCatchMs.
         * @member {"bleAdvIntervalCatchMs"|undefined} _bleAdvIntervalCatchMs
         * @memberof Request.SetConfig
         * @instance
         */
        Object.defineProperty(SetConfig.prototype, "_bleAdvIntervalCatchMs", {
            get: $util.oneOfGetter($oneOfFields = ["bleAdvIntervalCatchMs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SetConfig _bleAdvIntervalIbeaconMs.
         * @member {"bleAdvIntervalIbeaconMs"|undefined} _bleAdvIntervalIbeaconMs
         * @memberof Request.SetConfig
         * @instance
         */
        Object.defineProperty(SetConfig.prototype, "_bleAdvIntervalIbeaconMs", {
            get: $util.oneOfGetter($oneOfFields = ["bleAdvIntervalIbeaconMs"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SetConfig _ibeaconEnable.
         * @member {"ibeaconEnable"|undefined} _ibeaconEnable
         * @memberof Request.SetConfig
         * @instance
         */
        Object.defineProperty(SetConfig.prototype, "_ibeaconEnable", {
            get: $util.oneOfGetter($oneOfFields = ["ibeaconEnable"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SetConfig _ibeaconUuid.
         * @member {"ibeaconUuid"|undefined} _ibeaconUuid
         * @memberof Request.SetConfig
         * @instance
         */
        Object.defineProperty(SetConfig.prototype, "_ibeaconUuid", {
            get: $util.oneOfGetter($oneOfFields = ["ibeaconUuid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SetConfig _extAntenna.
         * @member {"extAntenna"|undefined} _extAntenna
         * @memberof Request.SetConfig
         * @instance
         */
        Object.defineProperty(SetConfig.prototype, "_extAntenna", {
            get: $util.oneOfGetter($oneOfFields = ["extAntenna"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SetConfig instance using the specified properties.
         * @function create
         * @memberof Request.SetConfig
         * @static
         * @param {Request.ISetConfig=} [properties] Properties to set
         * @returns {Request.SetConfig} SetConfig instance
         */
        SetConfig.create = function create(properties) {
            return new SetConfig(properties);
        };

        /**
         * Encodes the specified SetConfig message. Does not implicitly {@link Request.SetConfig.verify|verify} messages.
         * @function encode
         * @memberof Request.SetConfig
         * @static
         * @param {Request.ISetConfig} message SetConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.deviceId);
            if (message.streamId != null && Object.hasOwnProperty.call(message, "streamId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.streamId);
            if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.locationId);
            if (message.privateSigningKey != null && Object.hasOwnProperty.call(message, "privateSigningKey"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.privateSigningKey);
            if (message.publicControlKey != null && Object.hasOwnProperty.call(message, "publicControlKey"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.publicControlKey);
            if (message.screenHeadingDeg != null && Object.hasOwnProperty.call(message, "screenHeadingDeg"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.screenHeadingDeg);
            if (message.bleTxPowerDbm != null && Object.hasOwnProperty.call(message, "bleTxPowerDbm"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.bleTxPowerDbm);
            if (message.bleAdvIntervalCatchMs != null && Object.hasOwnProperty.call(message, "bleAdvIntervalCatchMs"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.bleAdvIntervalCatchMs);
            if (message.bleAdvIntervalIbeaconMs != null && Object.hasOwnProperty.call(message, "bleAdvIntervalIbeaconMs"))
                writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.bleAdvIntervalIbeaconMs);
            if (message.ibeaconEnable != null && Object.hasOwnProperty.call(message, "ibeaconEnable"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.ibeaconEnable);
            if (message.ibeaconUuid != null && Object.hasOwnProperty.call(message, "ibeaconUuid"))
                writer.uint32(/* id 13, wireType 2 =*/106).bytes(message.ibeaconUuid);
            if (message.extAntenna != null && Object.hasOwnProperty.call(message, "extAntenna"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.extAntenna);
            return writer;
        };

        /**
         * Encodes the specified SetConfig message, length delimited. Does not implicitly {@link Request.SetConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.SetConfig
         * @static
         * @param {Request.ISetConfig} message SetConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetConfig message from the specified reader or buffer.
         * @function decode
         * @memberof Request.SetConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.SetConfig} SetConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.SetConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.deviceId = reader.uint32();
                    break;
                case 2:
                    message.streamId = reader.uint32();
                    break;
                case 3:
                    message.locationId = reader.uint32();
                    break;
                case 4:
                    message.privateSigningKey = reader.bytes();
                    break;
                case 5:
                    message.publicControlKey = reader.bytes();
                    break;
                case 6:
                    message.screenHeadingDeg = reader.uint32();
                    break;
                case 7:
                    message.bleTxPowerDbm = reader.int32();
                    break;
                case 10:
                    message.bleAdvIntervalCatchMs = reader.uint32();
                    break;
                case 11:
                    message.bleAdvIntervalIbeaconMs = reader.uint32();
                    break;
                case 12:
                    message.ibeaconEnable = reader.bool();
                    break;
                case 13:
                    message.ibeaconUuid = reader.bytes();
                    break;
                case 14:
                    message.extAntenna = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.SetConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.SetConfig} SetConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetConfig message.
         * @function verify
         * @memberof Request.SetConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.deviceId != null && message.hasOwnProperty("deviceId")) {
                properties._deviceId = 1;
                if (!$util.isInteger(message.deviceId))
                    return "deviceId: integer expected";
            }
            if (message.streamId != null && message.hasOwnProperty("streamId")) {
                properties._streamId = 1;
                if (!$util.isInteger(message.streamId))
                    return "streamId: integer expected";
            }
            if (message.locationId != null && message.hasOwnProperty("locationId")) {
                properties._locationId = 1;
                if (!$util.isInteger(message.locationId))
                    return "locationId: integer expected";
            }
            if (message.privateSigningKey != null && message.hasOwnProperty("privateSigningKey")) {
                properties._privateSigningKey = 1;
                if (!(message.privateSigningKey && typeof message.privateSigningKey.length === "number" || $util.isString(message.privateSigningKey)))
                    return "privateSigningKey: buffer expected";
            }
            if (message.publicControlKey != null && message.hasOwnProperty("publicControlKey")) {
                properties._publicControlKey = 1;
                if (!(message.publicControlKey && typeof message.publicControlKey.length === "number" || $util.isString(message.publicControlKey)))
                    return "publicControlKey: buffer expected";
            }
            if (message.screenHeadingDeg != null && message.hasOwnProperty("screenHeadingDeg")) {
                properties._screenHeadingDeg = 1;
                if (!$util.isInteger(message.screenHeadingDeg))
                    return "screenHeadingDeg: integer expected";
            }
            if (message.bleTxPowerDbm != null && message.hasOwnProperty("bleTxPowerDbm")) {
                properties._bleTxPowerDbm = 1;
                if (!$util.isInteger(message.bleTxPowerDbm))
                    return "bleTxPowerDbm: integer expected";
            }
            if (message.bleAdvIntervalCatchMs != null && message.hasOwnProperty("bleAdvIntervalCatchMs")) {
                properties._bleAdvIntervalCatchMs = 1;
                if (!$util.isInteger(message.bleAdvIntervalCatchMs))
                    return "bleAdvIntervalCatchMs: integer expected";
            }
            if (message.bleAdvIntervalIbeaconMs != null && message.hasOwnProperty("bleAdvIntervalIbeaconMs")) {
                properties._bleAdvIntervalIbeaconMs = 1;
                if (!$util.isInteger(message.bleAdvIntervalIbeaconMs))
                    return "bleAdvIntervalIbeaconMs: integer expected";
            }
            if (message.ibeaconEnable != null && message.hasOwnProperty("ibeaconEnable")) {
                properties._ibeaconEnable = 1;
                if (typeof message.ibeaconEnable !== "boolean")
                    return "ibeaconEnable: boolean expected";
            }
            if (message.ibeaconUuid != null && message.hasOwnProperty("ibeaconUuid")) {
                properties._ibeaconUuid = 1;
                if (!(message.ibeaconUuid && typeof message.ibeaconUuid.length === "number" || $util.isString(message.ibeaconUuid)))
                    return "ibeaconUuid: buffer expected";
            }
            if (message.extAntenna != null && message.hasOwnProperty("extAntenna")) {
                properties._extAntenna = 1;
                if (typeof message.extAntenna !== "boolean")
                    return "extAntenna: boolean expected";
            }
            return null;
        };

        /**
         * Creates a SetConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.SetConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.SetConfig} SetConfig
         */
        SetConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.SetConfig)
                return object;
            let message = new $root.Request.SetConfig();
            if (object.deviceId != null)
                message.deviceId = object.deviceId >>> 0;
            if (object.streamId != null)
                message.streamId = object.streamId >>> 0;
            if (object.locationId != null)
                message.locationId = object.locationId >>> 0;
            if (object.privateSigningKey != null)
                if (typeof object.privateSigningKey === "string")
                    $util.base64.decode(object.privateSigningKey, message.privateSigningKey = $util.newBuffer($util.base64.length(object.privateSigningKey)), 0);
                else if (object.privateSigningKey.length)
                    message.privateSigningKey = object.privateSigningKey;
            if (object.publicControlKey != null)
                if (typeof object.publicControlKey === "string")
                    $util.base64.decode(object.publicControlKey, message.publicControlKey = $util.newBuffer($util.base64.length(object.publicControlKey)), 0);
                else if (object.publicControlKey.length)
                    message.publicControlKey = object.publicControlKey;
            if (object.screenHeadingDeg != null)
                message.screenHeadingDeg = object.screenHeadingDeg >>> 0;
            if (object.bleTxPowerDbm != null)
                message.bleTxPowerDbm = object.bleTxPowerDbm | 0;
            if (object.bleAdvIntervalCatchMs != null)
                message.bleAdvIntervalCatchMs = object.bleAdvIntervalCatchMs >>> 0;
            if (object.bleAdvIntervalIbeaconMs != null)
                message.bleAdvIntervalIbeaconMs = object.bleAdvIntervalIbeaconMs >>> 0;
            if (object.ibeaconEnable != null)
                message.ibeaconEnable = Boolean(object.ibeaconEnable);
            if (object.ibeaconUuid != null)
                if (typeof object.ibeaconUuid === "string")
                    $util.base64.decode(object.ibeaconUuid, message.ibeaconUuid = $util.newBuffer($util.base64.length(object.ibeaconUuid)), 0);
                else if (object.ibeaconUuid.length)
                    message.ibeaconUuid = object.ibeaconUuid;
            if (object.extAntenna != null)
                message.extAntenna = Boolean(object.extAntenna);
            return message;
        };

        /**
         * Creates a plain object from a SetConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.SetConfig
         * @static
         * @param {Request.SetConfig} message SetConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.deviceId != null && message.hasOwnProperty("deviceId")) {
                object.deviceId = message.deviceId;
                if (options.oneofs)
                    object._deviceId = "deviceId";
            }
            if (message.streamId != null && message.hasOwnProperty("streamId")) {
                object.streamId = message.streamId;
                if (options.oneofs)
                    object._streamId = "streamId";
            }
            if (message.locationId != null && message.hasOwnProperty("locationId")) {
                object.locationId = message.locationId;
                if (options.oneofs)
                    object._locationId = "locationId";
            }
            if (message.privateSigningKey != null && message.hasOwnProperty("privateSigningKey")) {
                object.privateSigningKey = options.bytes === String ? $util.base64.encode(message.privateSigningKey, 0, message.privateSigningKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.privateSigningKey) : message.privateSigningKey;
                if (options.oneofs)
                    object._privateSigningKey = "privateSigningKey";
            }
            if (message.publicControlKey != null && message.hasOwnProperty("publicControlKey")) {
                object.publicControlKey = options.bytes === String ? $util.base64.encode(message.publicControlKey, 0, message.publicControlKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.publicControlKey) : message.publicControlKey;
                if (options.oneofs)
                    object._publicControlKey = "publicControlKey";
            }
            if (message.screenHeadingDeg != null && message.hasOwnProperty("screenHeadingDeg")) {
                object.screenHeadingDeg = message.screenHeadingDeg;
                if (options.oneofs)
                    object._screenHeadingDeg = "screenHeadingDeg";
            }
            if (message.bleTxPowerDbm != null && message.hasOwnProperty("bleTxPowerDbm")) {
                object.bleTxPowerDbm = message.bleTxPowerDbm;
                if (options.oneofs)
                    object._bleTxPowerDbm = "bleTxPowerDbm";
            }
            if (message.bleAdvIntervalCatchMs != null && message.hasOwnProperty("bleAdvIntervalCatchMs")) {
                object.bleAdvIntervalCatchMs = message.bleAdvIntervalCatchMs;
                if (options.oneofs)
                    object._bleAdvIntervalCatchMs = "bleAdvIntervalCatchMs";
            }
            if (message.bleAdvIntervalIbeaconMs != null && message.hasOwnProperty("bleAdvIntervalIbeaconMs")) {
                object.bleAdvIntervalIbeaconMs = message.bleAdvIntervalIbeaconMs;
                if (options.oneofs)
                    object._bleAdvIntervalIbeaconMs = "bleAdvIntervalIbeaconMs";
            }
            if (message.ibeaconEnable != null && message.hasOwnProperty("ibeaconEnable")) {
                object.ibeaconEnable = message.ibeaconEnable;
                if (options.oneofs)
                    object._ibeaconEnable = "ibeaconEnable";
            }
            if (message.ibeaconUuid != null && message.hasOwnProperty("ibeaconUuid")) {
                object.ibeaconUuid = options.bytes === String ? $util.base64.encode(message.ibeaconUuid, 0, message.ibeaconUuid.length) : options.bytes === Array ? Array.prototype.slice.call(message.ibeaconUuid) : message.ibeaconUuid;
                if (options.oneofs)
                    object._ibeaconUuid = "ibeaconUuid";
            }
            if (message.extAntenna != null && message.hasOwnProperty("extAntenna")) {
                object.extAntenna = message.extAntenna;
                if (options.oneofs)
                    object._extAntenna = "extAntenna";
            }
            return object;
        };

        /**
         * Converts this SetConfig to JSON.
         * @function toJSON
         * @memberof Request.SetConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetConfig;
    })();

    Request.SetMode = (function() {

        /**
         * Properties of a SetMode.
         * @memberof Request
         * @interface ISetMode
         * @property {Mode|null} [mode] SetMode mode
         */

        /**
         * Constructs a new SetMode.
         * @memberof Request
         * @classdesc Set application run mode.
         * @implements ISetMode
         * @constructor
         * @param {Request.ISetMode=} [properties] Properties to set
         */
        function SetMode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetMode mode.
         * @member {Mode} mode
         * @memberof Request.SetMode
         * @instance
         */
        SetMode.prototype.mode = 0;

        /**
         * Creates a new SetMode instance using the specified properties.
         * @function create
         * @memberof Request.SetMode
         * @static
         * @param {Request.ISetMode=} [properties] Properties to set
         * @returns {Request.SetMode} SetMode instance
         */
        SetMode.create = function create(properties) {
            return new SetMode(properties);
        };

        /**
         * Encodes the specified SetMode message. Does not implicitly {@link Request.SetMode.verify|verify} messages.
         * @function encode
         * @memberof Request.SetMode
         * @static
         * @param {Request.ISetMode} message SetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mode != null && Object.hasOwnProperty.call(message, "mode"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.mode);
            return writer;
        };

        /**
         * Encodes the specified SetMode message, length delimited. Does not implicitly {@link Request.SetMode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.SetMode
         * @static
         * @param {Request.ISetMode} message SetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMode message from the specified reader or buffer.
         * @function decode
         * @memberof Request.SetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.SetMode} SetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.SetMode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.mode = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetMode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.SetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.SetMode} SetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMode message.
         * @function verify
         * @memberof Request.SetMode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mode != null && message.hasOwnProperty("mode"))
                switch (message.mode) {
                default:
                    return "mode: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            return null;
        };

        /**
         * Creates a SetMode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.SetMode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.SetMode} SetMode
         */
        SetMode.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.SetMode)
                return object;
            let message = new $root.Request.SetMode();
            switch (object.mode) {
            case "MODE_OFF":
            case 0:
                message.mode = 0;
                break;
            case "MODE_IDLE":
            case 1:
                message.mode = 1;
                break;
            case "MODE_ACTIVE_SIGNING":
            case 2:
                message.mode = 2;
                break;
            case "MODE_ACTIVE_NO_SIGNING":
            case 3:
                message.mode = 3;
                break;
            case "MODE_PASSIVE_SIGNING":
            case 4:
                message.mode = 4;
                break;
            case "MODE_PASSIVE_NO_SIGNING":
            case 5:
                message.mode = 5;
                break;
            case "MODE_FACTORY_TEST":
            case 6:
                message.mode = 6;
                break;
            case "MODE_DEMO":
            case 7:
                message.mode = 7;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SetMode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.SetMode
         * @static
         * @param {Request.SetMode} message SetMode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.mode = options.enums === String ? "MODE_OFF" : 0;
            if (message.mode != null && message.hasOwnProperty("mode"))
                object.mode = options.enums === String ? $root.Mode[message.mode] : message.mode;
            return object;
        };

        /**
         * Converts this SetMode to JSON.
         * @function toJSON
         * @memberof Request.SetMode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetMode;
    })();

    Request.SetPlaylist = (function() {

        /**
         * Properties of a SetPlaylist.
         * @memberof Request
         * @interface ISetPlaylist
         * @property {Array.<IPlaystate>|null} [playstates] SetPlaylist playstates
         */

        /**
         * Constructs a new SetPlaylist.
         * @memberof Request
         * @classdesc Set Catch playlist of playstates.
         * NOTE: This command overwrites any existing playlist on the device.
         * @implements ISetPlaylist
         * @constructor
         * @param {Request.ISetPlaylist=} [properties] Properties to set
         */
        function SetPlaylist(properties) {
            this.playstates = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetPlaylist playstates.
         * @member {Array.<IPlaystate>} playstates
         * @memberof Request.SetPlaylist
         * @instance
         */
        SetPlaylist.prototype.playstates = $util.emptyArray;

        /**
         * Creates a new SetPlaylist instance using the specified properties.
         * @function create
         * @memberof Request.SetPlaylist
         * @static
         * @param {Request.ISetPlaylist=} [properties] Properties to set
         * @returns {Request.SetPlaylist} SetPlaylist instance
         */
        SetPlaylist.create = function create(properties) {
            return new SetPlaylist(properties);
        };

        /**
         * Encodes the specified SetPlaylist message. Does not implicitly {@link Request.SetPlaylist.verify|verify} messages.
         * @function encode
         * @memberof Request.SetPlaylist
         * @static
         * @param {Request.ISetPlaylist} message SetPlaylist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetPlaylist.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.playstates != null && message.playstates.length)
                for (let i = 0; i < message.playstates.length; ++i)
                    $root.Playstate.encode(message.playstates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SetPlaylist message, length delimited. Does not implicitly {@link Request.SetPlaylist.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.SetPlaylist
         * @static
         * @param {Request.ISetPlaylist} message SetPlaylist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetPlaylist.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetPlaylist message from the specified reader or buffer.
         * @function decode
         * @memberof Request.SetPlaylist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.SetPlaylist} SetPlaylist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetPlaylist.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.SetPlaylist();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.playstates && message.playstates.length))
                        message.playstates = [];
                    message.playstates.push($root.Playstate.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetPlaylist message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.SetPlaylist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.SetPlaylist} SetPlaylist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetPlaylist.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetPlaylist message.
         * @function verify
         * @memberof Request.SetPlaylist
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetPlaylist.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.playstates != null && message.hasOwnProperty("playstates")) {
                if (!Array.isArray(message.playstates))
                    return "playstates: array expected";
                for (let i = 0; i < message.playstates.length; ++i) {
                    let error = $root.Playstate.verify(message.playstates[i]);
                    if (error)
                        return "playstates." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SetPlaylist message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.SetPlaylist
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.SetPlaylist} SetPlaylist
         */
        SetPlaylist.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.SetPlaylist)
                return object;
            let message = new $root.Request.SetPlaylist();
            if (object.playstates) {
                if (!Array.isArray(object.playstates))
                    throw TypeError(".Request.SetPlaylist.playstates: array expected");
                message.playstates = [];
                for (let i = 0; i < object.playstates.length; ++i) {
                    if (typeof object.playstates[i] !== "object")
                        throw TypeError(".Request.SetPlaylist.playstates: object expected");
                    message.playstates[i] = $root.Playstate.fromObject(object.playstates[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SetPlaylist message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.SetPlaylist
         * @static
         * @param {Request.SetPlaylist} message SetPlaylist
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetPlaylist.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.playstates = [];
            if (message.playstates && message.playstates.length) {
                object.playstates = [];
                for (let j = 0; j < message.playstates.length; ++j)
                    object.playstates[j] = $root.Playstate.toObject(message.playstates[j], options);
            }
            return object;
        };

        /**
         * Converts this SetPlaylist to JSON.
         * @function toJSON
         * @memberof Request.SetPlaylist
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetPlaylist.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetPlaylist;
    })();

    Request.Authenticate = (function() {

        /**
         * Properties of an Authenticate.
         * @memberof Request
         * @interface IAuthenticate
         * @property {Uint8Array|null} [authKey] Authenticate authKey
         */

        /**
         * Constructs a new Authenticate.
         * @memberof Request
         * @classdesc @TODO This message is a placeholder to provide a way to possibly authenticate a connection session.
         * In future, the device may support this protocol over BLE, allowing configuring and controlling of the
         * the device remotely (over BLE). In this case, it would be useful for the client to be able to receive
         * several of the OOB messages for diagnostics (LogString, Metrics, etc).
         * However, there is no way (other than BLE Security Manager-level authentication) to authenticate the
         * connection to first permit the unprompted sharing of this data. This message may provide a simple
         * mechanism for this that is secure enough.
         * @implements IAuthenticate
         * @constructor
         * @param {Request.IAuthenticate=} [properties] Properties to set
         */
        function Authenticate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Authenticate authKey.
         * @member {Uint8Array} authKey
         * @memberof Request.Authenticate
         * @instance
         */
        Authenticate.prototype.authKey = $util.newBuffer([]);

        /**
         * Creates a new Authenticate instance using the specified properties.
         * @function create
         * @memberof Request.Authenticate
         * @static
         * @param {Request.IAuthenticate=} [properties] Properties to set
         * @returns {Request.Authenticate} Authenticate instance
         */
        Authenticate.create = function create(properties) {
            return new Authenticate(properties);
        };

        /**
         * Encodes the specified Authenticate message. Does not implicitly {@link Request.Authenticate.verify|verify} messages.
         * @function encode
         * @memberof Request.Authenticate
         * @static
         * @param {Request.IAuthenticate} message Authenticate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Authenticate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.authKey != null && Object.hasOwnProperty.call(message, "authKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.authKey);
            return writer;
        };

        /**
         * Encodes the specified Authenticate message, length delimited. Does not implicitly {@link Request.Authenticate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.Authenticate
         * @static
         * @param {Request.IAuthenticate} message Authenticate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Authenticate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Authenticate message from the specified reader or buffer.
         * @function decode
         * @memberof Request.Authenticate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.Authenticate} Authenticate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Authenticate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.Authenticate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.authKey = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Authenticate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.Authenticate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.Authenticate} Authenticate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Authenticate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Authenticate message.
         * @function verify
         * @memberof Request.Authenticate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Authenticate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.authKey != null && message.hasOwnProperty("authKey"))
                if (!(message.authKey && typeof message.authKey.length === "number" || $util.isString(message.authKey)))
                    return "authKey: buffer expected";
            return null;
        };

        /**
         * Creates an Authenticate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.Authenticate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.Authenticate} Authenticate
         */
        Authenticate.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.Authenticate)
                return object;
            let message = new $root.Request.Authenticate();
            if (object.authKey != null)
                if (typeof object.authKey === "string")
                    $util.base64.decode(object.authKey, message.authKey = $util.newBuffer($util.base64.length(object.authKey)), 0);
                else if (object.authKey.length)
                    message.authKey = object.authKey;
            return message;
        };

        /**
         * Creates a plain object from an Authenticate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.Authenticate
         * @static
         * @param {Request.Authenticate} message Authenticate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Authenticate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.authKey = "";
                else {
                    object.authKey = [];
                    if (options.bytes !== Array)
                        object.authKey = $util.newBuffer(object.authKey);
                }
            if (message.authKey != null && message.hasOwnProperty("authKey"))
                object.authKey = options.bytes === String ? $util.base64.encode(message.authKey, 0, message.authKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.authKey) : message.authKey;
            return object;
        };

        /**
         * Converts this Authenticate to JSON.
         * @function toJSON
         * @memberof Request.Authenticate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Authenticate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Authenticate;
    })();

    Request.EnterBootloader = (function() {

        /**
         * Properties of an EnterBootloader.
         * @memberof Request
         * @interface IEnterBootloader
         * @property {boolean|null} [urgent] EnterBootloader urgent
         */

        /**
         * Constructs a new EnterBootloader.
         * @memberof Request
         * @classdesc Put device into bootloader mode.
         * @implements IEnterBootloader
         * @constructor
         * @param {Request.IEnterBootloader=} [properties] Properties to set
         */
        function EnterBootloader(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnterBootloader urgent.
         * @member {boolean} urgent
         * @memberof Request.EnterBootloader
         * @instance
         */
        EnterBootloader.prototype.urgent = false;

        /**
         * Creates a new EnterBootloader instance using the specified properties.
         * @function create
         * @memberof Request.EnterBootloader
         * @static
         * @param {Request.IEnterBootloader=} [properties] Properties to set
         * @returns {Request.EnterBootloader} EnterBootloader instance
         */
        EnterBootloader.create = function create(properties) {
            return new EnterBootloader(properties);
        };

        /**
         * Encodes the specified EnterBootloader message. Does not implicitly {@link Request.EnterBootloader.verify|verify} messages.
         * @function encode
         * @memberof Request.EnterBootloader
         * @static
         * @param {Request.IEnterBootloader} message EnterBootloader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterBootloader.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.urgent != null && Object.hasOwnProperty.call(message, "urgent"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.urgent);
            return writer;
        };

        /**
         * Encodes the specified EnterBootloader message, length delimited. Does not implicitly {@link Request.EnterBootloader.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.EnterBootloader
         * @static
         * @param {Request.IEnterBootloader} message EnterBootloader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterBootloader.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnterBootloader message from the specified reader or buffer.
         * @function decode
         * @memberof Request.EnterBootloader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.EnterBootloader} EnterBootloader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterBootloader.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.EnterBootloader();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.urgent = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnterBootloader message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.EnterBootloader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.EnterBootloader} EnterBootloader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterBootloader.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnterBootloader message.
         * @function verify
         * @memberof Request.EnterBootloader
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnterBootloader.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.urgent != null && message.hasOwnProperty("urgent"))
                if (typeof message.urgent !== "boolean")
                    return "urgent: boolean expected";
            return null;
        };

        /**
         * Creates an EnterBootloader message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.EnterBootloader
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.EnterBootloader} EnterBootloader
         */
        EnterBootloader.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.EnterBootloader)
                return object;
            let message = new $root.Request.EnterBootloader();
            if (object.urgent != null)
                message.urgent = Boolean(object.urgent);
            return message;
        };

        /**
         * Creates a plain object from an EnterBootloader message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.EnterBootloader
         * @static
         * @param {Request.EnterBootloader} message EnterBootloader
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnterBootloader.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.urgent = false;
            if (message.urgent != null && message.hasOwnProperty("urgent"))
                object.urgent = message.urgent;
            return object;
        };

        /**
         * Converts this EnterBootloader to JSON.
         * @function toJSON
         * @memberof Request.EnterBootloader
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnterBootloader.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EnterBootloader;
    })();

    Request.Reboot = (function() {

        /**
         * Properties of a Reboot.
         * @memberof Request
         * @interface IReboot
         * @property {boolean|null} [urgent] Reboot urgent
         */

        /**
         * Constructs a new Reboot.
         * @memberof Request
         * @classdesc Reboot the device.
         * @implements IReboot
         * @constructor
         * @param {Request.IReboot=} [properties] Properties to set
         */
        function Reboot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Reboot urgent.
         * @member {boolean} urgent
         * @memberof Request.Reboot
         * @instance
         */
        Reboot.prototype.urgent = false;

        /**
         * Creates a new Reboot instance using the specified properties.
         * @function create
         * @memberof Request.Reboot
         * @static
         * @param {Request.IReboot=} [properties] Properties to set
         * @returns {Request.Reboot} Reboot instance
         */
        Reboot.create = function create(properties) {
            return new Reboot(properties);
        };

        /**
         * Encodes the specified Reboot message. Does not implicitly {@link Request.Reboot.verify|verify} messages.
         * @function encode
         * @memberof Request.Reboot
         * @static
         * @param {Request.IReboot} message Reboot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reboot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.urgent != null && Object.hasOwnProperty.call(message, "urgent"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.urgent);
            return writer;
        };

        /**
         * Encodes the specified Reboot message, length delimited. Does not implicitly {@link Request.Reboot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.Reboot
         * @static
         * @param {Request.IReboot} message Reboot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reboot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Reboot message from the specified reader or buffer.
         * @function decode
         * @memberof Request.Reboot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.Reboot} Reboot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reboot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.Reboot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.urgent = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Reboot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.Reboot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.Reboot} Reboot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reboot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Reboot message.
         * @function verify
         * @memberof Request.Reboot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Reboot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.urgent != null && message.hasOwnProperty("urgent"))
                if (typeof message.urgent !== "boolean")
                    return "urgent: boolean expected";
            return null;
        };

        /**
         * Creates a Reboot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.Reboot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.Reboot} Reboot
         */
        Reboot.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.Reboot)
                return object;
            let message = new $root.Request.Reboot();
            if (object.urgent != null)
                message.urgent = Boolean(object.urgent);
            return message;
        };

        /**
         * Creates a plain object from a Reboot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.Reboot
         * @static
         * @param {Request.Reboot} message Reboot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Reboot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.urgent = false;
            if (message.urgent != null && message.hasOwnProperty("urgent"))
                object.urgent = message.urgent;
            return object;
        };

        /**
         * Converts this Reboot to JSON.
         * @function toJSON
         * @memberof Request.Reboot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Reboot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Reboot;
    })();

    Request.EnableWatchdog = (function() {

        /**
         * Properties of an EnableWatchdog.
         * @memberof Request
         * @interface IEnableWatchdog
         * @property {number|null} [timeoutMs] EnableWatchdog timeoutMs
         */

        /**
         * Constructs a new EnableWatchdog.
         * @memberof Request
         * @classdesc Enable the control protocol watchdog
         * @implements IEnableWatchdog
         * @constructor
         * @param {Request.IEnableWatchdog=} [properties] Properties to set
         */
        function EnableWatchdog(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnableWatchdog timeoutMs.
         * @member {number} timeoutMs
         * @memberof Request.EnableWatchdog
         * @instance
         */
        EnableWatchdog.prototype.timeoutMs = 0;

        /**
         * Creates a new EnableWatchdog instance using the specified properties.
         * @function create
         * @memberof Request.EnableWatchdog
         * @static
         * @param {Request.IEnableWatchdog=} [properties] Properties to set
         * @returns {Request.EnableWatchdog} EnableWatchdog instance
         */
        EnableWatchdog.create = function create(properties) {
            return new EnableWatchdog(properties);
        };

        /**
         * Encodes the specified EnableWatchdog message. Does not implicitly {@link Request.EnableWatchdog.verify|verify} messages.
         * @function encode
         * @memberof Request.EnableWatchdog
         * @static
         * @param {Request.IEnableWatchdog} message EnableWatchdog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnableWatchdog.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timeoutMs != null && Object.hasOwnProperty.call(message, "timeoutMs"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.timeoutMs);
            return writer;
        };

        /**
         * Encodes the specified EnableWatchdog message, length delimited. Does not implicitly {@link Request.EnableWatchdog.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.EnableWatchdog
         * @static
         * @param {Request.IEnableWatchdog} message EnableWatchdog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnableWatchdog.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnableWatchdog message from the specified reader or buffer.
         * @function decode
         * @memberof Request.EnableWatchdog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.EnableWatchdog} EnableWatchdog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnableWatchdog.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.EnableWatchdog();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.timeoutMs = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnableWatchdog message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.EnableWatchdog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.EnableWatchdog} EnableWatchdog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnableWatchdog.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnableWatchdog message.
         * @function verify
         * @memberof Request.EnableWatchdog
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnableWatchdog.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timeoutMs != null && message.hasOwnProperty("timeoutMs"))
                if (!$util.isInteger(message.timeoutMs))
                    return "timeoutMs: integer expected";
            return null;
        };

        /**
         * Creates an EnableWatchdog message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.EnableWatchdog
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.EnableWatchdog} EnableWatchdog
         */
        EnableWatchdog.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.EnableWatchdog)
                return object;
            let message = new $root.Request.EnableWatchdog();
            if (object.timeoutMs != null)
                message.timeoutMs = object.timeoutMs >>> 0;
            return message;
        };

        /**
         * Creates a plain object from an EnableWatchdog message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.EnableWatchdog
         * @static
         * @param {Request.EnableWatchdog} message EnableWatchdog
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnableWatchdog.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.timeoutMs = 0;
            if (message.timeoutMs != null && message.hasOwnProperty("timeoutMs"))
                object.timeoutMs = message.timeoutMs;
            return object;
        };

        /**
         * Converts this EnableWatchdog to JSON.
         * @function toJSON
         * @memberof Request.EnableWatchdog
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnableWatchdog.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EnableWatchdog;
    })();

    Request.TriggerFault = (function() {

        /**
         * Properties of a TriggerFault.
         * @memberof Request
         * @interface ITriggerFault
         * @property {FaultType|null} [fault] TriggerFault fault
         */

        /**
         * Constructs a new TriggerFault.
         * @memberof Request
         * @classdesc Trigger a fault within the device for testing purposes.
         * @implements ITriggerFault
         * @constructor
         * @param {Request.ITriggerFault=} [properties] Properties to set
         */
        function TriggerFault(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TriggerFault fault.
         * @member {FaultType} fault
         * @memberof Request.TriggerFault
         * @instance
         */
        TriggerFault.prototype.fault = 0;

        /**
         * Creates a new TriggerFault instance using the specified properties.
         * @function create
         * @memberof Request.TriggerFault
         * @static
         * @param {Request.ITriggerFault=} [properties] Properties to set
         * @returns {Request.TriggerFault} TriggerFault instance
         */
        TriggerFault.create = function create(properties) {
            return new TriggerFault(properties);
        };

        /**
         * Encodes the specified TriggerFault message. Does not implicitly {@link Request.TriggerFault.verify|verify} messages.
         * @function encode
         * @memberof Request.TriggerFault
         * @static
         * @param {Request.ITriggerFault} message TriggerFault message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerFault.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fault != null && Object.hasOwnProperty.call(message, "fault"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.fault);
            return writer;
        };

        /**
         * Encodes the specified TriggerFault message, length delimited. Does not implicitly {@link Request.TriggerFault.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Request.TriggerFault
         * @static
         * @param {Request.ITriggerFault} message TriggerFault message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerFault.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TriggerFault message from the specified reader or buffer.
         * @function decode
         * @memberof Request.TriggerFault
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Request.TriggerFault} TriggerFault
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerFault.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Request.TriggerFault();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fault = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TriggerFault message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Request.TriggerFault
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Request.TriggerFault} TriggerFault
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerFault.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TriggerFault message.
         * @function verify
         * @memberof Request.TriggerFault
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TriggerFault.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fault != null && message.hasOwnProperty("fault"))
                switch (message.fault) {
                default:
                    return "fault: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            return null;
        };

        /**
         * Creates a TriggerFault message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Request.TriggerFault
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Request.TriggerFault} TriggerFault
         */
        TriggerFault.fromObject = function fromObject(object) {
            if (object instanceof $root.Request.TriggerFault)
                return object;
            let message = new $root.Request.TriggerFault();
            switch (object.fault) {
            case "FAULT_TYPE_ASSERT":
            case 0:
                message.fault = 0;
                break;
            case "FAULT_TYPE_WATCHDOG_TIMEOUT":
            case 1:
                message.fault = 1;
                break;
            case "FAULT_TYPE_STACK_OVERFLOW":
            case 2:
                message.fault = 2;
                break;
            case "FAULT_TYPE_BUFFER_OVERFLOW":
            case 3:
                message.fault = 3;
                break;
            case "FAULT_TYPE_NULL_POINTER":
            case 4:
                message.fault = 4;
                break;
            case "FAULT_TYPE_HF_INVALID_FP":
            case 5:
                message.fault = 5;
                break;
            case "FAULT_TYPE_HF_UNDEF_INSTR":
            case 6:
                message.fault = 6;
                break;
            case "FAULT_TYPE_HF_LDM_ALIGN":
            case 7:
                message.fault = 7;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a TriggerFault message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Request.TriggerFault
         * @static
         * @param {Request.TriggerFault} message TriggerFault
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TriggerFault.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fault = options.enums === String ? "FAULT_TYPE_ASSERT" : 0;
            if (message.fault != null && message.hasOwnProperty("fault"))
                object.fault = options.enums === String ? $root.FaultType[message.fault] : message.fault;
            return object;
        };

        /**
         * Converts this TriggerFault to JSON.
         * @function toJSON
         * @memberof Request.TriggerFault
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TriggerFault.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TriggerFault;
    })();

    return Request;
})();

export const Response = $root.Response = (() => {

    /**
     * Properties of a Response.
     * @exports IResponse
     * @interface IResponse
     * @property {number|null} [correlationId] Response correlationId
     * @property {number|null} [execTimeMs] Response execTimeMs
     * @property {StatusCode|null} [status] Response status
     * @property {Response.IPing|null} [ping] Response ping
     * @property {Response.IGetVersion|null} [getVersion] Response getVersion
     * @property {Response.IGetDeviceInfo|null} [getDeviceInfo] Response getDeviceInfo
     * @property {Response.IGetTemperature|null} [getTemperature] Response getTemperature
     * @property {Response.IGetNonce|null} [getNonce] Response getNonce
     * @property {Response.IGetTime|null} [getTime] Response getTime
     * @property {Response.ISetTime|null} [setTime] Response setTime
     * @property {Response.IGetConfig|null} [getConfig] Response getConfig
     * @property {Response.ISetConfig|null} [setConfig] Response setConfig
     * @property {Response.IGetMode|null} [getMode] Response getMode
     * @property {Response.ISetMode|null} [setMode] Response setMode
     * @property {Response.IGetPlaylist|null} [getPlaylist] Response getPlaylist
     * @property {Response.ISetPlaylist|null} [setPlaylist] Response setPlaylist
     * @property {Response.IGetPlaystate|null} [getPlaystate] Response getPlaystate
     * @property {Response.IAuthenticate|null} [authenticate] Response authenticate
     * @property {Response.IEnterBootloader|null} [enterBootloader] Response enterBootloader
     * @property {Response.IReboot|null} [reboot] Response reboot
     * @property {Response.IFactoryReset|null} [factoryReset] Response factoryReset
     * @property {Response.IEnableWatchdog|null} [enableWatchdog] Response enableWatchdog
     * @property {Response.IFeedWatchdog|null} [feedWatchdog] Response feedWatchdog
     * @property {Response.ITriggerFault|null} [triggerFault] Response triggerFault
     */

    /**
     * Constructs a new Response.
     * @exports Response
     * @classdesc Response messages sent by the device in response to Request messages.
     * NOTE: For documentation of each of the fields and messages within, refer
     * to the documentation included in the equivalent Request messages.
     * @implements IResponse
     * @constructor
     * @param {IResponse=} [properties] Properties to set
     */
    function Response(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Response correlationId.
     * @member {number} correlationId
     * @memberof Response
     * @instance
     */
    Response.prototype.correlationId = 0;

    /**
     * Response execTimeMs.
     * @member {number} execTimeMs
     * @memberof Response
     * @instance
     */
    Response.prototype.execTimeMs = 0;

    /**
     * Response status.
     * @member {StatusCode} status
     * @memberof Response
     * @instance
     */
    Response.prototype.status = 0;

    /**
     * Response ping.
     * @member {Response.IPing|null|undefined} ping
     * @memberof Response
     * @instance
     */
    Response.prototype.ping = null;

    /**
     * Response getVersion.
     * @member {Response.IGetVersion|null|undefined} getVersion
     * @memberof Response
     * @instance
     */
    Response.prototype.getVersion = null;

    /**
     * Response getDeviceInfo.
     * @member {Response.IGetDeviceInfo|null|undefined} getDeviceInfo
     * @memberof Response
     * @instance
     */
    Response.prototype.getDeviceInfo = null;

    /**
     * Response getTemperature.
     * @member {Response.IGetTemperature|null|undefined} getTemperature
     * @memberof Response
     * @instance
     */
    Response.prototype.getTemperature = null;

    /**
     * Response getNonce.
     * @member {Response.IGetNonce|null|undefined} getNonce
     * @memberof Response
     * @instance
     */
    Response.prototype.getNonce = null;

    /**
     * Response getTime.
     * @member {Response.IGetTime|null|undefined} getTime
     * @memberof Response
     * @instance
     */
    Response.prototype.getTime = null;

    /**
     * Response setTime.
     * @member {Response.ISetTime|null|undefined} setTime
     * @memberof Response
     * @instance
     */
    Response.prototype.setTime = null;

    /**
     * Response getConfig.
     * @member {Response.IGetConfig|null|undefined} getConfig
     * @memberof Response
     * @instance
     */
    Response.prototype.getConfig = null;

    /**
     * Response setConfig.
     * @member {Response.ISetConfig|null|undefined} setConfig
     * @memberof Response
     * @instance
     */
    Response.prototype.setConfig = null;

    /**
     * Response getMode.
     * @member {Response.IGetMode|null|undefined} getMode
     * @memberof Response
     * @instance
     */
    Response.prototype.getMode = null;

    /**
     * Response setMode.
     * @member {Response.ISetMode|null|undefined} setMode
     * @memberof Response
     * @instance
     */
    Response.prototype.setMode = null;

    /**
     * Response getPlaylist.
     * @member {Response.IGetPlaylist|null|undefined} getPlaylist
     * @memberof Response
     * @instance
     */
    Response.prototype.getPlaylist = null;

    /**
     * Response setPlaylist.
     * @member {Response.ISetPlaylist|null|undefined} setPlaylist
     * @memberof Response
     * @instance
     */
    Response.prototype.setPlaylist = null;

    /**
     * Response getPlaystate.
     * @member {Response.IGetPlaystate|null|undefined} getPlaystate
     * @memberof Response
     * @instance
     */
    Response.prototype.getPlaystate = null;

    /**
     * Response authenticate.
     * @member {Response.IAuthenticate|null|undefined} authenticate
     * @memberof Response
     * @instance
     */
    Response.prototype.authenticate = null;

    /**
     * Response enterBootloader.
     * @member {Response.IEnterBootloader|null|undefined} enterBootloader
     * @memberof Response
     * @instance
     */
    Response.prototype.enterBootloader = null;

    /**
     * Response reboot.
     * @member {Response.IReboot|null|undefined} reboot
     * @memberof Response
     * @instance
     */
    Response.prototype.reboot = null;

    /**
     * Response factoryReset.
     * @member {Response.IFactoryReset|null|undefined} factoryReset
     * @memberof Response
     * @instance
     */
    Response.prototype.factoryReset = null;

    /**
     * Response enableWatchdog.
     * @member {Response.IEnableWatchdog|null|undefined} enableWatchdog
     * @memberof Response
     * @instance
     */
    Response.prototype.enableWatchdog = null;

    /**
     * Response feedWatchdog.
     * @member {Response.IFeedWatchdog|null|undefined} feedWatchdog
     * @memberof Response
     * @instance
     */
    Response.prototype.feedWatchdog = null;

    /**
     * Response triggerFault.
     * @member {Response.ITriggerFault|null|undefined} triggerFault
     * @memberof Response
     * @instance
     */
    Response.prototype.triggerFault = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * Response msg.
     * @member {"ping"|"getVersion"|"getDeviceInfo"|"getTemperature"|"getNonce"|"getTime"|"setTime"|"getConfig"|"setConfig"|"getMode"|"setMode"|"getPlaylist"|"setPlaylist"|"getPlaystate"|"authenticate"|"enterBootloader"|"reboot"|"factoryReset"|"enableWatchdog"|"feedWatchdog"|"triggerFault"|undefined} msg
     * @memberof Response
     * @instance
     */
    Object.defineProperty(Response.prototype, "msg", {
        get: $util.oneOfGetter($oneOfFields = ["ping", "getVersion", "getDeviceInfo", "getTemperature", "getNonce", "getTime", "setTime", "getConfig", "setConfig", "getMode", "setMode", "getPlaylist", "setPlaylist", "getPlaystate", "authenticate", "enterBootloader", "reboot", "factoryReset", "enableWatchdog", "feedWatchdog", "triggerFault"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Response instance using the specified properties.
     * @function create
     * @memberof Response
     * @static
     * @param {IResponse=} [properties] Properties to set
     * @returns {Response} Response instance
     */
    Response.create = function create(properties) {
        return new Response(properties);
    };

    /**
     * Encodes the specified Response message. Does not implicitly {@link Response.verify|verify} messages.
     * @function encode
     * @memberof Response
     * @static
     * @param {IResponse} message Response message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Response.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.correlationId != null && Object.hasOwnProperty.call(message, "correlationId"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.correlationId);
        if (message.execTimeMs != null && Object.hasOwnProperty.call(message, "execTimeMs"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.execTimeMs);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
        if (message.ping != null && Object.hasOwnProperty.call(message, "ping"))
            $root.Response.Ping.encode(message.ping, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.getVersion != null && Object.hasOwnProperty.call(message, "getVersion"))
            $root.Response.GetVersion.encode(message.getVersion, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.getDeviceInfo != null && Object.hasOwnProperty.call(message, "getDeviceInfo"))
            $root.Response.GetDeviceInfo.encode(message.getDeviceInfo, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.getTemperature != null && Object.hasOwnProperty.call(message, "getTemperature"))
            $root.Response.GetTemperature.encode(message.getTemperature, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.getNonce != null && Object.hasOwnProperty.call(message, "getNonce"))
            $root.Response.GetNonce.encode(message.getNonce, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.getTime != null && Object.hasOwnProperty.call(message, "getTime"))
            $root.Response.GetTime.encode(message.getTime, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.setTime != null && Object.hasOwnProperty.call(message, "setTime"))
            $root.Response.SetTime.encode(message.setTime, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.getConfig != null && Object.hasOwnProperty.call(message, "getConfig"))
            $root.Response.GetConfig.encode(message.getConfig, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.setConfig != null && Object.hasOwnProperty.call(message, "setConfig"))
            $root.Response.SetConfig.encode(message.setConfig, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.getMode != null && Object.hasOwnProperty.call(message, "getMode"))
            $root.Response.GetMode.encode(message.getMode, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.setMode != null && Object.hasOwnProperty.call(message, "setMode"))
            $root.Response.SetMode.encode(message.setMode, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.getPlaylist != null && Object.hasOwnProperty.call(message, "getPlaylist"))
            $root.Response.GetPlaylist.encode(message.getPlaylist, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.setPlaylist != null && Object.hasOwnProperty.call(message, "setPlaylist"))
            $root.Response.SetPlaylist.encode(message.setPlaylist, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
        if (message.getPlaystate != null && Object.hasOwnProperty.call(message, "getPlaystate"))
            $root.Response.GetPlaystate.encode(message.getPlaystate, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
        if (message.authenticate != null && Object.hasOwnProperty.call(message, "authenticate"))
            $root.Response.Authenticate.encode(message.authenticate, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
        if (message.enterBootloader != null && Object.hasOwnProperty.call(message, "enterBootloader"))
            $root.Response.EnterBootloader.encode(message.enterBootloader, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        if (message.reboot != null && Object.hasOwnProperty.call(message, "reboot"))
            $root.Response.Reboot.encode(message.reboot, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        if (message.factoryReset != null && Object.hasOwnProperty.call(message, "factoryReset"))
            $root.Response.FactoryReset.encode(message.factoryReset, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
        if (message.enableWatchdog != null && Object.hasOwnProperty.call(message, "enableWatchdog"))
            $root.Response.EnableWatchdog.encode(message.enableWatchdog, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
        if (message.feedWatchdog != null && Object.hasOwnProperty.call(message, "feedWatchdog"))
            $root.Response.FeedWatchdog.encode(message.feedWatchdog, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
        if (message.triggerFault != null && Object.hasOwnProperty.call(message, "triggerFault"))
            $root.Response.TriggerFault.encode(message.triggerFault, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Response message, length delimited. Does not implicitly {@link Response.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Response
     * @static
     * @param {IResponse} message Response message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Response.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Response message from the specified reader or buffer.
     * @function decode
     * @memberof Response
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Response} Response
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Response.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.correlationId = reader.uint32();
                break;
            case 2:
                message.execTimeMs = reader.uint32();
                break;
            case 3:
                message.status = reader.int32();
                break;
            case 5:
                message.ping = $root.Response.Ping.decode(reader, reader.uint32());
                break;
            case 6:
                message.getVersion = $root.Response.GetVersion.decode(reader, reader.uint32());
                break;
            case 7:
                message.getDeviceInfo = $root.Response.GetDeviceInfo.decode(reader, reader.uint32());
                break;
            case 8:
                message.getTemperature = $root.Response.GetTemperature.decode(reader, reader.uint32());
                break;
            case 9:
                message.getNonce = $root.Response.GetNonce.decode(reader, reader.uint32());
                break;
            case 10:
                message.getTime = $root.Response.GetTime.decode(reader, reader.uint32());
                break;
            case 11:
                message.setTime = $root.Response.SetTime.decode(reader, reader.uint32());
                break;
            case 12:
                message.getConfig = $root.Response.GetConfig.decode(reader, reader.uint32());
                break;
            case 13:
                message.setConfig = $root.Response.SetConfig.decode(reader, reader.uint32());
                break;
            case 14:
                message.getMode = $root.Response.GetMode.decode(reader, reader.uint32());
                break;
            case 15:
                message.setMode = $root.Response.SetMode.decode(reader, reader.uint32());
                break;
            case 16:
                message.getPlaylist = $root.Response.GetPlaylist.decode(reader, reader.uint32());
                break;
            case 17:
                message.setPlaylist = $root.Response.SetPlaylist.decode(reader, reader.uint32());
                break;
            case 18:
                message.getPlaystate = $root.Response.GetPlaystate.decode(reader, reader.uint32());
                break;
            case 19:
                message.authenticate = $root.Response.Authenticate.decode(reader, reader.uint32());
                break;
            case 20:
                message.enterBootloader = $root.Response.EnterBootloader.decode(reader, reader.uint32());
                break;
            case 21:
                message.reboot = $root.Response.Reboot.decode(reader, reader.uint32());
                break;
            case 22:
                message.factoryReset = $root.Response.FactoryReset.decode(reader, reader.uint32());
                break;
            case 23:
                message.enableWatchdog = $root.Response.EnableWatchdog.decode(reader, reader.uint32());
                break;
            case 24:
                message.feedWatchdog = $root.Response.FeedWatchdog.decode(reader, reader.uint32());
                break;
            case 25:
                message.triggerFault = $root.Response.TriggerFault.decode(reader, reader.uint32());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Response message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Response
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Response} Response
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Response.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Response message.
     * @function verify
     * @memberof Response
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Response.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.correlationId != null && message.hasOwnProperty("correlationId"))
            if (!$util.isInteger(message.correlationId))
                return "correlationId: integer expected";
        if (message.execTimeMs != null && message.hasOwnProperty("execTimeMs"))
            if (!$util.isInteger(message.execTimeMs))
                return "execTimeMs: integer expected";
        if (message.status != null && message.hasOwnProperty("status"))
            switch (message.status) {
            default:
                return "status: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
                break;
            }
        if (message.ping != null && message.hasOwnProperty("ping")) {
            properties.msg = 1;
            {
                let error = $root.Response.Ping.verify(message.ping);
                if (error)
                    return "ping." + error;
            }
        }
        if (message.getVersion != null && message.hasOwnProperty("getVersion")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.GetVersion.verify(message.getVersion);
                if (error)
                    return "getVersion." + error;
            }
        }
        if (message.getDeviceInfo != null && message.hasOwnProperty("getDeviceInfo")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.GetDeviceInfo.verify(message.getDeviceInfo);
                if (error)
                    return "getDeviceInfo." + error;
            }
        }
        if (message.getTemperature != null && message.hasOwnProperty("getTemperature")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.GetTemperature.verify(message.getTemperature);
                if (error)
                    return "getTemperature." + error;
            }
        }
        if (message.getNonce != null && message.hasOwnProperty("getNonce")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.GetNonce.verify(message.getNonce);
                if (error)
                    return "getNonce." + error;
            }
        }
        if (message.getTime != null && message.hasOwnProperty("getTime")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.GetTime.verify(message.getTime);
                if (error)
                    return "getTime." + error;
            }
        }
        if (message.setTime != null && message.hasOwnProperty("setTime")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.SetTime.verify(message.setTime);
                if (error)
                    return "setTime." + error;
            }
        }
        if (message.getConfig != null && message.hasOwnProperty("getConfig")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.GetConfig.verify(message.getConfig);
                if (error)
                    return "getConfig." + error;
            }
        }
        if (message.setConfig != null && message.hasOwnProperty("setConfig")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.SetConfig.verify(message.setConfig);
                if (error)
                    return "setConfig." + error;
            }
        }
        if (message.getMode != null && message.hasOwnProperty("getMode")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.GetMode.verify(message.getMode);
                if (error)
                    return "getMode." + error;
            }
        }
        if (message.setMode != null && message.hasOwnProperty("setMode")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.SetMode.verify(message.setMode);
                if (error)
                    return "setMode." + error;
            }
        }
        if (message.getPlaylist != null && message.hasOwnProperty("getPlaylist")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.GetPlaylist.verify(message.getPlaylist);
                if (error)
                    return "getPlaylist." + error;
            }
        }
        if (message.setPlaylist != null && message.hasOwnProperty("setPlaylist")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.SetPlaylist.verify(message.setPlaylist);
                if (error)
                    return "setPlaylist." + error;
            }
        }
        if (message.getPlaystate != null && message.hasOwnProperty("getPlaystate")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.GetPlaystate.verify(message.getPlaystate);
                if (error)
                    return "getPlaystate." + error;
            }
        }
        if (message.authenticate != null && message.hasOwnProperty("authenticate")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.Authenticate.verify(message.authenticate);
                if (error)
                    return "authenticate." + error;
            }
        }
        if (message.enterBootloader != null && message.hasOwnProperty("enterBootloader")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.EnterBootloader.verify(message.enterBootloader);
                if (error)
                    return "enterBootloader." + error;
            }
        }
        if (message.reboot != null && message.hasOwnProperty("reboot")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.Reboot.verify(message.reboot);
                if (error)
                    return "reboot." + error;
            }
        }
        if (message.factoryReset != null && message.hasOwnProperty("factoryReset")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.FactoryReset.verify(message.factoryReset);
                if (error)
                    return "factoryReset." + error;
            }
        }
        if (message.enableWatchdog != null && message.hasOwnProperty("enableWatchdog")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.EnableWatchdog.verify(message.enableWatchdog);
                if (error)
                    return "enableWatchdog." + error;
            }
        }
        if (message.feedWatchdog != null && message.hasOwnProperty("feedWatchdog")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.FeedWatchdog.verify(message.feedWatchdog);
                if (error)
                    return "feedWatchdog." + error;
            }
        }
        if (message.triggerFault != null && message.hasOwnProperty("triggerFault")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.Response.TriggerFault.verify(message.triggerFault);
                if (error)
                    return "triggerFault." + error;
            }
        }
        return null;
    };

    /**
     * Creates a Response message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Response
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Response} Response
     */
    Response.fromObject = function fromObject(object) {
        if (object instanceof $root.Response)
            return object;
        let message = new $root.Response();
        if (object.correlationId != null)
            message.correlationId = object.correlationId >>> 0;
        if (object.execTimeMs != null)
            message.execTimeMs = object.execTimeMs >>> 0;
        switch (object.status) {
        case "STATUS_SUCCESS":
        case 0:
            message.status = 0;
            break;
        case "STATUS_ERROR_NO_MEM":
        case 1:
            message.status = 1;
            break;
        case "STATUS_ERROR_NOT_FOUND":
        case 2:
            message.status = 2;
            break;
        case "STATUS_ERROR_NOT_SUPPORTED":
        case 3:
            message.status = 3;
            break;
        case "STATUS_ERROR_NOT_AUTHORIZED":
        case 4:
            message.status = 4;
            break;
        case "STATUS_ERROR_INVALID_PARAM":
        case 5:
            message.status = 5;
            break;
        case "STATUS_ERROR_INVALID_STATE":
        case 6:
            message.status = 6;
            break;
        case "STATUS_ERROR_INVALID_LENGTH":
        case 7:
            message.status = 7;
            break;
        case "STATUS_ERROR_INVALID_DATA":
        case 8:
            message.status = 8;
            break;
        case "STATUS_ERROR_TIMEOUT":
        case 9:
            message.status = 9;
            break;
        case "STATUS_ERROR_BUSY":
        case 10:
            message.status = 10;
            break;
        case "STATUS_ERROR_IO":
        case 11:
            message.status = 11;
            break;
        case "STATUS_ERROR_INTERNAL":
        case 12:
            message.status = 12;
            break;
        case "STATUS_ERROR_LINK":
        case 13:
            message.status = 13;
            break;
        case "STATUS_ERROR_FRAMING":
        case 14:
            message.status = 14;
            break;
        case "STATUS_ERROR_DECODING":
        case 15:
            message.status = 15;
            break;
        }
        if (object.ping != null) {
            if (typeof object.ping !== "object")
                throw TypeError(".Response.ping: object expected");
            message.ping = $root.Response.Ping.fromObject(object.ping);
        }
        if (object.getVersion != null) {
            if (typeof object.getVersion !== "object")
                throw TypeError(".Response.getVersion: object expected");
            message.getVersion = $root.Response.GetVersion.fromObject(object.getVersion);
        }
        if (object.getDeviceInfo != null) {
            if (typeof object.getDeviceInfo !== "object")
                throw TypeError(".Response.getDeviceInfo: object expected");
            message.getDeviceInfo = $root.Response.GetDeviceInfo.fromObject(object.getDeviceInfo);
        }
        if (object.getTemperature != null) {
            if (typeof object.getTemperature !== "object")
                throw TypeError(".Response.getTemperature: object expected");
            message.getTemperature = $root.Response.GetTemperature.fromObject(object.getTemperature);
        }
        if (object.getNonce != null) {
            if (typeof object.getNonce !== "object")
                throw TypeError(".Response.getNonce: object expected");
            message.getNonce = $root.Response.GetNonce.fromObject(object.getNonce);
        }
        if (object.getTime != null) {
            if (typeof object.getTime !== "object")
                throw TypeError(".Response.getTime: object expected");
            message.getTime = $root.Response.GetTime.fromObject(object.getTime);
        }
        if (object.setTime != null) {
            if (typeof object.setTime !== "object")
                throw TypeError(".Response.setTime: object expected");
            message.setTime = $root.Response.SetTime.fromObject(object.setTime);
        }
        if (object.getConfig != null) {
            if (typeof object.getConfig !== "object")
                throw TypeError(".Response.getConfig: object expected");
            message.getConfig = $root.Response.GetConfig.fromObject(object.getConfig);
        }
        if (object.setConfig != null) {
            if (typeof object.setConfig !== "object")
                throw TypeError(".Response.setConfig: object expected");
            message.setConfig = $root.Response.SetConfig.fromObject(object.setConfig);
        }
        if (object.getMode != null) {
            if (typeof object.getMode !== "object")
                throw TypeError(".Response.getMode: object expected");
            message.getMode = $root.Response.GetMode.fromObject(object.getMode);
        }
        if (object.setMode != null) {
            if (typeof object.setMode !== "object")
                throw TypeError(".Response.setMode: object expected");
            message.setMode = $root.Response.SetMode.fromObject(object.setMode);
        }
        if (object.getPlaylist != null) {
            if (typeof object.getPlaylist !== "object")
                throw TypeError(".Response.getPlaylist: object expected");
            message.getPlaylist = $root.Response.GetPlaylist.fromObject(object.getPlaylist);
        }
        if (object.setPlaylist != null) {
            if (typeof object.setPlaylist !== "object")
                throw TypeError(".Response.setPlaylist: object expected");
            message.setPlaylist = $root.Response.SetPlaylist.fromObject(object.setPlaylist);
        }
        if (object.getPlaystate != null) {
            if (typeof object.getPlaystate !== "object")
                throw TypeError(".Response.getPlaystate: object expected");
            message.getPlaystate = $root.Response.GetPlaystate.fromObject(object.getPlaystate);
        }
        if (object.authenticate != null) {
            if (typeof object.authenticate !== "object")
                throw TypeError(".Response.authenticate: object expected");
            message.authenticate = $root.Response.Authenticate.fromObject(object.authenticate);
        }
        if (object.enterBootloader != null) {
            if (typeof object.enterBootloader !== "object")
                throw TypeError(".Response.enterBootloader: object expected");
            message.enterBootloader = $root.Response.EnterBootloader.fromObject(object.enterBootloader);
        }
        if (object.reboot != null) {
            if (typeof object.reboot !== "object")
                throw TypeError(".Response.reboot: object expected");
            message.reboot = $root.Response.Reboot.fromObject(object.reboot);
        }
        if (object.factoryReset != null) {
            if (typeof object.factoryReset !== "object")
                throw TypeError(".Response.factoryReset: object expected");
            message.factoryReset = $root.Response.FactoryReset.fromObject(object.factoryReset);
        }
        if (object.enableWatchdog != null) {
            if (typeof object.enableWatchdog !== "object")
                throw TypeError(".Response.enableWatchdog: object expected");
            message.enableWatchdog = $root.Response.EnableWatchdog.fromObject(object.enableWatchdog);
        }
        if (object.feedWatchdog != null) {
            if (typeof object.feedWatchdog !== "object")
                throw TypeError(".Response.feedWatchdog: object expected");
            message.feedWatchdog = $root.Response.FeedWatchdog.fromObject(object.feedWatchdog);
        }
        if (object.triggerFault != null) {
            if (typeof object.triggerFault !== "object")
                throw TypeError(".Response.triggerFault: object expected");
            message.triggerFault = $root.Response.TriggerFault.fromObject(object.triggerFault);
        }
        return message;
    };

    /**
     * Creates a plain object from a Response message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Response
     * @static
     * @param {Response} message Response
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Response.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.correlationId = 0;
            object.execTimeMs = 0;
            object.status = options.enums === String ? "STATUS_SUCCESS" : 0;
        }
        if (message.correlationId != null && message.hasOwnProperty("correlationId"))
            object.correlationId = message.correlationId;
        if (message.execTimeMs != null && message.hasOwnProperty("execTimeMs"))
            object.execTimeMs = message.execTimeMs;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.StatusCode[message.status] : message.status;
        if (message.ping != null && message.hasOwnProperty("ping")) {
            object.ping = $root.Response.Ping.toObject(message.ping, options);
            if (options.oneofs)
                object.msg = "ping";
        }
        if (message.getVersion != null && message.hasOwnProperty("getVersion")) {
            object.getVersion = $root.Response.GetVersion.toObject(message.getVersion, options);
            if (options.oneofs)
                object.msg = "getVersion";
        }
        if (message.getDeviceInfo != null && message.hasOwnProperty("getDeviceInfo")) {
            object.getDeviceInfo = $root.Response.GetDeviceInfo.toObject(message.getDeviceInfo, options);
            if (options.oneofs)
                object.msg = "getDeviceInfo";
        }
        if (message.getTemperature != null && message.hasOwnProperty("getTemperature")) {
            object.getTemperature = $root.Response.GetTemperature.toObject(message.getTemperature, options);
            if (options.oneofs)
                object.msg = "getTemperature";
        }
        if (message.getNonce != null && message.hasOwnProperty("getNonce")) {
            object.getNonce = $root.Response.GetNonce.toObject(message.getNonce, options);
            if (options.oneofs)
                object.msg = "getNonce";
        }
        if (message.getTime != null && message.hasOwnProperty("getTime")) {
            object.getTime = $root.Response.GetTime.toObject(message.getTime, options);
            if (options.oneofs)
                object.msg = "getTime";
        }
        if (message.setTime != null && message.hasOwnProperty("setTime")) {
            object.setTime = $root.Response.SetTime.toObject(message.setTime, options);
            if (options.oneofs)
                object.msg = "setTime";
        }
        if (message.getConfig != null && message.hasOwnProperty("getConfig")) {
            object.getConfig = $root.Response.GetConfig.toObject(message.getConfig, options);
            if (options.oneofs)
                object.msg = "getConfig";
        }
        if (message.setConfig != null && message.hasOwnProperty("setConfig")) {
            object.setConfig = $root.Response.SetConfig.toObject(message.setConfig, options);
            if (options.oneofs)
                object.msg = "setConfig";
        }
        if (message.getMode != null && message.hasOwnProperty("getMode")) {
            object.getMode = $root.Response.GetMode.toObject(message.getMode, options);
            if (options.oneofs)
                object.msg = "getMode";
        }
        if (message.setMode != null && message.hasOwnProperty("setMode")) {
            object.setMode = $root.Response.SetMode.toObject(message.setMode, options);
            if (options.oneofs)
                object.msg = "setMode";
        }
        if (message.getPlaylist != null && message.hasOwnProperty("getPlaylist")) {
            object.getPlaylist = $root.Response.GetPlaylist.toObject(message.getPlaylist, options);
            if (options.oneofs)
                object.msg = "getPlaylist";
        }
        if (message.setPlaylist != null && message.hasOwnProperty("setPlaylist")) {
            object.setPlaylist = $root.Response.SetPlaylist.toObject(message.setPlaylist, options);
            if (options.oneofs)
                object.msg = "setPlaylist";
        }
        if (message.getPlaystate != null && message.hasOwnProperty("getPlaystate")) {
            object.getPlaystate = $root.Response.GetPlaystate.toObject(message.getPlaystate, options);
            if (options.oneofs)
                object.msg = "getPlaystate";
        }
        if (message.authenticate != null && message.hasOwnProperty("authenticate")) {
            object.authenticate = $root.Response.Authenticate.toObject(message.authenticate, options);
            if (options.oneofs)
                object.msg = "authenticate";
        }
        if (message.enterBootloader != null && message.hasOwnProperty("enterBootloader")) {
            object.enterBootloader = $root.Response.EnterBootloader.toObject(message.enterBootloader, options);
            if (options.oneofs)
                object.msg = "enterBootloader";
        }
        if (message.reboot != null && message.hasOwnProperty("reboot")) {
            object.reboot = $root.Response.Reboot.toObject(message.reboot, options);
            if (options.oneofs)
                object.msg = "reboot";
        }
        if (message.factoryReset != null && message.hasOwnProperty("factoryReset")) {
            object.factoryReset = $root.Response.FactoryReset.toObject(message.factoryReset, options);
            if (options.oneofs)
                object.msg = "factoryReset";
        }
        if (message.enableWatchdog != null && message.hasOwnProperty("enableWatchdog")) {
            object.enableWatchdog = $root.Response.EnableWatchdog.toObject(message.enableWatchdog, options);
            if (options.oneofs)
                object.msg = "enableWatchdog";
        }
        if (message.feedWatchdog != null && message.hasOwnProperty("feedWatchdog")) {
            object.feedWatchdog = $root.Response.FeedWatchdog.toObject(message.feedWatchdog, options);
            if (options.oneofs)
                object.msg = "feedWatchdog";
        }
        if (message.triggerFault != null && message.hasOwnProperty("triggerFault")) {
            object.triggerFault = $root.Response.TriggerFault.toObject(message.triggerFault, options);
            if (options.oneofs)
                object.msg = "triggerFault";
        }
        return object;
    };

    /**
     * Converts this Response to JSON.
     * @function toJSON
     * @memberof Response
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Response.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Response.Ping = (function() {

        /**
         * Properties of a Ping.
         * @memberof Response
         * @interface IPing
         */

        /**
         * Constructs a new Ping.
         * @memberof Response
         * @classdesc Represents a Ping.
         * @implements IPing
         * @constructor
         * @param {Response.IPing=} [properties] Properties to set
         */
        function Ping(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Ping instance using the specified properties.
         * @function create
         * @memberof Response.Ping
         * @static
         * @param {Response.IPing=} [properties] Properties to set
         * @returns {Response.Ping} Ping instance
         */
        Ping.create = function create(properties) {
            return new Ping(properties);
        };

        /**
         * Encodes the specified Ping message. Does not implicitly {@link Response.Ping.verify|verify} messages.
         * @function encode
         * @memberof Response.Ping
         * @static
         * @param {Response.IPing} message Ping message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ping.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified Ping message, length delimited. Does not implicitly {@link Response.Ping.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.Ping
         * @static
         * @param {Response.IPing} message Ping message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ping.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Ping message from the specified reader or buffer.
         * @function decode
         * @memberof Response.Ping
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.Ping} Ping
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ping.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.Ping();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Ping message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.Ping
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.Ping} Ping
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ping.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Ping message.
         * @function verify
         * @memberof Response.Ping
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Ping.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a Ping message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.Ping
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.Ping} Ping
         */
        Ping.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.Ping)
                return object;
            return new $root.Response.Ping();
        };

        /**
         * Creates a plain object from a Ping message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.Ping
         * @static
         * @param {Response.Ping} message Ping
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ping.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Ping to JSON.
         * @function toJSON
         * @memberof Response.Ping
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ping.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Ping;
    })();

    Response.SetTime = (function() {

        /**
         * Properties of a SetTime.
         * @memberof Response
         * @interface ISetTime
         */

        /**
         * Constructs a new SetTime.
         * @memberof Response
         * @classdesc Represents a SetTime.
         * @implements ISetTime
         * @constructor
         * @param {Response.ISetTime=} [properties] Properties to set
         */
        function SetTime(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SetTime instance using the specified properties.
         * @function create
         * @memberof Response.SetTime
         * @static
         * @param {Response.ISetTime=} [properties] Properties to set
         * @returns {Response.SetTime} SetTime instance
         */
        SetTime.create = function create(properties) {
            return new SetTime(properties);
        };

        /**
         * Encodes the specified SetTime message. Does not implicitly {@link Response.SetTime.verify|verify} messages.
         * @function encode
         * @memberof Response.SetTime
         * @static
         * @param {Response.ISetTime} message SetTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetTime.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SetTime message, length delimited. Does not implicitly {@link Response.SetTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.SetTime
         * @static
         * @param {Response.ISetTime} message SetTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetTime message from the specified reader or buffer.
         * @function decode
         * @memberof Response.SetTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.SetTime} SetTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetTime.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.SetTime();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.SetTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.SetTime} SetTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetTime message.
         * @function verify
         * @memberof Response.SetTime
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetTime.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SetTime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.SetTime
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.SetTime} SetTime
         */
        SetTime.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.SetTime)
                return object;
            return new $root.Response.SetTime();
        };

        /**
         * Creates a plain object from a SetTime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.SetTime
         * @static
         * @param {Response.SetTime} message SetTime
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetTime.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SetTime to JSON.
         * @function toJSON
         * @memberof Response.SetTime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetTime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetTime;
    })();

    Response.SetConfig = (function() {

        /**
         * Properties of a SetConfig.
         * @memberof Response
         * @interface ISetConfig
         */

        /**
         * Constructs a new SetConfig.
         * @memberof Response
         * @classdesc Represents a SetConfig.
         * @implements ISetConfig
         * @constructor
         * @param {Response.ISetConfig=} [properties] Properties to set
         */
        function SetConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SetConfig instance using the specified properties.
         * @function create
         * @memberof Response.SetConfig
         * @static
         * @param {Response.ISetConfig=} [properties] Properties to set
         * @returns {Response.SetConfig} SetConfig instance
         */
        SetConfig.create = function create(properties) {
            return new SetConfig(properties);
        };

        /**
         * Encodes the specified SetConfig message. Does not implicitly {@link Response.SetConfig.verify|verify} messages.
         * @function encode
         * @memberof Response.SetConfig
         * @static
         * @param {Response.ISetConfig} message SetConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SetConfig message, length delimited. Does not implicitly {@link Response.SetConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.SetConfig
         * @static
         * @param {Response.ISetConfig} message SetConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetConfig message from the specified reader or buffer.
         * @function decode
         * @memberof Response.SetConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.SetConfig} SetConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.SetConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.SetConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.SetConfig} SetConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetConfig message.
         * @function verify
         * @memberof Response.SetConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SetConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.SetConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.SetConfig} SetConfig
         */
        SetConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.SetConfig)
                return object;
            return new $root.Response.SetConfig();
        };

        /**
         * Creates a plain object from a SetConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.SetConfig
         * @static
         * @param {Response.SetConfig} message SetConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetConfig.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SetConfig to JSON.
         * @function toJSON
         * @memberof Response.SetConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetConfig;
    })();

    Response.SetMode = (function() {

        /**
         * Properties of a SetMode.
         * @memberof Response
         * @interface ISetMode
         */

        /**
         * Constructs a new SetMode.
         * @memberof Response
         * @classdesc Represents a SetMode.
         * @implements ISetMode
         * @constructor
         * @param {Response.ISetMode=} [properties] Properties to set
         */
        function SetMode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SetMode instance using the specified properties.
         * @function create
         * @memberof Response.SetMode
         * @static
         * @param {Response.ISetMode=} [properties] Properties to set
         * @returns {Response.SetMode} SetMode instance
         */
        SetMode.create = function create(properties) {
            return new SetMode(properties);
        };

        /**
         * Encodes the specified SetMode message. Does not implicitly {@link Response.SetMode.verify|verify} messages.
         * @function encode
         * @memberof Response.SetMode
         * @static
         * @param {Response.ISetMode} message SetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SetMode message, length delimited. Does not implicitly {@link Response.SetMode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.SetMode
         * @static
         * @param {Response.ISetMode} message SetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMode message from the specified reader or buffer.
         * @function decode
         * @memberof Response.SetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.SetMode} SetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.SetMode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetMode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.SetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.SetMode} SetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMode message.
         * @function verify
         * @memberof Response.SetMode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SetMode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.SetMode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.SetMode} SetMode
         */
        SetMode.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.SetMode)
                return object;
            return new $root.Response.SetMode();
        };

        /**
         * Creates a plain object from a SetMode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.SetMode
         * @static
         * @param {Response.SetMode} message SetMode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMode.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SetMode to JSON.
         * @function toJSON
         * @memberof Response.SetMode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetMode;
    })();

    Response.SetPlaylist = (function() {

        /**
         * Properties of a SetPlaylist.
         * @memberof Response
         * @interface ISetPlaylist
         */

        /**
         * Constructs a new SetPlaylist.
         * @memberof Response
         * @classdesc Represents a SetPlaylist.
         * @implements ISetPlaylist
         * @constructor
         * @param {Response.ISetPlaylist=} [properties] Properties to set
         */
        function SetPlaylist(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SetPlaylist instance using the specified properties.
         * @function create
         * @memberof Response.SetPlaylist
         * @static
         * @param {Response.ISetPlaylist=} [properties] Properties to set
         * @returns {Response.SetPlaylist} SetPlaylist instance
         */
        SetPlaylist.create = function create(properties) {
            return new SetPlaylist(properties);
        };

        /**
         * Encodes the specified SetPlaylist message. Does not implicitly {@link Response.SetPlaylist.verify|verify} messages.
         * @function encode
         * @memberof Response.SetPlaylist
         * @static
         * @param {Response.ISetPlaylist} message SetPlaylist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetPlaylist.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SetPlaylist message, length delimited. Does not implicitly {@link Response.SetPlaylist.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.SetPlaylist
         * @static
         * @param {Response.ISetPlaylist} message SetPlaylist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetPlaylist.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetPlaylist message from the specified reader or buffer.
         * @function decode
         * @memberof Response.SetPlaylist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.SetPlaylist} SetPlaylist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetPlaylist.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.SetPlaylist();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetPlaylist message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.SetPlaylist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.SetPlaylist} SetPlaylist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetPlaylist.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetPlaylist message.
         * @function verify
         * @memberof Response.SetPlaylist
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetPlaylist.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SetPlaylist message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.SetPlaylist
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.SetPlaylist} SetPlaylist
         */
        SetPlaylist.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.SetPlaylist)
                return object;
            return new $root.Response.SetPlaylist();
        };

        /**
         * Creates a plain object from a SetPlaylist message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.SetPlaylist
         * @static
         * @param {Response.SetPlaylist} message SetPlaylist
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetPlaylist.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SetPlaylist to JSON.
         * @function toJSON
         * @memberof Response.SetPlaylist
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetPlaylist.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetPlaylist;
    })();

    Response.Authenticate = (function() {

        /**
         * Properties of an Authenticate.
         * @memberof Response
         * @interface IAuthenticate
         */

        /**
         * Constructs a new Authenticate.
         * @memberof Response
         * @classdesc Represents an Authenticate.
         * @implements IAuthenticate
         * @constructor
         * @param {Response.IAuthenticate=} [properties] Properties to set
         */
        function Authenticate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Authenticate instance using the specified properties.
         * @function create
         * @memberof Response.Authenticate
         * @static
         * @param {Response.IAuthenticate=} [properties] Properties to set
         * @returns {Response.Authenticate} Authenticate instance
         */
        Authenticate.create = function create(properties) {
            return new Authenticate(properties);
        };

        /**
         * Encodes the specified Authenticate message. Does not implicitly {@link Response.Authenticate.verify|verify} messages.
         * @function encode
         * @memberof Response.Authenticate
         * @static
         * @param {Response.IAuthenticate} message Authenticate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Authenticate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified Authenticate message, length delimited. Does not implicitly {@link Response.Authenticate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.Authenticate
         * @static
         * @param {Response.IAuthenticate} message Authenticate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Authenticate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Authenticate message from the specified reader or buffer.
         * @function decode
         * @memberof Response.Authenticate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.Authenticate} Authenticate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Authenticate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.Authenticate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Authenticate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.Authenticate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.Authenticate} Authenticate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Authenticate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Authenticate message.
         * @function verify
         * @memberof Response.Authenticate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Authenticate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an Authenticate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.Authenticate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.Authenticate} Authenticate
         */
        Authenticate.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.Authenticate)
                return object;
            return new $root.Response.Authenticate();
        };

        /**
         * Creates a plain object from an Authenticate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.Authenticate
         * @static
         * @param {Response.Authenticate} message Authenticate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Authenticate.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Authenticate to JSON.
         * @function toJSON
         * @memberof Response.Authenticate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Authenticate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Authenticate;
    })();

    Response.EnterBootloader = (function() {

        /**
         * Properties of an EnterBootloader.
         * @memberof Response
         * @interface IEnterBootloader
         */

        /**
         * Constructs a new EnterBootloader.
         * @memberof Response
         * @classdesc Represents an EnterBootloader.
         * @implements IEnterBootloader
         * @constructor
         * @param {Response.IEnterBootloader=} [properties] Properties to set
         */
        function EnterBootloader(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new EnterBootloader instance using the specified properties.
         * @function create
         * @memberof Response.EnterBootloader
         * @static
         * @param {Response.IEnterBootloader=} [properties] Properties to set
         * @returns {Response.EnterBootloader} EnterBootloader instance
         */
        EnterBootloader.create = function create(properties) {
            return new EnterBootloader(properties);
        };

        /**
         * Encodes the specified EnterBootloader message. Does not implicitly {@link Response.EnterBootloader.verify|verify} messages.
         * @function encode
         * @memberof Response.EnterBootloader
         * @static
         * @param {Response.IEnterBootloader} message EnterBootloader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterBootloader.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified EnterBootloader message, length delimited. Does not implicitly {@link Response.EnterBootloader.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.EnterBootloader
         * @static
         * @param {Response.IEnterBootloader} message EnterBootloader message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterBootloader.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnterBootloader message from the specified reader or buffer.
         * @function decode
         * @memberof Response.EnterBootloader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.EnterBootloader} EnterBootloader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterBootloader.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.EnterBootloader();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnterBootloader message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.EnterBootloader
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.EnterBootloader} EnterBootloader
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterBootloader.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnterBootloader message.
         * @function verify
         * @memberof Response.EnterBootloader
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnterBootloader.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an EnterBootloader message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.EnterBootloader
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.EnterBootloader} EnterBootloader
         */
        EnterBootloader.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.EnterBootloader)
                return object;
            return new $root.Response.EnterBootloader();
        };

        /**
         * Creates a plain object from an EnterBootloader message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.EnterBootloader
         * @static
         * @param {Response.EnterBootloader} message EnterBootloader
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnterBootloader.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this EnterBootloader to JSON.
         * @function toJSON
         * @memberof Response.EnterBootloader
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnterBootloader.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EnterBootloader;
    })();

    Response.Reboot = (function() {

        /**
         * Properties of a Reboot.
         * @memberof Response
         * @interface IReboot
         */

        /**
         * Constructs a new Reboot.
         * @memberof Response
         * @classdesc Represents a Reboot.
         * @implements IReboot
         * @constructor
         * @param {Response.IReboot=} [properties] Properties to set
         */
        function Reboot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Reboot instance using the specified properties.
         * @function create
         * @memberof Response.Reboot
         * @static
         * @param {Response.IReboot=} [properties] Properties to set
         * @returns {Response.Reboot} Reboot instance
         */
        Reboot.create = function create(properties) {
            return new Reboot(properties);
        };

        /**
         * Encodes the specified Reboot message. Does not implicitly {@link Response.Reboot.verify|verify} messages.
         * @function encode
         * @memberof Response.Reboot
         * @static
         * @param {Response.IReboot} message Reboot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reboot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified Reboot message, length delimited. Does not implicitly {@link Response.Reboot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.Reboot
         * @static
         * @param {Response.IReboot} message Reboot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reboot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Reboot message from the specified reader or buffer.
         * @function decode
         * @memberof Response.Reboot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.Reboot} Reboot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reboot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.Reboot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Reboot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.Reboot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.Reboot} Reboot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reboot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Reboot message.
         * @function verify
         * @memberof Response.Reboot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Reboot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a Reboot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.Reboot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.Reboot} Reboot
         */
        Reboot.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.Reboot)
                return object;
            return new $root.Response.Reboot();
        };

        /**
         * Creates a plain object from a Reboot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.Reboot
         * @static
         * @param {Response.Reboot} message Reboot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Reboot.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Reboot to JSON.
         * @function toJSON
         * @memberof Response.Reboot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Reboot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Reboot;
    })();

    Response.FactoryReset = (function() {

        /**
         * Properties of a FactoryReset.
         * @memberof Response
         * @interface IFactoryReset
         */

        /**
         * Constructs a new FactoryReset.
         * @memberof Response
         * @classdesc Represents a FactoryReset.
         * @implements IFactoryReset
         * @constructor
         * @param {Response.IFactoryReset=} [properties] Properties to set
         */
        function FactoryReset(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new FactoryReset instance using the specified properties.
         * @function create
         * @memberof Response.FactoryReset
         * @static
         * @param {Response.IFactoryReset=} [properties] Properties to set
         * @returns {Response.FactoryReset} FactoryReset instance
         */
        FactoryReset.create = function create(properties) {
            return new FactoryReset(properties);
        };

        /**
         * Encodes the specified FactoryReset message. Does not implicitly {@link Response.FactoryReset.verify|verify} messages.
         * @function encode
         * @memberof Response.FactoryReset
         * @static
         * @param {Response.IFactoryReset} message FactoryReset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FactoryReset.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified FactoryReset message, length delimited. Does not implicitly {@link Response.FactoryReset.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.FactoryReset
         * @static
         * @param {Response.IFactoryReset} message FactoryReset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FactoryReset.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FactoryReset message from the specified reader or buffer.
         * @function decode
         * @memberof Response.FactoryReset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.FactoryReset} FactoryReset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FactoryReset.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.FactoryReset();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FactoryReset message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.FactoryReset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.FactoryReset} FactoryReset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FactoryReset.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FactoryReset message.
         * @function verify
         * @memberof Response.FactoryReset
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FactoryReset.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a FactoryReset message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.FactoryReset
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.FactoryReset} FactoryReset
         */
        FactoryReset.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.FactoryReset)
                return object;
            return new $root.Response.FactoryReset();
        };

        /**
         * Creates a plain object from a FactoryReset message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.FactoryReset
         * @static
         * @param {Response.FactoryReset} message FactoryReset
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FactoryReset.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this FactoryReset to JSON.
         * @function toJSON
         * @memberof Response.FactoryReset
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FactoryReset.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FactoryReset;
    })();

    Response.EnableWatchdog = (function() {

        /**
         * Properties of an EnableWatchdog.
         * @memberof Response
         * @interface IEnableWatchdog
         */

        /**
         * Constructs a new EnableWatchdog.
         * @memberof Response
         * @classdesc Represents an EnableWatchdog.
         * @implements IEnableWatchdog
         * @constructor
         * @param {Response.IEnableWatchdog=} [properties] Properties to set
         */
        function EnableWatchdog(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new EnableWatchdog instance using the specified properties.
         * @function create
         * @memberof Response.EnableWatchdog
         * @static
         * @param {Response.IEnableWatchdog=} [properties] Properties to set
         * @returns {Response.EnableWatchdog} EnableWatchdog instance
         */
        EnableWatchdog.create = function create(properties) {
            return new EnableWatchdog(properties);
        };

        /**
         * Encodes the specified EnableWatchdog message. Does not implicitly {@link Response.EnableWatchdog.verify|verify} messages.
         * @function encode
         * @memberof Response.EnableWatchdog
         * @static
         * @param {Response.IEnableWatchdog} message EnableWatchdog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnableWatchdog.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified EnableWatchdog message, length delimited. Does not implicitly {@link Response.EnableWatchdog.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.EnableWatchdog
         * @static
         * @param {Response.IEnableWatchdog} message EnableWatchdog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnableWatchdog.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnableWatchdog message from the specified reader or buffer.
         * @function decode
         * @memberof Response.EnableWatchdog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.EnableWatchdog} EnableWatchdog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnableWatchdog.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.EnableWatchdog();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnableWatchdog message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.EnableWatchdog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.EnableWatchdog} EnableWatchdog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnableWatchdog.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnableWatchdog message.
         * @function verify
         * @memberof Response.EnableWatchdog
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnableWatchdog.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an EnableWatchdog message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.EnableWatchdog
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.EnableWatchdog} EnableWatchdog
         */
        EnableWatchdog.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.EnableWatchdog)
                return object;
            return new $root.Response.EnableWatchdog();
        };

        /**
         * Creates a plain object from an EnableWatchdog message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.EnableWatchdog
         * @static
         * @param {Response.EnableWatchdog} message EnableWatchdog
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnableWatchdog.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this EnableWatchdog to JSON.
         * @function toJSON
         * @memberof Response.EnableWatchdog
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnableWatchdog.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EnableWatchdog;
    })();

    Response.FeedWatchdog = (function() {

        /**
         * Properties of a FeedWatchdog.
         * @memberof Response
         * @interface IFeedWatchdog
         */

        /**
         * Constructs a new FeedWatchdog.
         * @memberof Response
         * @classdesc Represents a FeedWatchdog.
         * @implements IFeedWatchdog
         * @constructor
         * @param {Response.IFeedWatchdog=} [properties] Properties to set
         */
        function FeedWatchdog(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new FeedWatchdog instance using the specified properties.
         * @function create
         * @memberof Response.FeedWatchdog
         * @static
         * @param {Response.IFeedWatchdog=} [properties] Properties to set
         * @returns {Response.FeedWatchdog} FeedWatchdog instance
         */
        FeedWatchdog.create = function create(properties) {
            return new FeedWatchdog(properties);
        };

        /**
         * Encodes the specified FeedWatchdog message. Does not implicitly {@link Response.FeedWatchdog.verify|verify} messages.
         * @function encode
         * @memberof Response.FeedWatchdog
         * @static
         * @param {Response.IFeedWatchdog} message FeedWatchdog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeedWatchdog.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified FeedWatchdog message, length delimited. Does not implicitly {@link Response.FeedWatchdog.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.FeedWatchdog
         * @static
         * @param {Response.IFeedWatchdog} message FeedWatchdog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeedWatchdog.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FeedWatchdog message from the specified reader or buffer.
         * @function decode
         * @memberof Response.FeedWatchdog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.FeedWatchdog} FeedWatchdog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeedWatchdog.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.FeedWatchdog();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FeedWatchdog message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.FeedWatchdog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.FeedWatchdog} FeedWatchdog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeedWatchdog.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FeedWatchdog message.
         * @function verify
         * @memberof Response.FeedWatchdog
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FeedWatchdog.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a FeedWatchdog message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.FeedWatchdog
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.FeedWatchdog} FeedWatchdog
         */
        FeedWatchdog.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.FeedWatchdog)
                return object;
            return new $root.Response.FeedWatchdog();
        };

        /**
         * Creates a plain object from a FeedWatchdog message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.FeedWatchdog
         * @static
         * @param {Response.FeedWatchdog} message FeedWatchdog
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FeedWatchdog.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this FeedWatchdog to JSON.
         * @function toJSON
         * @memberof Response.FeedWatchdog
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FeedWatchdog.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FeedWatchdog;
    })();

    Response.TriggerFault = (function() {

        /**
         * Properties of a TriggerFault.
         * @memberof Response
         * @interface ITriggerFault
         */

        /**
         * Constructs a new TriggerFault.
         * @memberof Response
         * @classdesc Represents a TriggerFault.
         * @implements ITriggerFault
         * @constructor
         * @param {Response.ITriggerFault=} [properties] Properties to set
         */
        function TriggerFault(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TriggerFault instance using the specified properties.
         * @function create
         * @memberof Response.TriggerFault
         * @static
         * @param {Response.ITriggerFault=} [properties] Properties to set
         * @returns {Response.TriggerFault} TriggerFault instance
         */
        TriggerFault.create = function create(properties) {
            return new TriggerFault(properties);
        };

        /**
         * Encodes the specified TriggerFault message. Does not implicitly {@link Response.TriggerFault.verify|verify} messages.
         * @function encode
         * @memberof Response.TriggerFault
         * @static
         * @param {Response.ITriggerFault} message TriggerFault message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerFault.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TriggerFault message, length delimited. Does not implicitly {@link Response.TriggerFault.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.TriggerFault
         * @static
         * @param {Response.ITriggerFault} message TriggerFault message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerFault.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TriggerFault message from the specified reader or buffer.
         * @function decode
         * @memberof Response.TriggerFault
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.TriggerFault} TriggerFault
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerFault.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.TriggerFault();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TriggerFault message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.TriggerFault
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.TriggerFault} TriggerFault
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerFault.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TriggerFault message.
         * @function verify
         * @memberof Response.TriggerFault
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TriggerFault.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TriggerFault message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.TriggerFault
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.TriggerFault} TriggerFault
         */
        TriggerFault.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.TriggerFault)
                return object;
            return new $root.Response.TriggerFault();
        };

        /**
         * Creates a plain object from a TriggerFault message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.TriggerFault
         * @static
         * @param {Response.TriggerFault} message TriggerFault
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TriggerFault.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TriggerFault to JSON.
         * @function toJSON
         * @memberof Response.TriggerFault
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TriggerFault.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TriggerFault;
    })();

    Response.GetVersion = (function() {

        /**
         * Properties of a GetVersion.
         * @memberof Response
         * @interface IGetVersion
         * @property {string|null} [appVersion] GetVersion appVersion
         * @property {number|null} [hwVersion] GetVersion hwVersion
         * @property {number|null} [signingProtocolVersion] GetVersion signingProtocolVersion
         */

        /**
         * Constructs a new GetVersion.
         * @memberof Response
         * @classdesc Represents a GetVersion.
         * @implements IGetVersion
         * @constructor
         * @param {Response.IGetVersion=} [properties] Properties to set
         */
        function GetVersion(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetVersion appVersion.
         * @member {string} appVersion
         * @memberof Response.GetVersion
         * @instance
         */
        GetVersion.prototype.appVersion = "";

        /**
         * GetVersion hwVersion.
         * @member {number} hwVersion
         * @memberof Response.GetVersion
         * @instance
         */
        GetVersion.prototype.hwVersion = 0;

        /**
         * GetVersion signingProtocolVersion.
         * @member {number} signingProtocolVersion
         * @memberof Response.GetVersion
         * @instance
         */
        GetVersion.prototype.signingProtocolVersion = 0;

        /**
         * Creates a new GetVersion instance using the specified properties.
         * @function create
         * @memberof Response.GetVersion
         * @static
         * @param {Response.IGetVersion=} [properties] Properties to set
         * @returns {Response.GetVersion} GetVersion instance
         */
        GetVersion.create = function create(properties) {
            return new GetVersion(properties);
        };

        /**
         * Encodes the specified GetVersion message. Does not implicitly {@link Response.GetVersion.verify|verify} messages.
         * @function encode
         * @memberof Response.GetVersion
         * @static
         * @param {Response.IGetVersion} message GetVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetVersion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appVersion != null && Object.hasOwnProperty.call(message, "appVersion"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.appVersion);
            if (message.hwVersion != null && Object.hasOwnProperty.call(message, "hwVersion"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.hwVersion);
            if (message.signingProtocolVersion != null && Object.hasOwnProperty.call(message, "signingProtocolVersion"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.signingProtocolVersion);
            return writer;
        };

        /**
         * Encodes the specified GetVersion message, length delimited. Does not implicitly {@link Response.GetVersion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.GetVersion
         * @static
         * @param {Response.IGetVersion} message GetVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetVersion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetVersion message from the specified reader or buffer.
         * @function decode
         * @memberof Response.GetVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.GetVersion} GetVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetVersion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.GetVersion();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.appVersion = reader.string();
                    break;
                case 2:
                    message.hwVersion = reader.uint32();
                    break;
                case 3:
                    message.signingProtocolVersion = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetVersion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.GetVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.GetVersion} GetVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetVersion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetVersion message.
         * @function verify
         * @memberof Response.GetVersion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetVersion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.appVersion != null && message.hasOwnProperty("appVersion"))
                if (!$util.isString(message.appVersion))
                    return "appVersion: string expected";
            if (message.hwVersion != null && message.hasOwnProperty("hwVersion"))
                if (!$util.isInteger(message.hwVersion))
                    return "hwVersion: integer expected";
            if (message.signingProtocolVersion != null && message.hasOwnProperty("signingProtocolVersion"))
                if (!$util.isInteger(message.signingProtocolVersion))
                    return "signingProtocolVersion: integer expected";
            return null;
        };

        /**
         * Creates a GetVersion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.GetVersion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.GetVersion} GetVersion
         */
        GetVersion.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.GetVersion)
                return object;
            let message = new $root.Response.GetVersion();
            if (object.appVersion != null)
                message.appVersion = String(object.appVersion);
            if (object.hwVersion != null)
                message.hwVersion = object.hwVersion >>> 0;
            if (object.signingProtocolVersion != null)
                message.signingProtocolVersion = object.signingProtocolVersion >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a GetVersion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.GetVersion
         * @static
         * @param {Response.GetVersion} message GetVersion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetVersion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.appVersion = "";
                object.hwVersion = 0;
                object.signingProtocolVersion = 0;
            }
            if (message.appVersion != null && message.hasOwnProperty("appVersion"))
                object.appVersion = message.appVersion;
            if (message.hwVersion != null && message.hasOwnProperty("hwVersion"))
                object.hwVersion = message.hwVersion;
            if (message.signingProtocolVersion != null && message.hasOwnProperty("signingProtocolVersion"))
                object.signingProtocolVersion = message.signingProtocolVersion;
            return object;
        };

        /**
         * Converts this GetVersion to JSON.
         * @function toJSON
         * @memberof Response.GetVersion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetVersion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetVersion;
    })();

    Response.GetDeviceInfo = (function() {

        /**
         * Properties of a GetDeviceInfo.
         * @memberof Response
         * @interface IGetDeviceInfo
         * @property {Uint8Array|null} [uuid] GetDeviceInfo uuid
         * @property {Uint8Array|null} [macAddr] GetDeviceInfo macAddr
         */

        /**
         * Constructs a new GetDeviceInfo.
         * @memberof Response
         * @classdesc Represents a GetDeviceInfo.
         * @implements IGetDeviceInfo
         * @constructor
         * @param {Response.IGetDeviceInfo=} [properties] Properties to set
         */
        function GetDeviceInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetDeviceInfo uuid.
         * @member {Uint8Array} uuid
         * @memberof Response.GetDeviceInfo
         * @instance
         */
        GetDeviceInfo.prototype.uuid = $util.newBuffer([]);

        /**
         * GetDeviceInfo macAddr.
         * @member {Uint8Array} macAddr
         * @memberof Response.GetDeviceInfo
         * @instance
         */
        GetDeviceInfo.prototype.macAddr = $util.newBuffer([]);

        /**
         * Creates a new GetDeviceInfo instance using the specified properties.
         * @function create
         * @memberof Response.GetDeviceInfo
         * @static
         * @param {Response.IGetDeviceInfo=} [properties] Properties to set
         * @returns {Response.GetDeviceInfo} GetDeviceInfo instance
         */
        GetDeviceInfo.create = function create(properties) {
            return new GetDeviceInfo(properties);
        };

        /**
         * Encodes the specified GetDeviceInfo message. Does not implicitly {@link Response.GetDeviceInfo.verify|verify} messages.
         * @function encode
         * @memberof Response.GetDeviceInfo
         * @static
         * @param {Response.IGetDeviceInfo} message GetDeviceInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDeviceInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.uuid);
            if (message.macAddr != null && Object.hasOwnProperty.call(message, "macAddr"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.macAddr);
            return writer;
        };

        /**
         * Encodes the specified GetDeviceInfo message, length delimited. Does not implicitly {@link Response.GetDeviceInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.GetDeviceInfo
         * @static
         * @param {Response.IGetDeviceInfo} message GetDeviceInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDeviceInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetDeviceInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Response.GetDeviceInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.GetDeviceInfo} GetDeviceInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDeviceInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.GetDeviceInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uuid = reader.bytes();
                    break;
                case 2:
                    message.macAddr = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetDeviceInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.GetDeviceInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.GetDeviceInfo} GetDeviceInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDeviceInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetDeviceInfo message.
         * @function verify
         * @memberof Response.GetDeviceInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetDeviceInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!(message.uuid && typeof message.uuid.length === "number" || $util.isString(message.uuid)))
                    return "uuid: buffer expected";
            if (message.macAddr != null && message.hasOwnProperty("macAddr"))
                if (!(message.macAddr && typeof message.macAddr.length === "number" || $util.isString(message.macAddr)))
                    return "macAddr: buffer expected";
            return null;
        };

        /**
         * Creates a GetDeviceInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.GetDeviceInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.GetDeviceInfo} GetDeviceInfo
         */
        GetDeviceInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.GetDeviceInfo)
                return object;
            let message = new $root.Response.GetDeviceInfo();
            if (object.uuid != null)
                if (typeof object.uuid === "string")
                    $util.base64.decode(object.uuid, message.uuid = $util.newBuffer($util.base64.length(object.uuid)), 0);
                else if (object.uuid.length)
                    message.uuid = object.uuid;
            if (object.macAddr != null)
                if (typeof object.macAddr === "string")
                    $util.base64.decode(object.macAddr, message.macAddr = $util.newBuffer($util.base64.length(object.macAddr)), 0);
                else if (object.macAddr.length)
                    message.macAddr = object.macAddr;
            return message;
        };

        /**
         * Creates a plain object from a GetDeviceInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.GetDeviceInfo
         * @static
         * @param {Response.GetDeviceInfo} message GetDeviceInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetDeviceInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.uuid = "";
                else {
                    object.uuid = [];
                    if (options.bytes !== Array)
                        object.uuid = $util.newBuffer(object.uuid);
                }
                if (options.bytes === String)
                    object.macAddr = "";
                else {
                    object.macAddr = [];
                    if (options.bytes !== Array)
                        object.macAddr = $util.newBuffer(object.macAddr);
                }
            }
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                object.uuid = options.bytes === String ? $util.base64.encode(message.uuid, 0, message.uuid.length) : options.bytes === Array ? Array.prototype.slice.call(message.uuid) : message.uuid;
            if (message.macAddr != null && message.hasOwnProperty("macAddr"))
                object.macAddr = options.bytes === String ? $util.base64.encode(message.macAddr, 0, message.macAddr.length) : options.bytes === Array ? Array.prototype.slice.call(message.macAddr) : message.macAddr;
            return object;
        };

        /**
         * Converts this GetDeviceInfo to JSON.
         * @function toJSON
         * @memberof Response.GetDeviceInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetDeviceInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetDeviceInfo;
    })();

    Response.GetConfig = (function() {

        /**
         * Properties of a GetConfig.
         * @memberof Response
         * @interface IGetConfig
         * @property {number|null} [deviceId] GetConfig deviceId
         * @property {number|null} [streamId] GetConfig streamId
         * @property {number|null} [locationId] GetConfig locationId
         * @property {number|null} [screenHeadingDeg] GetConfig screenHeadingDeg
         * @property {number|null} [bleTxPowerDbm] GetConfig bleTxPowerDbm
         * @property {number|null} [bleAdvIntervalCatchMs] GetConfig bleAdvIntervalCatchMs
         * @property {number|null} [bleAdvIntervalIbeaconMs] GetConfig bleAdvIntervalIbeaconMs
         * @property {boolean|null} [ibeaconEnable] GetConfig ibeaconEnable
         * @property {Uint8Array|null} [ibeaconUuid] GetConfig ibeaconUuid
         * @property {boolean|null} [extAntenna] GetConfig extAntenna
         */

        /**
         * Constructs a new GetConfig.
         * @memberof Response
         * @classdesc Represents a GetConfig.
         * @implements IGetConfig
         * @constructor
         * @param {Response.IGetConfig=} [properties] Properties to set
         */
        function GetConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetConfig deviceId.
         * @member {number} deviceId
         * @memberof Response.GetConfig
         * @instance
         */
        GetConfig.prototype.deviceId = 0;

        /**
         * GetConfig streamId.
         * @member {number} streamId
         * @memberof Response.GetConfig
         * @instance
         */
        GetConfig.prototype.streamId = 0;

        /**
         * GetConfig locationId.
         * @member {number} locationId
         * @memberof Response.GetConfig
         * @instance
         */
        GetConfig.prototype.locationId = 0;

        /**
         * GetConfig screenHeadingDeg.
         * @member {number} screenHeadingDeg
         * @memberof Response.GetConfig
         * @instance
         */
        GetConfig.prototype.screenHeadingDeg = 0;

        /**
         * GetConfig bleTxPowerDbm.
         * @member {number} bleTxPowerDbm
         * @memberof Response.GetConfig
         * @instance
         */
        GetConfig.prototype.bleTxPowerDbm = 0;

        /**
         * GetConfig bleAdvIntervalCatchMs.
         * @member {number} bleAdvIntervalCatchMs
         * @memberof Response.GetConfig
         * @instance
         */
        GetConfig.prototype.bleAdvIntervalCatchMs = 0;

        /**
         * GetConfig bleAdvIntervalIbeaconMs.
         * @member {number} bleAdvIntervalIbeaconMs
         * @memberof Response.GetConfig
         * @instance
         */
        GetConfig.prototype.bleAdvIntervalIbeaconMs = 0;

        /**
         * GetConfig ibeaconEnable.
         * @member {boolean} ibeaconEnable
         * @memberof Response.GetConfig
         * @instance
         */
        GetConfig.prototype.ibeaconEnable = false;

        /**
         * GetConfig ibeaconUuid.
         * @member {Uint8Array} ibeaconUuid
         * @memberof Response.GetConfig
         * @instance
         */
        GetConfig.prototype.ibeaconUuid = $util.newBuffer([]);

        /**
         * GetConfig extAntenna.
         * @member {boolean|null|undefined} extAntenna
         * @memberof Response.GetConfig
         * @instance
         */
        GetConfig.prototype.extAntenna = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GetConfig _extAntenna.
         * @member {"extAntenna"|undefined} _extAntenna
         * @memberof Response.GetConfig
         * @instance
         */
        Object.defineProperty(GetConfig.prototype, "_extAntenna", {
            get: $util.oneOfGetter($oneOfFields = ["extAntenna"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetConfig instance using the specified properties.
         * @function create
         * @memberof Response.GetConfig
         * @static
         * @param {Response.IGetConfig=} [properties] Properties to set
         * @returns {Response.GetConfig} GetConfig instance
         */
        GetConfig.create = function create(properties) {
            return new GetConfig(properties);
        };

        /**
         * Encodes the specified GetConfig message. Does not implicitly {@link Response.GetConfig.verify|verify} messages.
         * @function encode
         * @memberof Response.GetConfig
         * @static
         * @param {Response.IGetConfig} message GetConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.deviceId);
            if (message.streamId != null && Object.hasOwnProperty.call(message, "streamId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.streamId);
            if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.locationId);
            if (message.screenHeadingDeg != null && Object.hasOwnProperty.call(message, "screenHeadingDeg"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.screenHeadingDeg);
            if (message.bleTxPowerDbm != null && Object.hasOwnProperty.call(message, "bleTxPowerDbm"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.bleTxPowerDbm);
            if (message.bleAdvIntervalCatchMs != null && Object.hasOwnProperty.call(message, "bleAdvIntervalCatchMs"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.bleAdvIntervalCatchMs);
            if (message.bleAdvIntervalIbeaconMs != null && Object.hasOwnProperty.call(message, "bleAdvIntervalIbeaconMs"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.bleAdvIntervalIbeaconMs);
            if (message.ibeaconEnable != null && Object.hasOwnProperty.call(message, "ibeaconEnable"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.ibeaconEnable);
            if (message.ibeaconUuid != null && Object.hasOwnProperty.call(message, "ibeaconUuid"))
                writer.uint32(/* id 11, wireType 2 =*/90).bytes(message.ibeaconUuid);
            if (message.extAntenna != null && Object.hasOwnProperty.call(message, "extAntenna"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.extAntenna);
            return writer;
        };

        /**
         * Encodes the specified GetConfig message, length delimited. Does not implicitly {@link Response.GetConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.GetConfig
         * @static
         * @param {Response.IGetConfig} message GetConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetConfig message from the specified reader or buffer.
         * @function decode
         * @memberof Response.GetConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.GetConfig} GetConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.GetConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.deviceId = reader.uint32();
                    break;
                case 2:
                    message.streamId = reader.uint32();
                    break;
                case 3:
                    message.locationId = reader.uint32();
                    break;
                case 4:
                    message.screenHeadingDeg = reader.uint32();
                    break;
                case 5:
                    message.bleTxPowerDbm = reader.int32();
                    break;
                case 8:
                    message.bleAdvIntervalCatchMs = reader.uint32();
                    break;
                case 9:
                    message.bleAdvIntervalIbeaconMs = reader.uint32();
                    break;
                case 10:
                    message.ibeaconEnable = reader.bool();
                    break;
                case 11:
                    message.ibeaconUuid = reader.bytes();
                    break;
                case 12:
                    message.extAntenna = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.GetConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.GetConfig} GetConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetConfig message.
         * @function verify
         * @memberof Response.GetConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                if (!$util.isInteger(message.deviceId))
                    return "deviceId: integer expected";
            if (message.streamId != null && message.hasOwnProperty("streamId"))
                if (!$util.isInteger(message.streamId))
                    return "streamId: integer expected";
            if (message.locationId != null && message.hasOwnProperty("locationId"))
                if (!$util.isInteger(message.locationId))
                    return "locationId: integer expected";
            if (message.screenHeadingDeg != null && message.hasOwnProperty("screenHeadingDeg"))
                if (!$util.isInteger(message.screenHeadingDeg))
                    return "screenHeadingDeg: integer expected";
            if (message.bleTxPowerDbm != null && message.hasOwnProperty("bleTxPowerDbm"))
                if (!$util.isInteger(message.bleTxPowerDbm))
                    return "bleTxPowerDbm: integer expected";
            if (message.bleAdvIntervalCatchMs != null && message.hasOwnProperty("bleAdvIntervalCatchMs"))
                if (!$util.isInteger(message.bleAdvIntervalCatchMs))
                    return "bleAdvIntervalCatchMs: integer expected";
            if (message.bleAdvIntervalIbeaconMs != null && message.hasOwnProperty("bleAdvIntervalIbeaconMs"))
                if (!$util.isInteger(message.bleAdvIntervalIbeaconMs))
                    return "bleAdvIntervalIbeaconMs: integer expected";
            if (message.ibeaconEnable != null && message.hasOwnProperty("ibeaconEnable"))
                if (typeof message.ibeaconEnable !== "boolean")
                    return "ibeaconEnable: boolean expected";
            if (message.ibeaconUuid != null && message.hasOwnProperty("ibeaconUuid"))
                if (!(message.ibeaconUuid && typeof message.ibeaconUuid.length === "number" || $util.isString(message.ibeaconUuid)))
                    return "ibeaconUuid: buffer expected";
            if (message.extAntenna != null && message.hasOwnProperty("extAntenna")) {
                properties._extAntenna = 1;
                if (typeof message.extAntenna !== "boolean")
                    return "extAntenna: boolean expected";
            }
            return null;
        };

        /**
         * Creates a GetConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.GetConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.GetConfig} GetConfig
         */
        GetConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.GetConfig)
                return object;
            let message = new $root.Response.GetConfig();
            if (object.deviceId != null)
                message.deviceId = object.deviceId >>> 0;
            if (object.streamId != null)
                message.streamId = object.streamId >>> 0;
            if (object.locationId != null)
                message.locationId = object.locationId >>> 0;
            if (object.screenHeadingDeg != null)
                message.screenHeadingDeg = object.screenHeadingDeg >>> 0;
            if (object.bleTxPowerDbm != null)
                message.bleTxPowerDbm = object.bleTxPowerDbm | 0;
            if (object.bleAdvIntervalCatchMs != null)
                message.bleAdvIntervalCatchMs = object.bleAdvIntervalCatchMs >>> 0;
            if (object.bleAdvIntervalIbeaconMs != null)
                message.bleAdvIntervalIbeaconMs = object.bleAdvIntervalIbeaconMs >>> 0;
            if (object.ibeaconEnable != null)
                message.ibeaconEnable = Boolean(object.ibeaconEnable);
            if (object.ibeaconUuid != null)
                if (typeof object.ibeaconUuid === "string")
                    $util.base64.decode(object.ibeaconUuid, message.ibeaconUuid = $util.newBuffer($util.base64.length(object.ibeaconUuid)), 0);
                else if (object.ibeaconUuid.length)
                    message.ibeaconUuid = object.ibeaconUuid;
            if (object.extAntenna != null)
                message.extAntenna = Boolean(object.extAntenna);
            return message;
        };

        /**
         * Creates a plain object from a GetConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.GetConfig
         * @static
         * @param {Response.GetConfig} message GetConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.deviceId = 0;
                object.streamId = 0;
                object.locationId = 0;
                object.screenHeadingDeg = 0;
                object.bleTxPowerDbm = 0;
                object.bleAdvIntervalCatchMs = 0;
                object.bleAdvIntervalIbeaconMs = 0;
                object.ibeaconEnable = false;
                if (options.bytes === String)
                    object.ibeaconUuid = "";
                else {
                    object.ibeaconUuid = [];
                    if (options.bytes !== Array)
                        object.ibeaconUuid = $util.newBuffer(object.ibeaconUuid);
                }
            }
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                object.deviceId = message.deviceId;
            if (message.streamId != null && message.hasOwnProperty("streamId"))
                object.streamId = message.streamId;
            if (message.locationId != null && message.hasOwnProperty("locationId"))
                object.locationId = message.locationId;
            if (message.screenHeadingDeg != null && message.hasOwnProperty("screenHeadingDeg"))
                object.screenHeadingDeg = message.screenHeadingDeg;
            if (message.bleTxPowerDbm != null && message.hasOwnProperty("bleTxPowerDbm"))
                object.bleTxPowerDbm = message.bleTxPowerDbm;
            if (message.bleAdvIntervalCatchMs != null && message.hasOwnProperty("bleAdvIntervalCatchMs"))
                object.bleAdvIntervalCatchMs = message.bleAdvIntervalCatchMs;
            if (message.bleAdvIntervalIbeaconMs != null && message.hasOwnProperty("bleAdvIntervalIbeaconMs"))
                object.bleAdvIntervalIbeaconMs = message.bleAdvIntervalIbeaconMs;
            if (message.ibeaconEnable != null && message.hasOwnProperty("ibeaconEnable"))
                object.ibeaconEnable = message.ibeaconEnable;
            if (message.ibeaconUuid != null && message.hasOwnProperty("ibeaconUuid"))
                object.ibeaconUuid = options.bytes === String ? $util.base64.encode(message.ibeaconUuid, 0, message.ibeaconUuid.length) : options.bytes === Array ? Array.prototype.slice.call(message.ibeaconUuid) : message.ibeaconUuid;
            if (message.extAntenna != null && message.hasOwnProperty("extAntenna")) {
                object.extAntenna = message.extAntenna;
                if (options.oneofs)
                    object._extAntenna = "extAntenna";
            }
            return object;
        };

        /**
         * Converts this GetConfig to JSON.
         * @function toJSON
         * @memberof Response.GetConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetConfig;
    })();

    Response.GetTime = (function() {

        /**
         * Properties of a GetTime.
         * @memberof Response
         * @interface IGetTime
         * @property {boolean|null} [systimeIsSet] GetTime systimeIsSet
         * @property {number|Long|null} [systimeMs] GetTime systimeMs
         * @property {number|Long|null} [uptimeMs] GetTime uptimeMs
         */

        /**
         * Constructs a new GetTime.
         * @memberof Response
         * @classdesc Represents a GetTime.
         * @implements IGetTime
         * @constructor
         * @param {Response.IGetTime=} [properties] Properties to set
         */
        function GetTime(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetTime systimeIsSet.
         * @member {boolean} systimeIsSet
         * @memberof Response.GetTime
         * @instance
         */
        GetTime.prototype.systimeIsSet = false;

        /**
         * GetTime systimeMs.
         * @member {number|Long} systimeMs
         * @memberof Response.GetTime
         * @instance
         */
        GetTime.prototype.systimeMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * GetTime uptimeMs.
         * @member {number|Long} uptimeMs
         * @memberof Response.GetTime
         * @instance
         */
        GetTime.prototype.uptimeMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new GetTime instance using the specified properties.
         * @function create
         * @memberof Response.GetTime
         * @static
         * @param {Response.IGetTime=} [properties] Properties to set
         * @returns {Response.GetTime} GetTime instance
         */
        GetTime.create = function create(properties) {
            return new GetTime(properties);
        };

        /**
         * Encodes the specified GetTime message. Does not implicitly {@link Response.GetTime.verify|verify} messages.
         * @function encode
         * @memberof Response.GetTime
         * @static
         * @param {Response.IGetTime} message GetTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTime.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.systimeIsSet != null && Object.hasOwnProperty.call(message, "systimeIsSet"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.systimeIsSet);
            if (message.systimeMs != null && Object.hasOwnProperty.call(message, "systimeMs"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.systimeMs);
            if (message.uptimeMs != null && Object.hasOwnProperty.call(message, "uptimeMs"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.uptimeMs);
            return writer;
        };

        /**
         * Encodes the specified GetTime message, length delimited. Does not implicitly {@link Response.GetTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.GetTime
         * @static
         * @param {Response.IGetTime} message GetTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTime message from the specified reader or buffer.
         * @function decode
         * @memberof Response.GetTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.GetTime} GetTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTime.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.GetTime();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.systimeIsSet = reader.bool();
                    break;
                case 2:
                    message.systimeMs = reader.uint64();
                    break;
                case 3:
                    message.uptimeMs = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.GetTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.GetTime} GetTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTime message.
         * @function verify
         * @memberof Response.GetTime
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTime.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.systimeIsSet != null && message.hasOwnProperty("systimeIsSet"))
                if (typeof message.systimeIsSet !== "boolean")
                    return "systimeIsSet: boolean expected";
            if (message.systimeMs != null && message.hasOwnProperty("systimeMs"))
                if (!$util.isInteger(message.systimeMs) && !(message.systimeMs && $util.isInteger(message.systimeMs.low) && $util.isInteger(message.systimeMs.high)))
                    return "systimeMs: integer|Long expected";
            if (message.uptimeMs != null && message.hasOwnProperty("uptimeMs"))
                if (!$util.isInteger(message.uptimeMs) && !(message.uptimeMs && $util.isInteger(message.uptimeMs.low) && $util.isInteger(message.uptimeMs.high)))
                    return "uptimeMs: integer|Long expected";
            return null;
        };

        /**
         * Creates a GetTime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.GetTime
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.GetTime} GetTime
         */
        GetTime.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.GetTime)
                return object;
            let message = new $root.Response.GetTime();
            if (object.systimeIsSet != null)
                message.systimeIsSet = Boolean(object.systimeIsSet);
            if (object.systimeMs != null)
                if ($util.Long)
                    (message.systimeMs = $util.Long.fromValue(object.systimeMs)).unsigned = true;
                else if (typeof object.systimeMs === "string")
                    message.systimeMs = parseInt(object.systimeMs, 10);
                else if (typeof object.systimeMs === "number")
                    message.systimeMs = object.systimeMs;
                else if (typeof object.systimeMs === "object")
                    message.systimeMs = new $util.LongBits(object.systimeMs.low >>> 0, object.systimeMs.high >>> 0).toNumber(true);
            if (object.uptimeMs != null)
                if ($util.Long)
                    (message.uptimeMs = $util.Long.fromValue(object.uptimeMs)).unsigned = true;
                else if (typeof object.uptimeMs === "string")
                    message.uptimeMs = parseInt(object.uptimeMs, 10);
                else if (typeof object.uptimeMs === "number")
                    message.uptimeMs = object.uptimeMs;
                else if (typeof object.uptimeMs === "object")
                    message.uptimeMs = new $util.LongBits(object.uptimeMs.low >>> 0, object.uptimeMs.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a GetTime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.GetTime
         * @static
         * @param {Response.GetTime} message GetTime
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTime.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.systimeIsSet = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.systimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.systimeMs = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.uptimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.uptimeMs = options.longs === String ? "0" : 0;
            }
            if (message.systimeIsSet != null && message.hasOwnProperty("systimeIsSet"))
                object.systimeIsSet = message.systimeIsSet;
            if (message.systimeMs != null && message.hasOwnProperty("systimeMs"))
                if (typeof message.systimeMs === "number")
                    object.systimeMs = options.longs === String ? String(message.systimeMs) : message.systimeMs;
                else
                    object.systimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.systimeMs) : options.longs === Number ? new $util.LongBits(message.systimeMs.low >>> 0, message.systimeMs.high >>> 0).toNumber(true) : message.systimeMs;
            if (message.uptimeMs != null && message.hasOwnProperty("uptimeMs"))
                if (typeof message.uptimeMs === "number")
                    object.uptimeMs = options.longs === String ? String(message.uptimeMs) : message.uptimeMs;
                else
                    object.uptimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.uptimeMs) : options.longs === Number ? new $util.LongBits(message.uptimeMs.low >>> 0, message.uptimeMs.high >>> 0).toNumber(true) : message.uptimeMs;
            return object;
        };

        /**
         * Converts this GetTime to JSON.
         * @function toJSON
         * @memberof Response.GetTime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetTime;
    })();

    Response.GetTemperature = (function() {

        /**
         * Properties of a GetTemperature.
         * @memberof Response
         * @interface IGetTemperature
         * @property {number|null} [temperatureCelsius] GetTemperature temperatureCelsius
         */

        /**
         * Constructs a new GetTemperature.
         * @memberof Response
         * @classdesc Represents a GetTemperature.
         * @implements IGetTemperature
         * @constructor
         * @param {Response.IGetTemperature=} [properties] Properties to set
         */
        function GetTemperature(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetTemperature temperatureCelsius.
         * @member {number} temperatureCelsius
         * @memberof Response.GetTemperature
         * @instance
         */
        GetTemperature.prototype.temperatureCelsius = 0;

        /**
         * Creates a new GetTemperature instance using the specified properties.
         * @function create
         * @memberof Response.GetTemperature
         * @static
         * @param {Response.IGetTemperature=} [properties] Properties to set
         * @returns {Response.GetTemperature} GetTemperature instance
         */
        GetTemperature.create = function create(properties) {
            return new GetTemperature(properties);
        };

        /**
         * Encodes the specified GetTemperature message. Does not implicitly {@link Response.GetTemperature.verify|verify} messages.
         * @function encode
         * @memberof Response.GetTemperature
         * @static
         * @param {Response.IGetTemperature} message GetTemperature message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTemperature.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.temperatureCelsius != null && Object.hasOwnProperty.call(message, "temperatureCelsius"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.temperatureCelsius);
            return writer;
        };

        /**
         * Encodes the specified GetTemperature message, length delimited. Does not implicitly {@link Response.GetTemperature.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.GetTemperature
         * @static
         * @param {Response.IGetTemperature} message GetTemperature message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTemperature.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTemperature message from the specified reader or buffer.
         * @function decode
         * @memberof Response.GetTemperature
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.GetTemperature} GetTemperature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTemperature.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.GetTemperature();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.temperatureCelsius = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTemperature message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.GetTemperature
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.GetTemperature} GetTemperature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTemperature.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTemperature message.
         * @function verify
         * @memberof Response.GetTemperature
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTemperature.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.temperatureCelsius != null && message.hasOwnProperty("temperatureCelsius"))
                if (typeof message.temperatureCelsius !== "number")
                    return "temperatureCelsius: number expected";
            return null;
        };

        /**
         * Creates a GetTemperature message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.GetTemperature
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.GetTemperature} GetTemperature
         */
        GetTemperature.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.GetTemperature)
                return object;
            let message = new $root.Response.GetTemperature();
            if (object.temperatureCelsius != null)
                message.temperatureCelsius = Number(object.temperatureCelsius);
            return message;
        };

        /**
         * Creates a plain object from a GetTemperature message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.GetTemperature
         * @static
         * @param {Response.GetTemperature} message GetTemperature
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTemperature.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.temperatureCelsius = 0;
            if (message.temperatureCelsius != null && message.hasOwnProperty("temperatureCelsius"))
                object.temperatureCelsius = options.json && !isFinite(message.temperatureCelsius) ? String(message.temperatureCelsius) : message.temperatureCelsius;
            return object;
        };

        /**
         * Converts this GetTemperature to JSON.
         * @function toJSON
         * @memberof Response.GetTemperature
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTemperature.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetTemperature;
    })();

    Response.GetNonce = (function() {

        /**
         * Properties of a GetNonce.
         * @memberof Response
         * @interface IGetNonce
         * @property {Uint8Array|null} [nonce] GetNonce nonce
         */

        /**
         * Constructs a new GetNonce.
         * @memberof Response
         * @classdesc Represents a GetNonce.
         * @implements IGetNonce
         * @constructor
         * @param {Response.IGetNonce=} [properties] Properties to set
         */
        function GetNonce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetNonce nonce.
         * @member {Uint8Array} nonce
         * @memberof Response.GetNonce
         * @instance
         */
        GetNonce.prototype.nonce = $util.newBuffer([]);

        /**
         * Creates a new GetNonce instance using the specified properties.
         * @function create
         * @memberof Response.GetNonce
         * @static
         * @param {Response.IGetNonce=} [properties] Properties to set
         * @returns {Response.GetNonce} GetNonce instance
         */
        GetNonce.create = function create(properties) {
            return new GetNonce(properties);
        };

        /**
         * Encodes the specified GetNonce message. Does not implicitly {@link Response.GetNonce.verify|verify} messages.
         * @function encode
         * @memberof Response.GetNonce
         * @static
         * @param {Response.IGetNonce} message GetNonce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetNonce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nonce != null && Object.hasOwnProperty.call(message, "nonce"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.nonce);
            return writer;
        };

        /**
         * Encodes the specified GetNonce message, length delimited. Does not implicitly {@link Response.GetNonce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.GetNonce
         * @static
         * @param {Response.IGetNonce} message GetNonce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetNonce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetNonce message from the specified reader or buffer.
         * @function decode
         * @memberof Response.GetNonce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.GetNonce} GetNonce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetNonce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.GetNonce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.nonce = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetNonce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.GetNonce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.GetNonce} GetNonce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetNonce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetNonce message.
         * @function verify
         * @memberof Response.GetNonce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetNonce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                if (!(message.nonce && typeof message.nonce.length === "number" || $util.isString(message.nonce)))
                    return "nonce: buffer expected";
            return null;
        };

        /**
         * Creates a GetNonce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.GetNonce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.GetNonce} GetNonce
         */
        GetNonce.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.GetNonce)
                return object;
            let message = new $root.Response.GetNonce();
            if (object.nonce != null)
                if (typeof object.nonce === "string")
                    $util.base64.decode(object.nonce, message.nonce = $util.newBuffer($util.base64.length(object.nonce)), 0);
                else if (object.nonce.length)
                    message.nonce = object.nonce;
            return message;
        };

        /**
         * Creates a plain object from a GetNonce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.GetNonce
         * @static
         * @param {Response.GetNonce} message GetNonce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetNonce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.nonce = "";
                else {
                    object.nonce = [];
                    if (options.bytes !== Array)
                        object.nonce = $util.newBuffer(object.nonce);
                }
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                object.nonce = options.bytes === String ? $util.base64.encode(message.nonce, 0, message.nonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.nonce) : message.nonce;
            return object;
        };

        /**
         * Converts this GetNonce to JSON.
         * @function toJSON
         * @memberof Response.GetNonce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetNonce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetNonce;
    })();

    Response.GetMode = (function() {

        /**
         * Properties of a GetMode.
         * @memberof Response
         * @interface IGetMode
         * @property {Mode|null} [mode] GetMode mode
         */

        /**
         * Constructs a new GetMode.
         * @memberof Response
         * @classdesc Represents a GetMode.
         * @implements IGetMode
         * @constructor
         * @param {Response.IGetMode=} [properties] Properties to set
         */
        function GetMode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMode mode.
         * @member {Mode} mode
         * @memberof Response.GetMode
         * @instance
         */
        GetMode.prototype.mode = 0;

        /**
         * Creates a new GetMode instance using the specified properties.
         * @function create
         * @memberof Response.GetMode
         * @static
         * @param {Response.IGetMode=} [properties] Properties to set
         * @returns {Response.GetMode} GetMode instance
         */
        GetMode.create = function create(properties) {
            return new GetMode(properties);
        };

        /**
         * Encodes the specified GetMode message. Does not implicitly {@link Response.GetMode.verify|verify} messages.
         * @function encode
         * @memberof Response.GetMode
         * @static
         * @param {Response.IGetMode} message GetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mode != null && Object.hasOwnProperty.call(message, "mode"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.mode);
            return writer;
        };

        /**
         * Encodes the specified GetMode message, length delimited. Does not implicitly {@link Response.GetMode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.GetMode
         * @static
         * @param {Response.IGetMode} message GetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMode message from the specified reader or buffer.
         * @function decode
         * @memberof Response.GetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.GetMode} GetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.GetMode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.mode = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.GetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.GetMode} GetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMode message.
         * @function verify
         * @memberof Response.GetMode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mode != null && message.hasOwnProperty("mode"))
                switch (message.mode) {
                default:
                    return "mode: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            return null;
        };

        /**
         * Creates a GetMode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.GetMode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.GetMode} GetMode
         */
        GetMode.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.GetMode)
                return object;
            let message = new $root.Response.GetMode();
            switch (object.mode) {
            case "MODE_OFF":
            case 0:
                message.mode = 0;
                break;
            case "MODE_IDLE":
            case 1:
                message.mode = 1;
                break;
            case "MODE_ACTIVE_SIGNING":
            case 2:
                message.mode = 2;
                break;
            case "MODE_ACTIVE_NO_SIGNING":
            case 3:
                message.mode = 3;
                break;
            case "MODE_PASSIVE_SIGNING":
            case 4:
                message.mode = 4;
                break;
            case "MODE_PASSIVE_NO_SIGNING":
            case 5:
                message.mode = 5;
                break;
            case "MODE_FACTORY_TEST":
            case 6:
                message.mode = 6;
                break;
            case "MODE_DEMO":
            case 7:
                message.mode = 7;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a GetMode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.GetMode
         * @static
         * @param {Response.GetMode} message GetMode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.mode = options.enums === String ? "MODE_OFF" : 0;
            if (message.mode != null && message.hasOwnProperty("mode"))
                object.mode = options.enums === String ? $root.Mode[message.mode] : message.mode;
            return object;
        };

        /**
         * Converts this GetMode to JSON.
         * @function toJSON
         * @memberof Response.GetMode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetMode;
    })();

    Response.GetPlaylist = (function() {

        /**
         * Properties of a GetPlaylist.
         * @memberof Response
         * @interface IGetPlaylist
         * @property {Array.<IPlaystate>|null} [playstates] GetPlaylist playstates
         */

        /**
         * Constructs a new GetPlaylist.
         * @memberof Response
         * @classdesc Represents a GetPlaylist.
         * @implements IGetPlaylist
         * @constructor
         * @param {Response.IGetPlaylist=} [properties] Properties to set
         */
        function GetPlaylist(properties) {
            this.playstates = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPlaylist playstates.
         * @member {Array.<IPlaystate>} playstates
         * @memberof Response.GetPlaylist
         * @instance
         */
        GetPlaylist.prototype.playstates = $util.emptyArray;

        /**
         * Creates a new GetPlaylist instance using the specified properties.
         * @function create
         * @memberof Response.GetPlaylist
         * @static
         * @param {Response.IGetPlaylist=} [properties] Properties to set
         * @returns {Response.GetPlaylist} GetPlaylist instance
         */
        GetPlaylist.create = function create(properties) {
            return new GetPlaylist(properties);
        };

        /**
         * Encodes the specified GetPlaylist message. Does not implicitly {@link Response.GetPlaylist.verify|verify} messages.
         * @function encode
         * @memberof Response.GetPlaylist
         * @static
         * @param {Response.IGetPlaylist} message GetPlaylist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPlaylist.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.playstates != null && message.playstates.length)
                for (let i = 0; i < message.playstates.length; ++i)
                    $root.Playstate.encode(message.playstates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetPlaylist message, length delimited. Does not implicitly {@link Response.GetPlaylist.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.GetPlaylist
         * @static
         * @param {Response.IGetPlaylist} message GetPlaylist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPlaylist.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetPlaylist message from the specified reader or buffer.
         * @function decode
         * @memberof Response.GetPlaylist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.GetPlaylist} GetPlaylist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPlaylist.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.GetPlaylist();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.playstates && message.playstates.length))
                        message.playstates = [];
                    message.playstates.push($root.Playstate.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetPlaylist message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.GetPlaylist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.GetPlaylist} GetPlaylist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPlaylist.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetPlaylist message.
         * @function verify
         * @memberof Response.GetPlaylist
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetPlaylist.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.playstates != null && message.hasOwnProperty("playstates")) {
                if (!Array.isArray(message.playstates))
                    return "playstates: array expected";
                for (let i = 0; i < message.playstates.length; ++i) {
                    let error = $root.Playstate.verify(message.playstates[i]);
                    if (error)
                        return "playstates." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetPlaylist message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.GetPlaylist
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.GetPlaylist} GetPlaylist
         */
        GetPlaylist.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.GetPlaylist)
                return object;
            let message = new $root.Response.GetPlaylist();
            if (object.playstates) {
                if (!Array.isArray(object.playstates))
                    throw TypeError(".Response.GetPlaylist.playstates: array expected");
                message.playstates = [];
                for (let i = 0; i < object.playstates.length; ++i) {
                    if (typeof object.playstates[i] !== "object")
                        throw TypeError(".Response.GetPlaylist.playstates: object expected");
                    message.playstates[i] = $root.Playstate.fromObject(object.playstates[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetPlaylist message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.GetPlaylist
         * @static
         * @param {Response.GetPlaylist} message GetPlaylist
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetPlaylist.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.playstates = [];
            if (message.playstates && message.playstates.length) {
                object.playstates = [];
                for (let j = 0; j < message.playstates.length; ++j)
                    object.playstates[j] = $root.Playstate.toObject(message.playstates[j], options);
            }
            return object;
        };

        /**
         * Converts this GetPlaylist to JSON.
         * @function toJSON
         * @memberof Response.GetPlaylist
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetPlaylist.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetPlaylist;
    })();

    Response.GetPlaystate = (function() {

        /**
         * Properties of a GetPlaystate.
         * @memberof Response
         * @interface IGetPlaystate
         * @property {IPlaystate|null} [playstate] GetPlaystate playstate
         */

        /**
         * Constructs a new GetPlaystate.
         * @memberof Response
         * @classdesc Represents a GetPlaystate.
         * @implements IGetPlaystate
         * @constructor
         * @param {Response.IGetPlaystate=} [properties] Properties to set
         */
        function GetPlaystate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPlaystate playstate.
         * @member {IPlaystate|null|undefined} playstate
         * @memberof Response.GetPlaystate
         * @instance
         */
        GetPlaystate.prototype.playstate = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GetPlaystate _playstate.
         * @member {"playstate"|undefined} _playstate
         * @memberof Response.GetPlaystate
         * @instance
         */
        Object.defineProperty(GetPlaystate.prototype, "_playstate", {
            get: $util.oneOfGetter($oneOfFields = ["playstate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetPlaystate instance using the specified properties.
         * @function create
         * @memberof Response.GetPlaystate
         * @static
         * @param {Response.IGetPlaystate=} [properties] Properties to set
         * @returns {Response.GetPlaystate} GetPlaystate instance
         */
        GetPlaystate.create = function create(properties) {
            return new GetPlaystate(properties);
        };

        /**
         * Encodes the specified GetPlaystate message. Does not implicitly {@link Response.GetPlaystate.verify|verify} messages.
         * @function encode
         * @memberof Response.GetPlaystate
         * @static
         * @param {Response.IGetPlaystate} message GetPlaystate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPlaystate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.playstate != null && Object.hasOwnProperty.call(message, "playstate"))
                $root.Playstate.encode(message.playstate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetPlaystate message, length delimited. Does not implicitly {@link Response.GetPlaystate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Response.GetPlaystate
         * @static
         * @param {Response.IGetPlaystate} message GetPlaystate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPlaystate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetPlaystate message from the specified reader or buffer.
         * @function decode
         * @memberof Response.GetPlaystate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Response.GetPlaystate} GetPlaystate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPlaystate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Response.GetPlaystate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.playstate = $root.Playstate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetPlaystate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Response.GetPlaystate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Response.GetPlaystate} GetPlaystate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPlaystate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetPlaystate message.
         * @function verify
         * @memberof Response.GetPlaystate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetPlaystate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.playstate != null && message.hasOwnProperty("playstate")) {
                properties._playstate = 1;
                {
                    let error = $root.Playstate.verify(message.playstate);
                    if (error)
                        return "playstate." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetPlaystate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Response.GetPlaystate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Response.GetPlaystate} GetPlaystate
         */
        GetPlaystate.fromObject = function fromObject(object) {
            if (object instanceof $root.Response.GetPlaystate)
                return object;
            let message = new $root.Response.GetPlaystate();
            if (object.playstate != null) {
                if (typeof object.playstate !== "object")
                    throw TypeError(".Response.GetPlaystate.playstate: object expected");
                message.playstate = $root.Playstate.fromObject(object.playstate);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetPlaystate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Response.GetPlaystate
         * @static
         * @param {Response.GetPlaystate} message GetPlaystate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetPlaystate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.playstate != null && message.hasOwnProperty("playstate")) {
                object.playstate = $root.Playstate.toObject(message.playstate, options);
                if (options.oneofs)
                    object._playstate = "playstate";
            }
            return object;
        };

        /**
         * Converts this GetPlaystate to JSON.
         * @function toJSON
         * @memberof Response.GetPlaystate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetPlaystate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetPlaystate;
    })();

    return Response;
})();

export const OOB = $root.OOB = (() => {

    /**
     * Properties of a OOB.
     * @exports IOOB
     * @interface IOOB
     * @property {OOB.IAppReady|null} [appReady] OOB appReady
     * @property {OOB.IProtocolError|null} [protocolError] OOB protocolError
     * @property {OOB.IHeartbeatMetrics|null} [heartbeatMetrics] OOB heartbeatMetrics
     * @property {OOB.ICrashMetrics|null} [crashMetrics] OOB crashMetrics
     * @property {OOB.ICatchEvent|null} [catchEvent] OOB catchEvent
     * @property {OOB.IRebooting|null} [rebooting] OOB rebooting
     */

    /**
     * Constructs a new OOB.
     * @exports OOB
     * @classdesc Out-of-band messages that are sent asynchronously by the device without any
     * prior request sent by the client.
     * @implements IOOB
     * @constructor
     * @param {IOOB=} [properties] Properties to set
     */
    function OOB(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * OOB appReady.
     * @member {OOB.IAppReady|null|undefined} appReady
     * @memberof OOB
     * @instance
     */
    OOB.prototype.appReady = null;

    /**
     * OOB protocolError.
     * @member {OOB.IProtocolError|null|undefined} protocolError
     * @memberof OOB
     * @instance
     */
    OOB.prototype.protocolError = null;

    /**
     * OOB heartbeatMetrics.
     * @member {OOB.IHeartbeatMetrics|null|undefined} heartbeatMetrics
     * @memberof OOB
     * @instance
     */
    OOB.prototype.heartbeatMetrics = null;

    /**
     * OOB crashMetrics.
     * @member {OOB.ICrashMetrics|null|undefined} crashMetrics
     * @memberof OOB
     * @instance
     */
    OOB.prototype.crashMetrics = null;

    /**
     * OOB catchEvent.
     * @member {OOB.ICatchEvent|null|undefined} catchEvent
     * @memberof OOB
     * @instance
     */
    OOB.prototype.catchEvent = null;

    /**
     * OOB rebooting.
     * @member {OOB.IRebooting|null|undefined} rebooting
     * @memberof OOB
     * @instance
     */
    OOB.prototype.rebooting = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * Out-of-band message.
     * @member {"appReady"|"protocolError"|"heartbeatMetrics"|"crashMetrics"|"catchEvent"|"rebooting"|undefined} msg
     * @memberof OOB
     * @instance
     */
    Object.defineProperty(OOB.prototype, "msg", {
        get: $util.oneOfGetter($oneOfFields = ["appReady", "protocolError", "heartbeatMetrics", "crashMetrics", "catchEvent", "rebooting"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new OOB instance using the specified properties.
     * @function create
     * @memberof OOB
     * @static
     * @param {IOOB=} [properties] Properties to set
     * @returns {OOB} OOB instance
     */
    OOB.create = function create(properties) {
        return new OOB(properties);
    };

    /**
     * Encodes the specified OOB message. Does not implicitly {@link OOB.verify|verify} messages.
     * @function encode
     * @memberof OOB
     * @static
     * @param {IOOB} message OOB message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OOB.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.appReady != null && Object.hasOwnProperty.call(message, "appReady"))
            $root.OOB.AppReady.encode(message.appReady, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.protocolError != null && Object.hasOwnProperty.call(message, "protocolError"))
            $root.OOB.ProtocolError.encode(message.protocolError, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.heartbeatMetrics != null && Object.hasOwnProperty.call(message, "heartbeatMetrics"))
            $root.OOB.HeartbeatMetrics.encode(message.heartbeatMetrics, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.crashMetrics != null && Object.hasOwnProperty.call(message, "crashMetrics"))
            $root.OOB.CrashMetrics.encode(message.crashMetrics, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.catchEvent != null && Object.hasOwnProperty.call(message, "catchEvent"))
            $root.OOB.CatchEvent.encode(message.catchEvent, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.rebooting != null && Object.hasOwnProperty.call(message, "rebooting"))
            $root.OOB.Rebooting.encode(message.rebooting, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified OOB message, length delimited. Does not implicitly {@link OOB.verify|verify} messages.
     * @function encodeDelimited
     * @memberof OOB
     * @static
     * @param {IOOB} message OOB message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OOB.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a OOB message from the specified reader or buffer.
     * @function decode
     * @memberof OOB
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {OOB} OOB
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OOB.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.OOB();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.appReady = $root.OOB.AppReady.decode(reader, reader.uint32());
                break;
            case 2:
                message.protocolError = $root.OOB.ProtocolError.decode(reader, reader.uint32());
                break;
            case 5:
                message.heartbeatMetrics = $root.OOB.HeartbeatMetrics.decode(reader, reader.uint32());
                break;
            case 6:
                message.crashMetrics = $root.OOB.CrashMetrics.decode(reader, reader.uint32());
                break;
            case 7:
                message.catchEvent = $root.OOB.CatchEvent.decode(reader, reader.uint32());
                break;
            case 8:
                message.rebooting = $root.OOB.Rebooting.decode(reader, reader.uint32());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a OOB message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof OOB
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {OOB} OOB
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OOB.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a OOB message.
     * @function verify
     * @memberof OOB
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    OOB.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.appReady != null && message.hasOwnProperty("appReady")) {
            properties.msg = 1;
            {
                let error = $root.OOB.AppReady.verify(message.appReady);
                if (error)
                    return "appReady." + error;
            }
        }
        if (message.protocolError != null && message.hasOwnProperty("protocolError")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.OOB.ProtocolError.verify(message.protocolError);
                if (error)
                    return "protocolError." + error;
            }
        }
        if (message.heartbeatMetrics != null && message.hasOwnProperty("heartbeatMetrics")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.OOB.HeartbeatMetrics.verify(message.heartbeatMetrics);
                if (error)
                    return "heartbeatMetrics." + error;
            }
        }
        if (message.crashMetrics != null && message.hasOwnProperty("crashMetrics")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.OOB.CrashMetrics.verify(message.crashMetrics);
                if (error)
                    return "crashMetrics." + error;
            }
        }
        if (message.catchEvent != null && message.hasOwnProperty("catchEvent")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.OOB.CatchEvent.verify(message.catchEvent);
                if (error)
                    return "catchEvent." + error;
            }
        }
        if (message.rebooting != null && message.hasOwnProperty("rebooting")) {
            if (properties.msg === 1)
                return "msg: multiple values";
            properties.msg = 1;
            {
                let error = $root.OOB.Rebooting.verify(message.rebooting);
                if (error)
                    return "rebooting." + error;
            }
        }
        return null;
    };

    /**
     * Creates a OOB message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof OOB
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {OOB} OOB
     */
    OOB.fromObject = function fromObject(object) {
        if (object instanceof $root.OOB)
            return object;
        let message = new $root.OOB();
        if (object.appReady != null) {
            if (typeof object.appReady !== "object")
                throw TypeError(".OOB.appReady: object expected");
            message.appReady = $root.OOB.AppReady.fromObject(object.appReady);
        }
        if (object.protocolError != null) {
            if (typeof object.protocolError !== "object")
                throw TypeError(".OOB.protocolError: object expected");
            message.protocolError = $root.OOB.ProtocolError.fromObject(object.protocolError);
        }
        if (object.heartbeatMetrics != null) {
            if (typeof object.heartbeatMetrics !== "object")
                throw TypeError(".OOB.heartbeatMetrics: object expected");
            message.heartbeatMetrics = $root.OOB.HeartbeatMetrics.fromObject(object.heartbeatMetrics);
        }
        if (object.crashMetrics != null) {
            if (typeof object.crashMetrics !== "object")
                throw TypeError(".OOB.crashMetrics: object expected");
            message.crashMetrics = $root.OOB.CrashMetrics.fromObject(object.crashMetrics);
        }
        if (object.catchEvent != null) {
            if (typeof object.catchEvent !== "object")
                throw TypeError(".OOB.catchEvent: object expected");
            message.catchEvent = $root.OOB.CatchEvent.fromObject(object.catchEvent);
        }
        if (object.rebooting != null) {
            if (typeof object.rebooting !== "object")
                throw TypeError(".OOB.rebooting: object expected");
            message.rebooting = $root.OOB.Rebooting.fromObject(object.rebooting);
        }
        return message;
    };

    /**
     * Creates a plain object from a OOB message. Also converts values to other types if specified.
     * @function toObject
     * @memberof OOB
     * @static
     * @param {OOB} message OOB
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    OOB.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (message.appReady != null && message.hasOwnProperty("appReady")) {
            object.appReady = $root.OOB.AppReady.toObject(message.appReady, options);
            if (options.oneofs)
                object.msg = "appReady";
        }
        if (message.protocolError != null && message.hasOwnProperty("protocolError")) {
            object.protocolError = $root.OOB.ProtocolError.toObject(message.protocolError, options);
            if (options.oneofs)
                object.msg = "protocolError";
        }
        if (message.heartbeatMetrics != null && message.hasOwnProperty("heartbeatMetrics")) {
            object.heartbeatMetrics = $root.OOB.HeartbeatMetrics.toObject(message.heartbeatMetrics, options);
            if (options.oneofs)
                object.msg = "heartbeatMetrics";
        }
        if (message.crashMetrics != null && message.hasOwnProperty("crashMetrics")) {
            object.crashMetrics = $root.OOB.CrashMetrics.toObject(message.crashMetrics, options);
            if (options.oneofs)
                object.msg = "crashMetrics";
        }
        if (message.catchEvent != null && message.hasOwnProperty("catchEvent")) {
            object.catchEvent = $root.OOB.CatchEvent.toObject(message.catchEvent, options);
            if (options.oneofs)
                object.msg = "catchEvent";
        }
        if (message.rebooting != null && message.hasOwnProperty("rebooting")) {
            object.rebooting = $root.OOB.Rebooting.toObject(message.rebooting, options);
            if (options.oneofs)
                object.msg = "rebooting";
        }
        return object;
    };

    /**
     * Converts this OOB to JSON.
     * @function toJSON
     * @memberof OOB
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    OOB.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    OOB.Rebooting = (function() {

        /**
         * Properties of a Rebooting.
         * @memberof OOB
         * @interface IRebooting
         */

        /**
         * Constructs a new Rebooting.
         * @memberof OOB
         * @classdesc Device is rebooting.
         * @implements IRebooting
         * @constructor
         * @param {OOB.IRebooting=} [properties] Properties to set
         */
        function Rebooting(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Rebooting instance using the specified properties.
         * @function create
         * @memberof OOB.Rebooting
         * @static
         * @param {OOB.IRebooting=} [properties] Properties to set
         * @returns {OOB.Rebooting} Rebooting instance
         */
        Rebooting.create = function create(properties) {
            return new Rebooting(properties);
        };

        /**
         * Encodes the specified Rebooting message. Does not implicitly {@link OOB.Rebooting.verify|verify} messages.
         * @function encode
         * @memberof OOB.Rebooting
         * @static
         * @param {OOB.IRebooting} message Rebooting message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Rebooting.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified Rebooting message, length delimited. Does not implicitly {@link OOB.Rebooting.verify|verify} messages.
         * @function encodeDelimited
         * @memberof OOB.Rebooting
         * @static
         * @param {OOB.IRebooting} message Rebooting message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Rebooting.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Rebooting message from the specified reader or buffer.
         * @function decode
         * @memberof OOB.Rebooting
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {OOB.Rebooting} Rebooting
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Rebooting.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.OOB.Rebooting();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Rebooting message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof OOB.Rebooting
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {OOB.Rebooting} Rebooting
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Rebooting.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Rebooting message.
         * @function verify
         * @memberof OOB.Rebooting
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Rebooting.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a Rebooting message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof OOB.Rebooting
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {OOB.Rebooting} Rebooting
         */
        Rebooting.fromObject = function fromObject(object) {
            if (object instanceof $root.OOB.Rebooting)
                return object;
            return new $root.OOB.Rebooting();
        };

        /**
         * Creates a plain object from a Rebooting message. Also converts values to other types if specified.
         * @function toObject
         * @memberof OOB.Rebooting
         * @static
         * @param {OOB.Rebooting} message Rebooting
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Rebooting.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Rebooting to JSON.
         * @function toJSON
         * @memberof OOB.Rebooting
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Rebooting.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Rebooting;
    })();

    OOB.AppReady = (function() {

        /**
         * Properties of an AppReady.
         * @memberof OOB
         * @interface IAppReady
         * @property {number|null} [resetReason] AppReady resetReason
         */

        /**
         * Constructs a new AppReady.
         * @memberof OOB
         * @classdesc Application ready message sent by the device on successful boot
         * to signal that it is now ready to accept requests from the client.
         * @implements IAppReady
         * @constructor
         * @param {OOB.IAppReady=} [properties] Properties to set
         */
        function AppReady(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppReady resetReason.
         * @member {number} resetReason
         * @memberof OOB.AppReady
         * @instance
         */
        AppReady.prototype.resetReason = 0;

        /**
         * Creates a new AppReady instance using the specified properties.
         * @function create
         * @memberof OOB.AppReady
         * @static
         * @param {OOB.IAppReady=} [properties] Properties to set
         * @returns {OOB.AppReady} AppReady instance
         */
        AppReady.create = function create(properties) {
            return new AppReady(properties);
        };

        /**
         * Encodes the specified AppReady message. Does not implicitly {@link OOB.AppReady.verify|verify} messages.
         * @function encode
         * @memberof OOB.AppReady
         * @static
         * @param {OOB.IAppReady} message AppReady message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppReady.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.resetReason != null && Object.hasOwnProperty.call(message, "resetReason"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.resetReason);
            return writer;
        };

        /**
         * Encodes the specified AppReady message, length delimited. Does not implicitly {@link OOB.AppReady.verify|verify} messages.
         * @function encodeDelimited
         * @memberof OOB.AppReady
         * @static
         * @param {OOB.IAppReady} message AppReady message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppReady.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppReady message from the specified reader or buffer.
         * @function decode
         * @memberof OOB.AppReady
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {OOB.AppReady} AppReady
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppReady.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.OOB.AppReady();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.resetReason = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppReady message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof OOB.AppReady
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {OOB.AppReady} AppReady
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppReady.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppReady message.
         * @function verify
         * @memberof OOB.AppReady
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppReady.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.resetReason != null && message.hasOwnProperty("resetReason"))
                if (!$util.isInteger(message.resetReason))
                    return "resetReason: integer expected";
            return null;
        };

        /**
         * Creates an AppReady message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof OOB.AppReady
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {OOB.AppReady} AppReady
         */
        AppReady.fromObject = function fromObject(object) {
            if (object instanceof $root.OOB.AppReady)
                return object;
            let message = new $root.OOB.AppReady();
            if (object.resetReason != null)
                message.resetReason = object.resetReason >>> 0;
            return message;
        };

        /**
         * Creates a plain object from an AppReady message. Also converts values to other types if specified.
         * @function toObject
         * @memberof OOB.AppReady
         * @static
         * @param {OOB.AppReady} message AppReady
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppReady.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.resetReason = 0;
            if (message.resetReason != null && message.hasOwnProperty("resetReason"))
                object.resetReason = message.resetReason;
            return object;
        };

        /**
         * Converts this AppReady to JSON.
         * @function toJSON
         * @memberof OOB.AppReady
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppReady.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppReady;
    })();

    OOB.ProtocolError = (function() {

        /**
         * Properties of a ProtocolError.
         * @memberof OOB
         * @interface IProtocolError
         * @property {StatusCode|null} [status] ProtocolError status
         */

        /**
         * Constructs a new ProtocolError.
         * @memberof OOB
         * @classdesc This message is sent by the device when a frame does not adhere to the control.proto
         * protocol. Serial protocol level error.
         * @implements IProtocolError
         * @constructor
         * @param {OOB.IProtocolError=} [properties] Properties to set
         */
        function ProtocolError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProtocolError status.
         * @member {StatusCode} status
         * @memberof OOB.ProtocolError
         * @instance
         */
        ProtocolError.prototype.status = 0;

        /**
         * Creates a new ProtocolError instance using the specified properties.
         * @function create
         * @memberof OOB.ProtocolError
         * @static
         * @param {OOB.IProtocolError=} [properties] Properties to set
         * @returns {OOB.ProtocolError} ProtocolError instance
         */
        ProtocolError.create = function create(properties) {
            return new ProtocolError(properties);
        };

        /**
         * Encodes the specified ProtocolError message. Does not implicitly {@link OOB.ProtocolError.verify|verify} messages.
         * @function encode
         * @memberof OOB.ProtocolError
         * @static
         * @param {OOB.IProtocolError} message ProtocolError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProtocolError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified ProtocolError message, length delimited. Does not implicitly {@link OOB.ProtocolError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof OOB.ProtocolError
         * @static
         * @param {OOB.IProtocolError} message ProtocolError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProtocolError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProtocolError message from the specified reader or buffer.
         * @function decode
         * @memberof OOB.ProtocolError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {OOB.ProtocolError} ProtocolError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProtocolError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.OOB.ProtocolError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProtocolError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof OOB.ProtocolError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {OOB.ProtocolError} ProtocolError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProtocolError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProtocolError message.
         * @function verify
         * @memberof OOB.ProtocolError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProtocolError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                    break;
                }
            return null;
        };

        /**
         * Creates a ProtocolError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof OOB.ProtocolError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {OOB.ProtocolError} ProtocolError
         */
        ProtocolError.fromObject = function fromObject(object) {
            if (object instanceof $root.OOB.ProtocolError)
                return object;
            let message = new $root.OOB.ProtocolError();
            switch (object.status) {
            case "STATUS_SUCCESS":
            case 0:
                message.status = 0;
                break;
            case "STATUS_ERROR_NO_MEM":
            case 1:
                message.status = 1;
                break;
            case "STATUS_ERROR_NOT_FOUND":
            case 2:
                message.status = 2;
                break;
            case "STATUS_ERROR_NOT_SUPPORTED":
            case 3:
                message.status = 3;
                break;
            case "STATUS_ERROR_NOT_AUTHORIZED":
            case 4:
                message.status = 4;
                break;
            case "STATUS_ERROR_INVALID_PARAM":
            case 5:
                message.status = 5;
                break;
            case "STATUS_ERROR_INVALID_STATE":
            case 6:
                message.status = 6;
                break;
            case "STATUS_ERROR_INVALID_LENGTH":
            case 7:
                message.status = 7;
                break;
            case "STATUS_ERROR_INVALID_DATA":
            case 8:
                message.status = 8;
                break;
            case "STATUS_ERROR_TIMEOUT":
            case 9:
                message.status = 9;
                break;
            case "STATUS_ERROR_BUSY":
            case 10:
                message.status = 10;
                break;
            case "STATUS_ERROR_IO":
            case 11:
                message.status = 11;
                break;
            case "STATUS_ERROR_INTERNAL":
            case 12:
                message.status = 12;
                break;
            case "STATUS_ERROR_LINK":
            case 13:
                message.status = 13;
                break;
            case "STATUS_ERROR_FRAMING":
            case 14:
                message.status = 14;
                break;
            case "STATUS_ERROR_DECODING":
            case 15:
                message.status = 15;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ProtocolError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof OOB.ProtocolError
         * @static
         * @param {OOB.ProtocolError} message ProtocolError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProtocolError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "STATUS_SUCCESS" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.StatusCode[message.status] : message.status;
            return object;
        };

        /**
         * Converts this ProtocolError to JSON.
         * @function toJSON
         * @memberof OOB.ProtocolError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProtocolError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProtocolError;
    })();

    OOB.HeartbeatMetrics = (function() {

        /**
         * Properties of a HeartbeatMetrics.
         * @memberof OOB
         * @interface IHeartbeatMetrics
         * @property {Uint8Array|null} [metrics] HeartbeatMetrics metrics
         */

        /**
         * Constructs a new HeartbeatMetrics.
         * @memberof OOB
         * @classdesc Device heartbeat metrics.
         * @implements IHeartbeatMetrics
         * @constructor
         * @param {OOB.IHeartbeatMetrics=} [properties] Properties to set
         */
        function HeartbeatMetrics(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HeartbeatMetrics metrics.
         * @member {Uint8Array} metrics
         * @memberof OOB.HeartbeatMetrics
         * @instance
         */
        HeartbeatMetrics.prototype.metrics = $util.newBuffer([]);

        /**
         * Creates a new HeartbeatMetrics instance using the specified properties.
         * @function create
         * @memberof OOB.HeartbeatMetrics
         * @static
         * @param {OOB.IHeartbeatMetrics=} [properties] Properties to set
         * @returns {OOB.HeartbeatMetrics} HeartbeatMetrics instance
         */
        HeartbeatMetrics.create = function create(properties) {
            return new HeartbeatMetrics(properties);
        };

        /**
         * Encodes the specified HeartbeatMetrics message. Does not implicitly {@link OOB.HeartbeatMetrics.verify|verify} messages.
         * @function encode
         * @memberof OOB.HeartbeatMetrics
         * @static
         * @param {OOB.IHeartbeatMetrics} message HeartbeatMetrics message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeartbeatMetrics.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.metrics != null && Object.hasOwnProperty.call(message, "metrics"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.metrics);
            return writer;
        };

        /**
         * Encodes the specified HeartbeatMetrics message, length delimited. Does not implicitly {@link OOB.HeartbeatMetrics.verify|verify} messages.
         * @function encodeDelimited
         * @memberof OOB.HeartbeatMetrics
         * @static
         * @param {OOB.IHeartbeatMetrics} message HeartbeatMetrics message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeartbeatMetrics.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HeartbeatMetrics message from the specified reader or buffer.
         * @function decode
         * @memberof OOB.HeartbeatMetrics
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {OOB.HeartbeatMetrics} HeartbeatMetrics
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeartbeatMetrics.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.OOB.HeartbeatMetrics();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.metrics = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HeartbeatMetrics message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof OOB.HeartbeatMetrics
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {OOB.HeartbeatMetrics} HeartbeatMetrics
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeartbeatMetrics.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HeartbeatMetrics message.
         * @function verify
         * @memberof OOB.HeartbeatMetrics
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HeartbeatMetrics.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.metrics != null && message.hasOwnProperty("metrics"))
                if (!(message.metrics && typeof message.metrics.length === "number" || $util.isString(message.metrics)))
                    return "metrics: buffer expected";
            return null;
        };

        /**
         * Creates a HeartbeatMetrics message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof OOB.HeartbeatMetrics
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {OOB.HeartbeatMetrics} HeartbeatMetrics
         */
        HeartbeatMetrics.fromObject = function fromObject(object) {
            if (object instanceof $root.OOB.HeartbeatMetrics)
                return object;
            let message = new $root.OOB.HeartbeatMetrics();
            if (object.metrics != null)
                if (typeof object.metrics === "string")
                    $util.base64.decode(object.metrics, message.metrics = $util.newBuffer($util.base64.length(object.metrics)), 0);
                else if (object.metrics.length)
                    message.metrics = object.metrics;
            return message;
        };

        /**
         * Creates a plain object from a HeartbeatMetrics message. Also converts values to other types if specified.
         * @function toObject
         * @memberof OOB.HeartbeatMetrics
         * @static
         * @param {OOB.HeartbeatMetrics} message HeartbeatMetrics
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HeartbeatMetrics.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.metrics = "";
                else {
                    object.metrics = [];
                    if (options.bytes !== Array)
                        object.metrics = $util.newBuffer(object.metrics);
                }
            if (message.metrics != null && message.hasOwnProperty("metrics"))
                object.metrics = options.bytes === String ? $util.base64.encode(message.metrics, 0, message.metrics.length) : options.bytes === Array ? Array.prototype.slice.call(message.metrics) : message.metrics;
            return object;
        };

        /**
         * Converts this HeartbeatMetrics to JSON.
         * @function toJSON
         * @memberof OOB.HeartbeatMetrics
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HeartbeatMetrics.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HeartbeatMetrics;
    })();

    OOB.CrashMetrics = (function() {

        /**
         * Properties of a CrashMetrics.
         * @memberof OOB
         * @interface ICrashMetrics
         * @property {Uint8Array|null} [metrics] CrashMetrics metrics
         */

        /**
         * Constructs a new CrashMetrics.
         * @memberof OOB
         * @classdesc Device crash metrics.
         * @implements ICrashMetrics
         * @constructor
         * @param {OOB.ICrashMetrics=} [properties] Properties to set
         */
        function CrashMetrics(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CrashMetrics metrics.
         * @member {Uint8Array} metrics
         * @memberof OOB.CrashMetrics
         * @instance
         */
        CrashMetrics.prototype.metrics = $util.newBuffer([]);

        /**
         * Creates a new CrashMetrics instance using the specified properties.
         * @function create
         * @memberof OOB.CrashMetrics
         * @static
         * @param {OOB.ICrashMetrics=} [properties] Properties to set
         * @returns {OOB.CrashMetrics} CrashMetrics instance
         */
        CrashMetrics.create = function create(properties) {
            return new CrashMetrics(properties);
        };

        /**
         * Encodes the specified CrashMetrics message. Does not implicitly {@link OOB.CrashMetrics.verify|verify} messages.
         * @function encode
         * @memberof OOB.CrashMetrics
         * @static
         * @param {OOB.ICrashMetrics} message CrashMetrics message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CrashMetrics.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.metrics != null && Object.hasOwnProperty.call(message, "metrics"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.metrics);
            return writer;
        };

        /**
         * Encodes the specified CrashMetrics message, length delimited. Does not implicitly {@link OOB.CrashMetrics.verify|verify} messages.
         * @function encodeDelimited
         * @memberof OOB.CrashMetrics
         * @static
         * @param {OOB.ICrashMetrics} message CrashMetrics message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CrashMetrics.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CrashMetrics message from the specified reader or buffer.
         * @function decode
         * @memberof OOB.CrashMetrics
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {OOB.CrashMetrics} CrashMetrics
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CrashMetrics.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.OOB.CrashMetrics();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.metrics = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CrashMetrics message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof OOB.CrashMetrics
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {OOB.CrashMetrics} CrashMetrics
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CrashMetrics.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CrashMetrics message.
         * @function verify
         * @memberof OOB.CrashMetrics
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CrashMetrics.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.metrics != null && message.hasOwnProperty("metrics"))
                if (!(message.metrics && typeof message.metrics.length === "number" || $util.isString(message.metrics)))
                    return "metrics: buffer expected";
            return null;
        };

        /**
         * Creates a CrashMetrics message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof OOB.CrashMetrics
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {OOB.CrashMetrics} CrashMetrics
         */
        CrashMetrics.fromObject = function fromObject(object) {
            if (object instanceof $root.OOB.CrashMetrics)
                return object;
            let message = new $root.OOB.CrashMetrics();
            if (object.metrics != null)
                if (typeof object.metrics === "string")
                    $util.base64.decode(object.metrics, message.metrics = $util.newBuffer($util.base64.length(object.metrics)), 0);
                else if (object.metrics.length)
                    message.metrics = object.metrics;
            return message;
        };

        /**
         * Creates a plain object from a CrashMetrics message. Also converts values to other types if specified.
         * @function toObject
         * @memberof OOB.CrashMetrics
         * @static
         * @param {OOB.CrashMetrics} message CrashMetrics
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CrashMetrics.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.metrics = "";
                else {
                    object.metrics = [];
                    if (options.bytes !== Array)
                        object.metrics = $util.newBuffer(object.metrics);
                }
            if (message.metrics != null && message.hasOwnProperty("metrics"))
                object.metrics = options.bytes === String ? $util.base64.encode(message.metrics, 0, message.metrics.length) : options.bytes === Array ? Array.prototype.slice.call(message.metrics) : message.metrics;
            return object;
        };

        /**
         * Converts this CrashMetrics to JSON.
         * @function toJSON
         * @memberof OOB.CrashMetrics
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CrashMetrics.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CrashMetrics;
    })();

    OOB.CatchEvent = (function() {

        /**
         * Properties of a CatchEvent.
         * @memberof OOB
         * @interface ICatchEvent
         * @property {number|null} [catchableId] CatchEvent catchableId
         * @property {number|Long|null} [systimeMs] CatchEvent systimeMs
         * @property {number|Long|null} [uptimeMs] CatchEvent uptimeMs
         */

        /**
         * Constructs a new CatchEvent.
         * @memberof OOB
         * @classdesc Catch event sent when user makes a successful catch.
         * @implements ICatchEvent
         * @constructor
         * @param {OOB.ICatchEvent=} [properties] Properties to set
         */
        function CatchEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CatchEvent catchableId.
         * @member {number} catchableId
         * @memberof OOB.CatchEvent
         * @instance
         */
        CatchEvent.prototype.catchableId = 0;

        /**
         * CatchEvent systimeMs.
         * @member {number|Long} systimeMs
         * @memberof OOB.CatchEvent
         * @instance
         */
        CatchEvent.prototype.systimeMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * CatchEvent uptimeMs.
         * @member {number|Long} uptimeMs
         * @memberof OOB.CatchEvent
         * @instance
         */
        CatchEvent.prototype.uptimeMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new CatchEvent instance using the specified properties.
         * @function create
         * @memberof OOB.CatchEvent
         * @static
         * @param {OOB.ICatchEvent=} [properties] Properties to set
         * @returns {OOB.CatchEvent} CatchEvent instance
         */
        CatchEvent.create = function create(properties) {
            return new CatchEvent(properties);
        };

        /**
         * Encodes the specified CatchEvent message. Does not implicitly {@link OOB.CatchEvent.verify|verify} messages.
         * @function encode
         * @memberof OOB.CatchEvent
         * @static
         * @param {OOB.ICatchEvent} message CatchEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CatchEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.catchableId != null && Object.hasOwnProperty.call(message, "catchableId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.catchableId);
            if (message.systimeMs != null && Object.hasOwnProperty.call(message, "systimeMs"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.systimeMs);
            if (message.uptimeMs != null && Object.hasOwnProperty.call(message, "uptimeMs"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.uptimeMs);
            return writer;
        };

        /**
         * Encodes the specified CatchEvent message, length delimited. Does not implicitly {@link OOB.CatchEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof OOB.CatchEvent
         * @static
         * @param {OOB.ICatchEvent} message CatchEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CatchEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CatchEvent message from the specified reader or buffer.
         * @function decode
         * @memberof OOB.CatchEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {OOB.CatchEvent} CatchEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CatchEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.OOB.CatchEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.catchableId = reader.uint32();
                    break;
                case 2:
                    message.systimeMs = reader.uint64();
                    break;
                case 3:
                    message.uptimeMs = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CatchEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof OOB.CatchEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {OOB.CatchEvent} CatchEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CatchEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CatchEvent message.
         * @function verify
         * @memberof OOB.CatchEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CatchEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.catchableId != null && message.hasOwnProperty("catchableId"))
                if (!$util.isInteger(message.catchableId))
                    return "catchableId: integer expected";
            if (message.systimeMs != null && message.hasOwnProperty("systimeMs"))
                if (!$util.isInteger(message.systimeMs) && !(message.systimeMs && $util.isInteger(message.systimeMs.low) && $util.isInteger(message.systimeMs.high)))
                    return "systimeMs: integer|Long expected";
            if (message.uptimeMs != null && message.hasOwnProperty("uptimeMs"))
                if (!$util.isInteger(message.uptimeMs) && !(message.uptimeMs && $util.isInteger(message.uptimeMs.low) && $util.isInteger(message.uptimeMs.high)))
                    return "uptimeMs: integer|Long expected";
            return null;
        };

        /**
         * Creates a CatchEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof OOB.CatchEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {OOB.CatchEvent} CatchEvent
         */
        CatchEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.OOB.CatchEvent)
                return object;
            let message = new $root.OOB.CatchEvent();
            if (object.catchableId != null)
                message.catchableId = object.catchableId >>> 0;
            if (object.systimeMs != null)
                if ($util.Long)
                    (message.systimeMs = $util.Long.fromValue(object.systimeMs)).unsigned = true;
                else if (typeof object.systimeMs === "string")
                    message.systimeMs = parseInt(object.systimeMs, 10);
                else if (typeof object.systimeMs === "number")
                    message.systimeMs = object.systimeMs;
                else if (typeof object.systimeMs === "object")
                    message.systimeMs = new $util.LongBits(object.systimeMs.low >>> 0, object.systimeMs.high >>> 0).toNumber(true);
            if (object.uptimeMs != null)
                if ($util.Long)
                    (message.uptimeMs = $util.Long.fromValue(object.uptimeMs)).unsigned = true;
                else if (typeof object.uptimeMs === "string")
                    message.uptimeMs = parseInt(object.uptimeMs, 10);
                else if (typeof object.uptimeMs === "number")
                    message.uptimeMs = object.uptimeMs;
                else if (typeof object.uptimeMs === "object")
                    message.uptimeMs = new $util.LongBits(object.uptimeMs.low >>> 0, object.uptimeMs.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a CatchEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof OOB.CatchEvent
         * @static
         * @param {OOB.CatchEvent} message CatchEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CatchEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.catchableId = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.systimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.systimeMs = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.uptimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.uptimeMs = options.longs === String ? "0" : 0;
            }
            if (message.catchableId != null && message.hasOwnProperty("catchableId"))
                object.catchableId = message.catchableId;
            if (message.systimeMs != null && message.hasOwnProperty("systimeMs"))
                if (typeof message.systimeMs === "number")
                    object.systimeMs = options.longs === String ? String(message.systimeMs) : message.systimeMs;
                else
                    object.systimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.systimeMs) : options.longs === Number ? new $util.LongBits(message.systimeMs.low >>> 0, message.systimeMs.high >>> 0).toNumber(true) : message.systimeMs;
            if (message.uptimeMs != null && message.hasOwnProperty("uptimeMs"))
                if (typeof message.uptimeMs === "number")
                    object.uptimeMs = options.longs === String ? String(message.uptimeMs) : message.uptimeMs;
                else
                    object.uptimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.uptimeMs) : options.longs === Number ? new $util.LongBits(message.uptimeMs.low >>> 0, message.uptimeMs.high >>> 0).toNumber(true) : message.uptimeMs;
            return object;
        };

        /**
         * Converts this CatchEvent to JSON.
         * @function toJSON
         * @memberof OOB.CatchEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CatchEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CatchEvent;
    })();

    return OOB;
})();

export const Playstate = $root.Playstate = (() => {

    /**
     * Properties of a Playstate.
     * @exports IPlaystate
     * @interface IPlaystate
     * @property {number|null} [catchableId] Playstate catchableId
     * @property {CampaignType|null} [campaignType] Playstate campaignType
     * @property {number|Long|null} [startTimeMs] Playstate startTimeMs
     * @property {number|Long|null} [endTimeMs] Playstate endTimeMs
     * @property {number|null} [catchesRequired] Playstate catchesRequired
     */

    /**
     * Constructs a new Playstate.
     * @exports Playstate
     * @classdesc Catch playstate information.
     * @implements IPlaystate
     * @constructor
     * @param {IPlaystate=} [properties] Properties to set
     */
    function Playstate(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Playstate catchableId.
     * @member {number} catchableId
     * @memberof Playstate
     * @instance
     */
    Playstate.prototype.catchableId = 0;

    /**
     * Playstate campaignType.
     * @member {CampaignType} campaignType
     * @memberof Playstate
     * @instance
     */
    Playstate.prototype.campaignType = 0;

    /**
     * Playstate startTimeMs.
     * @member {number|Long} startTimeMs
     * @memberof Playstate
     * @instance
     */
    Playstate.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Playstate endTimeMs.
     * @member {number|Long} endTimeMs
     * @memberof Playstate
     * @instance
     */
    Playstate.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Playstate catchesRequired.
     * @member {number} catchesRequired
     * @memberof Playstate
     * @instance
     */
    Playstate.prototype.catchesRequired = 0;

    /**
     * Creates a new Playstate instance using the specified properties.
     * @function create
     * @memberof Playstate
     * @static
     * @param {IPlaystate=} [properties] Properties to set
     * @returns {Playstate} Playstate instance
     */
    Playstate.create = function create(properties) {
        return new Playstate(properties);
    };

    /**
     * Encodes the specified Playstate message. Does not implicitly {@link Playstate.verify|verify} messages.
     * @function encode
     * @memberof Playstate
     * @static
     * @param {IPlaystate} message Playstate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Playstate.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.catchableId != null && Object.hasOwnProperty.call(message, "catchableId"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.catchableId);
        if (message.campaignType != null && Object.hasOwnProperty.call(message, "campaignType"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.campaignType);
        if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.startTimeMs);
        if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.endTimeMs);
        if (message.catchesRequired != null && Object.hasOwnProperty.call(message, "catchesRequired"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.catchesRequired);
        return writer;
    };

    /**
     * Encodes the specified Playstate message, length delimited. Does not implicitly {@link Playstate.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Playstate
     * @static
     * @param {IPlaystate} message Playstate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Playstate.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Playstate message from the specified reader or buffer.
     * @function decode
     * @memberof Playstate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Playstate} Playstate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Playstate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Playstate();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.catchableId = reader.uint32();
                break;
            case 2:
                message.campaignType = reader.int32();
                break;
            case 3:
                message.startTimeMs = reader.uint64();
                break;
            case 4:
                message.endTimeMs = reader.uint64();
                break;
            case 5:
                message.catchesRequired = reader.uint32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Playstate message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Playstate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Playstate} Playstate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Playstate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Playstate message.
     * @function verify
     * @memberof Playstate
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Playstate.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.catchableId != null && message.hasOwnProperty("catchableId"))
            if (!$util.isInteger(message.catchableId))
                return "catchableId: integer expected";
        if (message.campaignType != null && message.hasOwnProperty("campaignType"))
            switch (message.campaignType) {
            default:
                return "campaignType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
            if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                return "startTimeMs: integer|Long expected";
        if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
            if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                return "endTimeMs: integer|Long expected";
        if (message.catchesRequired != null && message.hasOwnProperty("catchesRequired"))
            if (!$util.isInteger(message.catchesRequired))
                return "catchesRequired: integer expected";
        return null;
    };

    /**
     * Creates a Playstate message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Playstate
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Playstate} Playstate
     */
    Playstate.fromObject = function fromObject(object) {
        if (object instanceof $root.Playstate)
            return object;
        let message = new $root.Playstate();
        if (object.catchableId != null)
            message.catchableId = object.catchableId >>> 0;
        switch (object.campaignType) {
        case "CAMPAIGN_TYPE_CHANCE":
        case 0:
            message.campaignType = 0;
            break;
        case "CAMPAIGN_TYPE_INSTANT":
        case 1:
            message.campaignType = 1;
            break;
        case "CAMPAIGN_TYPE_COLLECT":
        case 2:
            message.campaignType = 2;
            break;
        case "CAMPAIGN_TYPE_INFO":
        case 3:
            message.campaignType = 3;
            break;
        case "CAMPAIGN_TYPE_UNKNOWN":
        case 4:
            message.campaignType = 4;
            break;
        }
        if (object.startTimeMs != null)
            if ($util.Long)
                (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = true;
            else if (typeof object.startTimeMs === "string")
                message.startTimeMs = parseInt(object.startTimeMs, 10);
            else if (typeof object.startTimeMs === "number")
                message.startTimeMs = object.startTimeMs;
            else if (typeof object.startTimeMs === "object")
                message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber(true);
        if (object.endTimeMs != null)
            if ($util.Long)
                (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = true;
            else if (typeof object.endTimeMs === "string")
                message.endTimeMs = parseInt(object.endTimeMs, 10);
            else if (typeof object.endTimeMs === "number")
                message.endTimeMs = object.endTimeMs;
            else if (typeof object.endTimeMs === "object")
                message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber(true);
        if (object.catchesRequired != null)
            message.catchesRequired = object.catchesRequired >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a Playstate message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Playstate
     * @static
     * @param {Playstate} message Playstate
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Playstate.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.catchableId = 0;
            object.campaignType = options.enums === String ? "CAMPAIGN_TYPE_CHANCE" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.startTimeMs = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.endTimeMs = options.longs === String ? "0" : 0;
            object.catchesRequired = 0;
        }
        if (message.catchableId != null && message.hasOwnProperty("catchableId"))
            object.catchableId = message.catchableId;
        if (message.campaignType != null && message.hasOwnProperty("campaignType"))
            object.campaignType = options.enums === String ? $root.CampaignType[message.campaignType] : message.campaignType;
        if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
            if (typeof message.startTimeMs === "number")
                object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
            else
                object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber(true) : message.startTimeMs;
        if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
            if (typeof message.endTimeMs === "number")
                object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
            else
                object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber(true) : message.endTimeMs;
        if (message.catchesRequired != null && message.hasOwnProperty("catchesRequired"))
            object.catchesRequired = message.catchesRequired;
        return object;
    };

    /**
     * Converts this Playstate to JSON.
     * @function toJSON
     * @memberof Playstate
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Playstate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Playstate;
})();

export { $root as default };
