export function preloadPlaylist(playlist, progress) {
    const el = document.createElement('video');
    
    return new Promise((yes, no) => {
        function _do(ix) {
            progress(ix / playlist.length);

            if (ix === playlist.length) {
                yes();
                return;
            }

            const entry = playlist[playlist.length - (ix+1)];

            el.oncanplaythrough = () => {
                entry.duration = el.duration;
                _do(ix+1);
            }

            el.onerror = () => {
                no();
            }

            el.src = entry.mediaURL;
        }

        _do(0);
    });
}