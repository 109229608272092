const End       = 0xC0;
const Esc       = 0xDB;
const EscEnd    = 0xDC;
const EscEsc    = 0xDD;

// This would probably be better implemented as a stream
export function createSlipDecoder(packetCallback) {
    const buf = new ArrayBuffer(512);
    const dst = new Uint8Array(buf);

    let state = 0, wp = 0;

    return (buffer) => {
        for (let rp = 0; rp < buffer.length; ++rp) {
            const b = buffer[rp];
            if (b === End) {
                try {
                    packetCallback(dst.slice(0, wp));
                } catch (err) {
                    console.error("Uncaught error propagated from packet handler", err);
                }
                state = 0;
                wp = 0;
                continue;
            }
            
            switch (state) {
                case 0:
                    if (b === Esc) {
                        state = 1;
                    } else {
                        dst[wp++] = b;
                    }
                    break;
                case 1:
                    if (b === EscEnd) {
                        dst[wp++] = End;
                    } else if (b === EscEsc) {
                        dst[wp++] = Esc;
                    } else {
                        dst[wp++] = b;
                    }
                    state = 0;
                    break;
            }
        }
    };
}

export function slipEncode(packet) {
    const src = new Uint8Array(packet);

    // Measure required size of output
    let len = 1; // for end byte
    for (let i = 0; i < src.length; ++i) {
        len += (src[i] === End || src[i] === Esc) ? 2 : 1;
    }

    const encoded = new ArrayBuffer(len);
    const dst = new Uint8Array(encoded);
    
    // Copy SLIP-encoded payload to output
    let wp = 0;
    for (let i = 0; i < src.length; ++i) {
        const b = src[i];
        switch (b) {
            case End:
                dst[wp++] = Esc;
                dst[wp++] = EscEnd;
                break;
            case Esc:
                dst[wp++] = Esc;
                dst[wp++] = EscEsc;
                break;
            default:
                dst[wp++] = b;
        }
    }
    dst[wp++] = End;

    return encoded;
}
