import {run} from './lib/ui';

import * as pb from './lib/protocol.js';

globalThis.pb = pb;

document.addEventListener('DOMContentLoaded', () => {
    const isWindows = navigator.userAgent.toLowerCase().indexOf("win") >= 0;
    if (isWindows) {
        document.body.classList.remove("not-windows");
        document.body.classList.add("windows");
    }

    const sources = [...document.querySelectorAll('template')]
        .flatMap(el => [...el.content.querySelectorAll('img[src]')])
        .map(el => el.src);

    const images = sources.map(src => {
        const img = new Image();
        img.onload = () => console.log(`Preloaded ${src}`);
        img.src = src;
    });

    const params = new URLSearchParams(document.location.search);
    
    run({
        scenario: params.get('scenario')
    });
});
