import {EventEmitter} from 'events';
import {createSlipDecoder, slipEncode} from './slip';

import * as pb from './protocol';

export class BeaconComms extends EventEmitter {
    constructor(serialPort) {
        super();
        this._serialPort = serialPort;
        this._writer = serialPort.writable.getWriter();
        this._reader = serialPort.readable.getReader();
        this._decoder = createSlipDecoder((packet) => {
            this._handlePacket(packet);
        });
        this._pending = {};
        this._nextID = 1;
    }

    start() {
        this._readNextChunk();
    }

    async sendRequest(req) {
        return new Promise((yes, no) => {
            const cid = this._generateID();
            req.correlationId = cid;
            this._pending[cid] = yes;

            const msg = pb.Protocol.encode(pb.Protocol.create({
                request: req
            })).finish();

            this._send(msg);
            
            setTimeout(() => {
                if (this._pending[cid]) {
                    delete(this._pending[cid]);
                    no(new Error(`Response timeout for request cid=${cid}`));
                }
            }, 1000);

        });
    }

    _send(packet) {
        this._writer.write(slipEncode(packet));
    }

    _readNextChunk() {
        this._reader.read().then(chunk => {
            if (chunk.done) {
                return;
            }
            this._decoder(chunk.value);
            this._readNextChunk();
        });
    }

    _handlePacket(packet) {
        const msg = pb.Protocol.decode(packet);
        if (msg.response) {
            const res = msg.response;
            const status = res.status || 0;
            if (status !== 0) {
                throw new Error(`Request ID ${res.correlationId} failed with status ${status}`);
            }
            const hnd = this._pending[res.correlationId];
            if (hnd) {
                delete(this._pending[res.correlationId]);
                hnd(res);
            }
        } else if (msg.oob) {
            this.emit('oob', msg.oob);
        }
    }

    _generateID() {
        return this._nextID++;
    }
}
