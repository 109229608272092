import pluck from 'pluck-elements';
import {EventEmitter} from 'events';
import {loadScenario} from './scenarios';
import {preloadPlaylist} from './preloader';
import {openSerialPort} from './serial';
import {play} from './player';

function clickThrough(nextStep) {
    return ({ui, nav}) => {
        ui.button.onclick = () => { nav.go(nextStep); }
    };
}

function preload(scenario) {
    const out = new EventEmitter();
    out.progress = 0;

    out.complete = preloadPlaylist(scenario.playlist, (p) => {
        out.progress = p;
        out.emit('progress', p);
    });

    return out;
}

const Controllers = {
    'chrome': ({ui, nav}) => {
        // nothing to do
    },

    'start': clickThrough('enter-invite'),
    
    'enter-invite': ({ui, nav, state}) => {
        const validCode = /^([\w-]+)$/

        ui.input.oninput = _syncButton;
        ui.input.onchange = _syncButton;

        ui.button.onclick = async () => {
            ui.button.setAttribute('disabled', 'disabled');
            try {
                state.scenario = await loadScenario(_getValue());
                state.preload = preload(state.scenario);
                nav.go('teaser-2');
            } catch (err) {
                ui.input.classList.add('is-error');
                ui.button.removeAttribute('disabled');
                _setError("Failed to look up invitation - check code is correct and that network is online");
            }
        };

        function _getValue() {
            return ui.input.value.trim().toLowerCase();
        }

        function _syncButton() {
            ui.input.className = '';
            if (_getValue().match(validCode)) {
                ui.button.removeAttribute('disabled');
                ui.button.classList.add('is-success');
            } else {
                ui.button.setAttribute('disabled', 'disabled');
                ui.button.classList.remove('is-success');
            }
        }
        
        function _setError(msg) {
            if (!msg) {
                ui.message.innerHTML = '&nbsp;';
            } else {
                ui.message.textContent = msg;
            }
        }
    },

    'teaser-2': clickThrough('install-app'),
    'install-app': clickThrough('setup-app'),
    'setup-app': clickThrough('connect-beacon'),
    'connect-beacon': clickThrough('init-beacon'),

    'init-beacon': ({ui, nav, state}) => {
        ui.connect.onclick = async () => {
            try {
                state.serialPort = await openSerialPort();
            } catch (err) {
                console.error(err);
                return;
            }
            nav.go('preload');
        }
    },

    'preload': ({ui, nav, state}) => {
        _updateProgress(state.preload.progress);
        state.preload.on('progress', _updateProgress);

        state.preload.complete.then(() => {
            nav.go('player');
        });

        return {
            teardown() {
                state.preload.off('progress', _updateProgress);
            }
        };

        function _updateProgress(p) {
            ui.progress.value = p;
        }
    },

    'player': ({ui, nav, state}) => {
        play({
            scenario: state.scenario,
            serialPort: state.serialPort,
            ui
        });
    }
}

export async function run({scenario}) {
    const state = {};

    let startStep = 'start';
    if (scenario) {
        state.scenario = await loadScenario(scenario);
        state.preload = preload(state.scenario);
        startStep = 'init-beacon';
    }
    
    let activeController = null;

    const nav = {
        go(controllerName) {
            if (activeController) {
                activeController.teardown && activeController.teardown();
                document.body.removeChild(activeController.root);
            }

            const root = document.querySelector(`#${controllerName}`).content.firstElementChild.cloneNode(true);
            const ui = pluck(root);
            
            const instance = Controllers[controllerName]({nav, ui, state}) || {};
            instance.root = root;
            document.body.appendChild(instance.root);
            
            activeController = instance;
        }
    };

    if (navigator.serial && typeof navigator.serial.requestPort === 'function') {
        nav.go(startStep);
    } else {
        nav.go('chrome');
    }
}