const ScenarioBaseUrl = `https://catch-demo-scenarios.s3.eu-west-2.amazonaws.com/`;

export async function loadScenario(uuid) {
    const scenario = await fetchScenario(uuid);
    scenario.location = await fetchLocation(scenario.locationID);
    scenario.location.privateKey = Uint8Array.from(atob(scenario.location.privateKey), c => c.charCodeAt(0));
    return scenario;
}

async function fetchScenario(uuid) {
    const url = `${ScenarioBaseUrl}${uuid}.json`;
    const scenario = fetch(url).then(r => r.json());
    if (!scenario.events) {
        scenario.events = {
            onFirstCatch: `You just made your first Catch!`
        };
    }
    if (!scenario.events.onCatch) {
        scenario.events.onCatch = `You just made a Catch!`;
    }
    return scenario;
}

async function fetchLocation(id) {
    return Promise.resolve({
        "id": id,
        "privateKey": "X3eoAKAVniz8/7oK1QHLlLIlvmVIaMJ2zILmxAsT1JA="
    });
}
